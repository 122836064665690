// @flow
import {handleActions} from 'redux-actions';
import type {ApplicationState, UserState} from '../types/FlowTypes';
import {Language} from '../types/FlowTypes';
import FetchStatus from '../api/FetchStatus';
import {UserActions} from './user';

const AVAILABLE_LANGUAGES = [Language.GERMAN, Language.ENGLISH];

export const ApplicationActions = {
    TOGGLE_MOBILE_MENU: 'APPLICATION/TOGGLE_MOBILE_MENU',
    SET_LANGUAGE: 'APPLICATION/SET_LANGUAGE',
    SEND_CONTACT_MESSAGE: 'APPLICATION/SEND_CONTACT_MESSAGE',
    FETCH_SEND_CONTACT_MESSAGE: 'APPLICATION/FETCH_SEND_CONTACT_MESSAGE',
    FETCH_SEND_CONTACT_MESSAGE_SUCCESS: 'APPLICATION/FETCH_SEND_CONTACT_MESSAGE_SUCCESS',
    FETCH_SEND_CONTACT_MESSAGE_ERROR: 'APPLICATION/FETCH_SEND_CONTACT_MESSAGE_ERROR',
    CREATE_USER: 'APPLICATION/CREATE_HOTEL',
    FETCH_CREATE_USER: 'APPLICATION/FETCH_CREATE_USER',
    FETCH_CREATE_USER_SUCCESS: 'APPLICATION/FETCH_CREATE_USER_SUCCESS',
    FETCH_CREATE_USER_ERROR: 'APPLICATION/FETCH_CREATE_USER_ERROR',
    RESET_CREATE_USER_FETCH_STATUS: 'APPLICATION/RESET_CREATE_USER_FETCH_STATUS',
    SET_AVAILABLE_HIGHLIGHT_CATEGORIES: 'APPLICATION/SET_AVAILABLE_HIGHLIGHT_CATEGORIES',
    SET_SELECTED_HIGHLIGHT_CATEGORY: 'APPLICATION/SET_SELECTED_HIGHLIGHT_CATEGORY',
    START_HIGHLIGHT_SAGA: 'APPLICATION/START_HIGHLIGHT_SAGA',
    STOP_HIGHLIGHT_SAGA: 'APPLICATION/STOP_HIGHLIGHT_SAGA',
    SEND_REQUEST_MESSAGE: 'APPLICATION/SEND_REQUEST_MESSAGE',
    FETCH_SEND_REQUEST_MESSAGE: 'APPLICATION/FETCH_SEND_REQUEST_MESSAGE',
    FETCH_SEND_REQUEST_MESSAGE_SUCCESS: 'APPLICATION/FETCH_SEND_REQUEST_MESSAGE_SUCCESS',
    FETCH_SEND_REQUEST_MESSAGE_ERROR: 'APPLICATION/FETCH_SEND_REQUEST_MESSAGE_ERROR',
    SET_SHOW_COOKIE_NOTIFICATION: 'APPLICATION/SET_SHOW_COOKIE_NOTIFICATION',
    SET_TRACKING_ENABLED: 'APPLICATION/SET_TRACKING_ENABLED',
    CHECK_COOKIE_NOTIFICATION: 'APPLICATION/CHECK_COOKIE_NOTIFICATION',
    ACCEPT_COOKIES: 'APPLICATION/ACCEPT_COOKIES',
};

export const HIGHLIGHT_CATEGORIES = ['beach', 'wellness', 'nature', 'mountains', 'city', 'groups', 'sport'];


const initialState: ApplicationState = {
    language: {
        selected: (navigator.language || navigator.userLanguage).replace('-', '_')
    },
    showMobileMenu: false,
    contactFetchStatus: FetchStatus.DEFAULT,
    requestFetchStatus: FetchStatus.DEFAULT,
    loginFetchStatus: FetchStatus.DEFAULT,
    createFetchStatus: FetchStatus.DEFAULT,
    uploadImageFetchStatus: FetchStatus.DEFAULT,
    saveHotelFetchStatus: FetchStatus.DEFAULT,
    createHotelWithFrameContractFetchStatus: FetchStatus.DEFAULT,
    highlights: {
        availableCategories: [],
        selectedCategory: -1
    },
    cookies: {
        trackingEnabled: false,
        showNotification: false
    }
};

export default handleActions({
        [ApplicationActions.TOGGLE_MOBILE_MENU]: (state: ApplicationState) => ({
            ...state,
            showMobileMenu: !state.showMobileMenu
        }),
        [ApplicationActions.SET_LANGUAGE]: (state: ApplicationState, action: Object) => ({
            ...state,
            language: {
                ...state.language,
                selected: AVAILABLE_LANGUAGES.includes(action.payload) ? action.payload : 'de_DE'
            }
        }),
        [ApplicationActions.FETCH_SEND_CONTACT_MESSAGE]: (state: ApplicationState) => ({
            ...state,
            contactFetchStatus: FetchStatus.ACTIVE
        }),
        [ApplicationActions.FETCH_SEND_CONTACT_MESSAGE_SUCCESS]: (state: ApplicationState) => ({
            ...state,
            contactFetchStatus: FetchStatus.SUCCESS
        }),
        [ApplicationActions.FETCH_SEND_CONTACT_MESSAGE_ERROR]: (state: ApplicationState) => ({
            ...state,
            contactFetchStatus: FetchStatus.ERROR
        }),
        [UserActions.FETCH_LOGIN_USER]: (state: ApplicationState): ApplicationState => ({
            ...state,
            loginFetchStatus: FetchStatus.ACTIVE
        }),
        [UserActions.FETCH_LOGIN_USER_SUCCESS]: (state: ApplicationState): ApplicationState => ({
            ...state,
            loginFetchStatus: FetchStatus.SUCCESS
        }),
        [UserActions.FETCH_LOGIN_USER_ERROR]: (state: ApplicationState): ApplicationState => ({
            ...state,
            ...{
                loginFetchStatus: FetchStatus.ERROR
            }
        }),
        [ApplicationActions.FETCH_CREATE_USER]: (state: ApplicationState): ApplicationState => ({
            ...state,
            createFetchStatus: FetchStatus.ACTIVE
        }),
        [ApplicationActions.FETCH_CREATE_USER_SUCCESS]: (state: ApplicationState): ApplicationState => ({
            ...state,
            createFetchStatus: FetchStatus.SUCCESS
        }),
        [ApplicationActions.FETCH_CREATE_USER_ERROR]: (state: ApplicationState): ApplicationState => ({
            ...state,
            createFetchStatus: FetchStatus.ERROR
        }),
        [ApplicationActions.SET_AVAILABLE_HIGHLIGHT_CATEGORIES]: (state: ApplicationState, action: Object): ApplicationState => ({
            ...state,
            highlights: {
                ...state.highlights,
                availableCategories: action.payload
            }
        }),
        [ApplicationActions.SET_SELECTED_HIGHLIGHT_CATEGORY]: (state: ApplicationState, action: Object): ApplicationState => ({
            ...state,
            highlights: {
                ...state.highlights,
                selectedCategory: action.payload
            }
        }),
        [UserActions.FETCH_UPLOAD_IMAGES]: (state: UserState): UserState => ({
            ...state,
            uploadImageFetchStatus: FetchStatus.ACTIVE
        }),
        [UserActions.FETCH_UPLOAD_IMAGES_SUCCESS]: (state: UserState): UserState => ({
            ...state,
            uploadImageFetchStatus: FetchStatus.SUCCESS
        }),
        [UserActions.FETCH_UPLOAD_IMAGES_ERROR]: (state: UserState): UserState => ({
            ...state,
            uploadImageFetchStatus: FetchStatus.ERROR
        }),
        [UserActions.FETCH_SAVE_HOTEL]: (state: UserState): UserState => ({
            ...state,
            saveHotelFetchStatus: FetchStatus.ACTIVE
        }),
        [UserActions.FETCH_SAVE_HOTEL_SUCCESS]: (state: UserState): UserState => ({
            ...state,
            saveHotelFetchStatus: FetchStatus.SUCCESS
        }),
        [UserActions.FETCH_SAVE_HOTEL_ERROR]: (state: UserState): UserState => ({
            ...state,
            saveHotelFetchStatus: FetchStatus.ERROR
        }),
        [ApplicationActions.FETCH_SEND_REQUEST_MESSAGE]: (state: ApplicationState) => ({
            ...state,
            requestFetchStatus: FetchStatus.ACTIVE
        }),
        [ApplicationActions.FETCH_SEND_REQUEST_MESSAGE_SUCCESS]: (state: ApplicationState) => ({
            ...state,
            requestFetchStatus: FetchStatus.SUCCESS
        }),
        [ApplicationActions.FETCH_SEND_REQUEST_MESSAGE_ERROR]: (state: ApplicationState) => ({
            ...state,
            requestFetchStatus: FetchStatus.ERROR
        }),
        [ApplicationActions.SET_SHOW_COOKIE_NOTIFICATION]: (state: ApplicationState, action) => ({
            ...state,
            cookies: {
                ...state.cookies,
                showNotification: action.payload
            }
        }),
        [ApplicationActions.SET_TRACKING_ENABLED]: (state: ApplicationState, action) => ({
            ...state,
            cookies: {
                ...state.cookies,
                trackingEnabled: action.payload
            }
        }),
        [ApplicationActions.RESET_CREATE_USER_FETCH_STATUS]: (state: ApplicationState, action) => ({
            ...state,
            createFetchStatus: FetchStatus.DEFAULT
        })
    },
    initialState);