import ReactGA from 'react-ga';

let initialized = false;

const getGA = () => {
    if (!initialized) {
        ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID || '');
        initialized = true;
    }
    return ReactGA;
}




export default getGA;