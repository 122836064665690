import React, {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import logo from '../../../images/src/logo/logo.svg';
import ScrollToTop from '../helper/ScrollToTop';
import {LocalizationProvider} from 'react-localize';
import dictionary from '../../../language/dictionary';
import {makeStyles, MuiThemeProvider, Theme} from '@material-ui/core/styles';
import AppTheme from '../theme/Theme';
import App from '../App';
import {useSelector} from 'react-redux';
import type {AppState} from '../../../types/FlowTypes';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme: Theme) => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    },
    logo: {
        width: 400,
        [theme.breakpoints.down('xs')]: {
            width: '80%'
        }
    }
}));

const Skeleton = () => {

    const classes = useStyles();
    const language = useSelector((state: AppState) => state.application.language.selected);

    return (
        <BrowserRouter>
            <Suspense fallback={
                <div className={classes.loader}>
                    <img src={logo} alt={'Rolli-Hotels.de'} title={'Rolli-Hotels.de'}
                         className={classes.logo}/>
                </div>}>
                <ScrollToTop>
                    <LocalizationProvider messages={dictionary[language || 'de_DE']}>
                        <MuiThemeProvider theme={AppTheme}>
                            <CssBaseline />
                            <App />
                        </MuiThemeProvider>
                    </LocalizationProvider>
                </ScrollToTop>
            </Suspense>
        </BrowserRouter>
    );
}

export default Skeleton;