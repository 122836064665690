// @flow
import {handleActions} from 'redux-actions';
import type {ApplicationState, SearchState} from '../types/FlowTypes';
import FetchStatus from '../api/FetchStatus';
import {normalize} from '../helper/helperFunctions';

export const SearchActions = {
    UPDATE_SEARCH_VALUE: 'SEARCH/UPDATE_SEARCH_VALUE',
    LOAD_COUNTIES: 'APPLICATION/LOAD_COUNTIES',
    FETCH_LOAD_COUNTIES: 'APPLICATION/FETCH_LOAD_COUNTIES',
    FETCH_LOAD_COUNTIES_SUCCESS: 'APPLICATION/FETCH_LOAD_COUNTIES_SUCCESS',
    FETCH_LOAD_COUNTIES_ERROR: 'APPLICATION/FETCH_LOAD_COUNTIES_ERROR'
};

const initialState: SearchState = {
    search: null,
    results: {},
    counties: {},
    countries: {},
    countyFetchStatus: FetchStatus.SUCCESS,
};

export default handleActions({
        [SearchActions.UPDATE_SEARCH_VALUE]: (state: SearchState, action: Object) => ({
            ...state, ...{search: action.payload.search}
        }),
        [SearchActions.FETCH_LOAD_COUNTIES]: (state: ApplicationState, action: Object) => ({
            ...state,
            ...{
                countyFetchStatus: FetchStatus.ACTIVE
            }
        }),
        [SearchActions.FETCH_LOAD_COUNTIES_SUCCESS]: (state: ApplicationState, action: Object) => {
            const countries = {};
            action.payload.counties.forEach((county: Object) => {
                county.hasHotels = action.payload.countiesWithHotels.includes(county.id);
                if (!countries[county.Country.id]) {
                    countries[county.Country.id] = county.Country;
                }
            });
            return {
                ...state,
                ...{
                    countyFetchStatus: FetchStatus.SUCCESS,
                    counties: normalize(action.payload.counties, 'id'),
                    countries
                }
            }
        },
        [SearchActions.FETCH_LOAD_COUNTIES_ERROR]: (state: ApplicationState) => ({
            ...state,
            ...{
                countyFetchStatus: FetchStatus.ERROR,
                counties: {}
            }
        })
    },
    initialState);