// @flow
import React, {useCallback, useMemo} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Link, useHistory} from 'react-router-dom';
import {useLocalize} from 'react-localize';
import type {AppState} from '../../../types/FlowTypes';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import logo from '../../../images/src/logo/logo.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useDispatch, useSelector} from 'react-redux';
import {createAction} from 'redux-actions';
import {ApplicationActions} from '../../../reducer/application';
import {UserActions} from '../../../reducer/user';
import {useLocation} from 'react-router';
import {Language} from '../../../types/FlowTypes';
import english from '../../../images/icons/english.svg';
import englishSelected from '../../../images/icons/english_gray.svg';
import german from '../../../images/icons/german.svg';
import germanSelected from '../../../images/icons/german_gray.svg';

const useStyles: Function = makeStyles((theme: Object): Object => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '14px 0',
        boxShadow: '0 0 8px 0 rgba(0,0,0,0.2)',
        position: 'fixed',
        zIndex: 101,
        width: '100%',
        background: 'white',
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none',
            background: 'white',
            justifyContent: 'flex-start'
        }
    },
    inner: {
        ...theme.body,
        margin: undefined,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            margin: '0 auto'
        }
    },
    link: {
        ...theme.link,
        color: theme.palette.secondary.light,
        fontSize: 17,
        marginRight: 40,
        letterSpacing: 0.25,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            fontSize: 24,
            lineHeight: '24px',
            fontWeight: 600,
            marginBottom: 24
        }
    },
    button: {
        fontWeight: 600,
        display: 'inline',
        padding: '16px 24px',
        boxSizing: 'border-box',
        lineHeight: 1,
        border: 'white',
        fontSize: 16,
        textTransform: 'none',
        letterSpacing: 0.25,
    },
    buttonLabel: {
        background: 'linear-gradient(225deg, #2F89FC 0%, #30E3CA 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },
    logo: {
        height: 60,
        marginLeft: 0,
        [theme.breakpoints.down('xs')]: {
            height: 52,
            marginBottom: 0
        }
    },
    menuIcon: {
        fontSize: 32,
        color: theme.palette.primary.light
    },
    mobileMenu: {
        background: 'white',
        position: 'fixed',
        display: 'none',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        top: 80,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
    },
    mobileMenuActive: {
        display: 'flex'
    },
    gradientLink: {
        background: 'linear-gradient(225deg, #2F89FC 0%, #30E3CA 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },
    noMargin: {
        margin: 0
    },
    linkActive: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    switch: {
        padding: 0,
        minWidth: 'initial',
        marginLeft: theme.spacing(4)
    },
    languageIcon: {
        width: 32,
        height: 32,
        borderRadius: 4
    },
    languageSurround: {
        display: 'flex',
        marginTop: theme.spacing(4),
        alignItems: 'center'
    },
    mobileLanguageIcon: {
        width: 32,
        height: 32,
        borderRadius: 4
    },
    language: {
        padding: theme.spacing(2)
    },
    mobileLanguageDivider: {
        display: 'block',
        width: 1,
        background: '#E4E4E4',
        height: 24
    }
}));

const Menu = () => {

    const classes = useStyles();
    const {push} = useHistory();
    const {pathname} = useLocation();
    const {localize} = useLocalize();
    const dispatch = useDispatch();
    const isWidthUpMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const {showMobileMenu, jwt, language} = useSelector((state: AppState) => ({
        showMobileMenu: state.application.showMobileMenu,
        jwt: state.user.jwt,
        language: state.application.language.selected
    }))

    const isLinkActive = useCallback((link) => {
        if (link === '/hotels' || link === '/blog') {
            return pathname.startsWith(link);
        }
        return pathname === link;
    }, [pathname]);

    const toggleMobileMenu = useCallback(() => dispatch(createAction(ApplicationActions.TOGGLE_MOBILE_MENU)()),
        [dispatch]);

    const mobileMenu = useMemo(() => {
        if (!isWidthUpMD) {
            return (
                <div className={classnames(classes.mobileMenu, {[classes.mobileMenuActive]: showMobileMenu})}>
                    <Link to={'/'} onClick={toggleMobileMenu}
                          className={classnames(classes.link, {[classes.linkActive]: isLinkActive('/')})}>
                        {localize('menu.home')}
                    </Link>
                    <Link to={'/hotels'} onClick={toggleMobileMenu}
                          className={classnames(classes.link, {[classes.linkActive]: isLinkActive('/hotels')})}>
                        {localize('menu.destinations')}
                    </Link>
                    <Link to={'/blog'} onClick={toggleMobileMenu}
                          className={classnames(classes.link, {[classes.linkActive]: isLinkActive('/blog')})}>
                        {localize('menu.blog')}
                    </Link>
                    <Link to={'/advertise'} onClick={toggleMobileMenu}
                          className={classnames(classes.link, {[classes.linkActive]: isLinkActive('/advertise')})}>
                        {localize('menu.rent')}
                    </Link>
                    <Link to={'/aboutUs'} onClick={toggleMobileMenu}
                          className={classnames(classes.link, {[classes.linkActive]: isLinkActive('/aboutUs')})}>
                        {localize('menu.about.us')}
                    </Link>
                    {!jwt ?
                        <Link to={'/login'} onClick={toggleMobileMenu}
                              className={classnames(classes.link, classes.gradientLink)}>
                            {localize('menu.login')}
                        </Link>
                        :
                        <React.Fragment>
                            <Link to={'/login'} onClick={toggleMobileMenu}
                                  className={classnames(classes.link, classes.gradientLink)}>
                                {localize('menu.hotel')}
                            </Link>
                            <span onClick={() => {
                                dispatch(createAction(UserActions.LOGOUT)());
                                toggleMobileMenu();
                            }} className={classnames(classes.link, classes.gradientLink)}>
                            {localize('menu.logout')}
                        </span>
                        </React.Fragment>
                    }
                    <div className={classes.languageSurround}>
                        <IconButton className={classes.language} onClick={() => {
                            if (language !== Language.GERMAN) {
                                dispatch(createAction(ApplicationActions.SET_LANGUAGE)(Language.GERMAN));
                            }
                        }}>
                            <img src={language === Language.GERMAN ? german : germanSelected}
                                 className={classes.mobileLanguageIcon}
                                 alt={localize('language.german')} title={localize('language.german')}/>
                        </IconButton>
                        <span className={classes.mobileLanguageDivider}/>
                        <IconButton className={classes.language} onClick={() => {
                            if (language !== Language.ENGLISH) {
                                dispatch(createAction(ApplicationActions.SET_LANGUAGE)(Language.ENGLISH));
                            }
                        }}>
                            <img src={language === Language.ENGLISH ? english : englishSelected}
                                 className={classes.mobileLanguageIcon}
                                 alt={localize('language.english')} title={localize('language.english')}/>
                        </IconButton>
                    </div>
                </div>
            );
        }
        return null;
    }, [isWidthUpMD, dispatch, classes, toggleMobileMenu, isLinkActive, localize, jwt, showMobileMenu, language]);

    const mobileMenuToggle = useMemo(() => {
        return (<IconButton aria-label={'toggle-menu'}
                            onClick={toggleMobileMenu}>
            {showMobileMenu ? <CloseIcon className={classes.menuIcon}/> :
                <MenuIcon className={classes.menuIcon}/>}
        </IconButton>)
    }, [classes, showMobileMenu, toggleMobileMenu]);

    const header = useMemo(() => {
        if (isWidthUpMD) {
            return (<div>
                <Link to={'/hotels'}
                      className={classnames(classes.link, {[classes.linkActive]: isLinkActive('/hotels')})}>
                    {localize('menu.destinations')}
                </Link>
                <Link to={'/blog'} className={classnames(classes.link, {[classes.linkActive]: isLinkActive('/blog')})}>
                    {localize('menu.blog')}
                </Link>
                <Link to={'/advertise'}
                      className={classnames(classes.link, {[classes.linkActive]: isLinkActive('/advertise')})}>
                    {localize('menu.rent')}
                </Link>
                <Link to={'/aboutUs'}
                      className={classnames(classes.link, {[classes.linkActive]: isLinkActive('/aboutUs')})}>
                    {localize('menu.about.us')}
                </Link>
                <ButtonGroup variant={'contained'} color={'primary'} aria-label={'login'}>
                    <Button className={classes.button} variant={'contained'} color={'primary'}
                            aria-label={jwt ? localize('menu.hotel') : localize('menu.login')}
                            onClick={() => push('/login')}>
                        {jwt ? localize('menu.hotel') : localize('menu.login')}
                    </Button>
                    {jwt ?
                        <Button size={'small'}
                            aria-label={'logout'}
                            onClick={() => dispatch(createAction(UserActions.LOGOUT)())}>
                            <ExitToAppIcon/>
                        </Button> : null}
                </ButtonGroup>
                <Button variant={'text'} className={classes.switch} onClick={() => {
                    dispatch(createAction(ApplicationActions.SET_LANGUAGE)(language === Language.ENGLISH ? Language.GERMAN : Language.ENGLISH));
                }}>
                    <img src={language === Language.ENGLISH ? german : english} alt={''} title={''}
                         className={classes.languageIcon}/>
                </Button>
            </div>);
        }
        return mobileMenuToggle;
    }, [classes, localize, jwt, dispatch, push, isWidthUpMD, mobileMenuToggle, isLinkActive, language]);

    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                <Link to={'/'} className={classnames(classes.link, classes.noMargin)}>
                    <img className={classes.logo} src={logo} alt={localize('app.name')}/>
                </Link>
                {header}
            </div>
            {mobileMenu}
        </div>
    );

}

export default Menu;
