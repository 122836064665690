// @flow
import {spawn, take, put, call} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import {post, Route, sendContactMessage} from '../api/Api';
import {ApplicationActions} from '../reducer/application';
import {existsAndNotEmpty, validateEmail} from '../helper/helperFunctions';


export function* sendContactMessageSaga() {
    while (true) {
        try {
            const action = yield take(ApplicationActions.SEND_CONTACT_MESSAGE);
            if (existsAndNotEmpty(action.payload, 'name') && existsAndNotEmpty(action.payload, 'email') && existsAndNotEmpty(action.payload, 'message') && action.payload.acceptPrivacy) {
                yield put(createAction(ApplicationActions.FETCH_SEND_CONTACT_MESSAGE)());
                yield call(sendContactMessage, Route.SEND_CONTACT, action.payload);
                yield put(createAction(ApplicationActions.FETCH_SEND_CONTACT_MESSAGE_SUCCESS)());
            }
        } catch (e) {
            console.error(e);
            yield put(createAction(ApplicationActions.FETCH_SEND_CONTACT_MESSAGE_ERROR)())
        }

    }
}


export function* sendRequestMessage() {
    while (true) {
        try {
            const action = yield take(ApplicationActions.SEND_REQUEST_MESSAGE);
            if (existsAndNotEmpty(action.payload, 'name') && existsAndNotEmpty(action.payload, 'firstName') &&
                existsAndNotEmpty(action.payload, 'lastName') && existsAndNotEmpty(action.payload, 'email') &&
                validateEmail(action.payload.email.trim()) && action.payload.acceptPrivacy && action.payload.acceptTermsOfUse) {
                yield put(createAction(ApplicationActions.FETCH_SEND_REQUEST_MESSAGE)());
                yield call(post, Route.CREATE_USER, action.payload);
                yield put(createAction(ApplicationActions.FETCH_SEND_REQUEST_MESSAGE_SUCCESS)());
            }
        } catch (e) {
            console.error(e);
            yield put(createAction(ApplicationActions.FETCH_SEND_REQUEST_MESSAGE_ERROR)())
        }
    }
}

function* contactSaga() {
    yield spawn(sendContactMessageSaga);
    yield spawn(sendRequestMessage);
}

export default contactSaga;