// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Theme, Typography, useMediaQuery} from '@material-ui/core';
import * as classnames from 'classnames';
import CookieAccordion from './CookieAccordion';
import CookieSwitch from './CookieSwitch';
import {Launch} from '@material-ui/icons';
import {useLocalize} from 'react-localize';

type CookieSettingsProps = {
    trackingEnabled: boolean;
    setTrackingEnabled: (enabled: boolean) => void
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
    },
    description: {
        color: '#767E8C',
        fontSize: 15,
        lineHeight: '20px'
    },
    bordered: {
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid #E2E3E5'
    },
    titleSurround: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 'calc(100% - 32px)',
        alignItems: 'center'
    },
    switchSurround: {
        borderRight: '1px solid #E2E3E5',
        paddingRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        alignSelf: 'center'
    },
    switchDescription: {
        color: 'white',
        position: 'absolute',
        top: 5,
        left: 10,
        fontSize: 10,
        fontWeight: 600,
        textTransform: 'uppercase',
        transition: theme.transitions.create('all'),
        zIndex: 1,
        lineHeight: 1
    },
    switchDescriptionOff: {
        left: 20
    },
    provider: {
        width: 472,
        maxWidth: '90%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            width: '100%'
        }
    },
    providerTitle: {
        fontSize: 14,
        color: theme.palette.primary.dark,
        fontWeight: 600,
        lineHeight: '18px',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            fontWeight: 400
        }
    },
    vendorLink: {
        ...theme.link,
        display: 'block',
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    vendorButton: {
        background: 'white',
        textTransform: 'none',
        width: '100%',
        border: '1px solid #CBCCCD',
        fontSize: 14,
        color: '#666666',
        borderRadius: 24,
        height: 50,
        padding: theme.spacing(0, 3),
        justifyContent: 'space-between'
    },
    vendorButtonIcon: {
        width: 24,
        height: 24,
        color: '#979797'
    },
    title: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        width: '100%'
    }
}));

const CookieSettings= ({setTrackingEnabled, trackingEnabled}: CookieSettingsProps) => {
    const styles = useStyles();
    const {localize} = useLocalize();
    const isWidthDownXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    return (
        <div className={classnames(styles.root, styles.bordered)}>
            <Typography variant={'body1'} className={classnames(styles.description, styles.bordered)}>
                {localize('cookies.settings.description')}
            </Typography>
            <CookieAccordion title={
                <div className={styles.titleSurround}>
                    <Typography variant={'h4'} className={styles.title}>
                        {isWidthDownXS ? localize('cookies.settings.device.titleMobile') : localize('cookies.settings.device.title')}
                    </Typography>
                    <div className={styles.switchSurround}>
                        <Typography variant={'body1'} className={styles.switchDescription}>
                            {localize('cookies.settings.switch.on')}
                        </Typography>
                        <CookieSwitch disabled={true} checked={true}/>
                    </div>
                </div>
            } description={localize('cookies.settings.device.description')}/>
            <CookieAccordion title={
                <div className={styles.titleSurround}>
                    <Typography variant={'h4'} className={styles.title}>
                        {isWidthDownXS ? localize('cookies.settings.content.titleMobile') : localize('cookies.settings.content.title')}
                    </Typography>
                    <div className={styles.switchSurround} onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setTrackingEnabled(!trackingEnabled);
                    }}>
                        <Typography variant={'body1'}
                                    className={classnames(styles.switchDescription, {
                                        [styles.switchDescriptionOff]: !trackingEnabled
                                    })}>
                            {trackingEnabled ? localize('cookies.settings.switch.on') : localize('messages.cookies.settings.switch.off')}
                        </Typography>
                        <CookieSwitch checked={trackingEnabled} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setTrackingEnabled(!trackingEnabled);
                        }}/>
                    </div>
                </div>
            } description={
                <div className={styles.provider}>
                    <Typography variant={'body1'} className={styles.providerTitle}>
                        {localize('cookies.settings.content.vendors.title')}
                    </Typography>
                    <a href={'https://policies.google.com/privacy?hl=de'} rel={'noopener noreferrer'}
                       className={styles.vendorLink} target={'_blank'}>
                        <Button className={styles.vendorButton} variant={'outlined'}>
                            <span>{localize('cookies.settings.content.vendors.googleAnalytics')}</span>
                            <Launch className={styles.vendorButtonIcon}/>
                        </Button>
                    </a>
                    <a href={'https://privacy.google.com/businesses/processorterms/'} rel={'noopener noreferrer'}
                       className={styles.vendorLink} target={'_blank'}>
                        <Button className={styles.vendorButton} variant={'outlined'}>
                            <span>{localize('cookies.settings.content.vendors.googleAds')}</span>
                            <Launch className={styles.vendorButtonIcon}/>
                        </Button>
                    </a>
                    <a href={'https://policies.google.com/technologies/cookies?hl=en'} rel={'noopener noreferrer'}
                       className={styles.vendorLink} target={'_blank'}>
                        <Button className={styles.vendorButton} variant={'outlined'}>
                            <span>{localize('cookies.settings.content.vendors.googleTagManager')}</span>
                            <Launch className={styles.vendorButtonIcon}/>
                        </Button>
                    </a>
                    <a href={'https://www.facebook.com/policy.php'} rel={'noopener noreferrer'}
                       className={styles.vendorLink} target={'_blank'}>
                        <Button className={styles.vendorButton} variant={'outlined'}>
                            <span>{localize('cookies.settings.content.vendors.facebookPixel')}</span>
                            <Launch className={styles.vendorButtonIcon}/>
                        </Button>
                    </a>
                </div>}
            />
        </div>
    );
}

export default CookieSettings;

