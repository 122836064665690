// @flow
import {Switch, Theme, withStyles} from '@material-ui/core';
import {createStyles} from '@material-ui/core/styles';
import React from 'react';

const CookieSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 46,
            height: 20,
            padding: 0
        },
        switchBase: {
            padding: 1,
            top: 1,
            left: 1,
            '&$checked': {
                transform: 'translateX(26px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#13C5AC',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$disabled': {
                '& + $track': {
                    backgroundColor: '#82D6CA !important',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#95D67F',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 16,
            height: 16,
            boxShadow: 'none'
        },
        track: {
            borderRadius: 10,
            border: 'none',
            backgroundColor: theme.palette.primary.dark,
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
        description: {
            color: 'white'
        },
        disabled: {}
    }),
)(({classes, ...rest}) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
                disabled: classes.disabled
            }}
            {...rest}
        />
    );
});

export default CookieSwitch;