// @flow
import {spawn, call, take, put, select, fork, delay, takeLatest, takeEvery} from 'redux-saga/effects';
import {buffers, eventChannel, END} from 'redux-saga';
import {UserActions} from '../reducer/user';
import {createAction} from 'redux-actions';
import {post, Route} from '../api/Api';
import {existsAndNotEmpty} from '../helper/helperFunctions';
import {ApplicationActions} from '../reducer/application';
import type {AppState} from '../types/FlowTypes';
import {StatusCodes} from 'http-status-codes';
import {getCookie, setCookie} from 'redux-cookie/src';

function createUploadFileChannel(endpoint: string, file: File, jwt: string) {
    return eventChannel(emitter => {
        const xhr = new XMLHttpRequest();
        const onProgress = (e: ProgressEvent) => {
            if (e.lengthComputable) {
                const progress = e.loaded / e.total;
                emitter({progress});
            }
        };
        const onFailure = (e: ProgressEvent) => {
            emitter({err: new Error('Upload failed')});
            emitter(END);
        };
        xhr.upload.addEventListener('progress', onProgress);
        xhr.upload.addEventListener('error', onFailure);
        xhr.upload.addEventListener('abort', onFailure);
        xhr.onreadystatechange = () => {
            const {readyState, status, response} = xhr;
            if (readyState === 4) {
                if (status === 200) {
                    emitter({success: true, response: JSON.parse(response)});
                    emitter(END);
                } else {
                    onFailure(null);
                }
            }
        };
        xhr.open('POST', endpoint, true);
        if (jwt) {
            xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);
        }
        const formData = new FormData();
        formData.append('file', file);
        xhr.send(formData);
        return () => {
            xhr.upload.removeEventListener('progress', onProgress);
            xhr.upload.removeEventListener('error', onFailure);
            xhr.upload.removeEventListener('abort', onFailure);
            xhr.onreadystatechange = null;
            xhr.abort();
        };
    }, buffers.sliding(2));
}

function* cookieSaga() {
    yield takeLatest(ApplicationActions.CHECK_COOKIE_NOTIFICATION, function* () {
        const cookieString: string = yield put(getCookie(process.env.REACT_APP_COOKIE_NAME));
        if (!cookieString) {
            yield put(createAction(ApplicationActions.SET_SHOW_COOKIE_NOTIFICATION)(true));
        } else {
            const cookie: CookieConsent = JSON.parse(cookieString);
            if (cookie.trackingEnabled) {
                yield put(createAction(ApplicationActions.SET_TRACKING_ENABLED)(cookie.trackingEnabled));
            }
        }
    });
}

function* acceptCookieSaga() {
    yield takeLatest(ApplicationActions.ACCEPT_COOKIES, function* (action) {
        yield put(setCookie(process.env.REACT_APP_COOKIE_NAME, action.payload, {
            expires: parseInt(process.env.REACT_APP_COOKIE_EXPIRES || '31', 10),
            domain: process.env.REACT_APP_COOKIE_DOMAIN
        }));
        // Tell the store whether tracking was enabled or not
        yield put(createAction(ApplicationActions.SET_TRACKING_ENABLED)(action.payload.trackingEnabled));
        // Hide the cookie notification
        yield put(createAction(ApplicationActions.SET_SHOW_COOKIE_NOTIFICATION)(false));
    });
}

export function* loginUserSaga(): Generator<any, any, any> {
    while (true) {
        try {
            const action = yield take(UserActions.LOGIN_USER);
            if (action.payload.password && action.payload.password.trim()) {
                yield put(createAction(UserActions.FETCH_LOGIN_USER)());
                const user = yield call(post, Route.LOGIN_VERIFY, {
                    password: action.payload.password.trim()
                });
                if (user) {
                    yield put(createAction(UserActions.FETCH_LOGIN_USER_SUCCESS)(user));
                } else {
                    yield put(createAction(UserActions.FETCH_LOGIN_USER_ERROR)());
                }
            }
        } catch (e) {
            yield put(createAction(UserActions.FETCH_LOGIN_USER_ERROR)());
        }
    }
}

export function* createUserSaga(): Generator<any, any, any> {
    yield takeEvery(ApplicationActions.CREATE_USER, function* (action) {
        try {
            if (existsAndNotEmpty(action.payload, 'model') &&
                existsAndNotEmpty(action.payload, 'firstName') && existsAndNotEmpty(action.payload, 'lastName') &&
                existsAndNotEmpty(action.payload, 'email') && existsAndNotEmpty(action.payload, 'hotelName') &&
                existsAndNotEmpty(action.payload, 'street') && existsAndNotEmpty(action.payload, 'zip') &&
                existsAndNotEmpty(action.payload, 'city') && existsAndNotEmpty(action.payload, 'country')) {
                yield put(createAction(ApplicationActions.FETCH_CREATE_USER)());
                const language = yield select((state: AppState) => state.application.language.selected);
                const result = yield call(post, Route.CREATE_USER, action.payload, undefined, language);
                if (result && result.success) {
                    yield put(createAction(ApplicationActions.FETCH_CREATE_USER_SUCCESS)());
                } else {
                    yield put(createAction(ApplicationActions.FETCH_CREATE_USER_ERROR)());
                }
            }
        } catch (e) {
            console.error(e);
            yield put(createAction(ApplicationActions.FETCH_CREATE_USER_ERROR)());
        }

    })
}

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export function* uploadImagesSaga(): Generator<any, any, any> {
    while (true) {
        const action = yield take(UserActions.UPLOAD_IMAGES);
        const {jwt} = yield select((state: AppState) => ({
            jwt: state.user.jwt,
            images: state.user.hotel.Images
        }));
        try {
            if (jwt) {
                yield put(createAction(UserActions.FETCH_UPLOAD_IMAGES)());
                let index = 0;
                const files = [...action.payload].filter(file => ALLOWED_FILE_TYPES.includes(file.type));
                let file = files[index];
                while (file) {
                    // Only upload file if type is valid
                    const channel = yield call(createUploadFileChannel, `${process.env.REACT_APP_BASE_URL}${Route.UPLOAD_IMAGE}/true`, file, jwt);
                    while (true) {
                        const {err, success, response} = yield take(channel);
                        if (success) {
                            yield put(createAction(UserActions.ADD_IMAGE_TO_HOTEL)(response));
                            break;
                        } else if (err) {
                            console.error(err);
                            // Tell the store that the current image could not be uploaded / processed
                            yield put(createAction(UserActions.FETCH_UPLOAD_IMAGES_ERROR)(file.name));
                            break;
                        }
                    }
                    index++;
                    file = files[index];
                }
                yield put(createAction(UserActions.FETCH_UPLOAD_IMAGES_SUCCESS)());
                // Sync current registration progress with backend
                yield put(createAction(UserActions.SYNC_HOTEL_REGISTRATION)(true));
            }
        } catch (e) {
            console.error(e);
            yield put(createAction(ApplicationActions.FETCH_CREATE_USER_ERROR)());
            if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                yield put(createAction(UserActions.LOGOUT)());
            }
        }

    }
}

export function* deleteImageSaga(): Generator<any, any, any> {
    while (true) {
        const action = yield take(UserActions.DELETE_IMAGE);
        const jwt = yield select((state: AppState) => state.user.jwt);
        try {
            if (jwt) {
                yield put(createAction(UserActions.FETCH_DELETE_IMAGE)(action.payload));
                const response = yield call(post, Route.DELETE_IMAGE, {id: action.payload}, jwt);
                if (response.success) {
                    yield put(createAction(UserActions.FETCH_DELETE_IMAGE_SUCCESS)(action.payload));
                    // Sync current registration progress with backend
                    yield put(createAction(UserActions.SYNC_HOTEL_REGISTRATION)(true));
                } else {
                    yield put(createAction(UserActions.FETCH_DELETE_IMAGE_ERROR)(action.payload));
                }
            }
        } catch (e) {
            console.error(e);
            yield put(createAction(UserActions.FETCH_DELETE_IMAGE_ERROR)(action.payload));
            if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                yield put(createAction(UserActions.LOGOUT)());
            }
        }
    }
}

export function* saveHotelSaga(): Generator<any, any, any> {
    yield takeEvery(UserActions.SAVE_HOTEL, function* () {
        const {jwt, hotel} = yield select((state: AppState) => ({jwt: state.user.jwt, hotel: state.user.hotel}));
        try {
            if (jwt && hotel) {
                yield put(createAction(UserActions.FETCH_SAVE_HOTEL)());
                yield call(post, Route.SAVE_HOTEL, {hotel}, jwt);
                // Set hotel to pending if it was not published yet
                if (!hotel.published) {
                    yield put(createAction(UserActions.SET_HOTEL_PENDING)());
                    // Sync current registration progress with backend
                    yield put(createAction(UserActions.SYNC_HOTEL_REGISTRATION)(true));
                }
                yield put(createAction(UserActions.FETCH_SAVE_HOTEL_SUCCESS)());
            }
        } catch (e) {
            console.error(e);
            yield put(createAction(UserActions.FETCH_SAVE_HOTEL_ERROR)());
            if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                yield put(createAction(UserActions.LOGOUT)());
            }
        }
    });
}

export function* periodicHotelRegistrationMergeSaga(): Generator<any, any, any> {
    while (yield take(UserActions.START_PERIODIC_HOTEL_REGISTRATION_MERGE)) {
        try {
            const jwt = yield select((state: AppState) => state.user.jwt);
            let callSaga = jwt;
            while (callSaga) {
                const hotel = yield select((state: AppState) => state.user.hotel);
                yield call(mergeHotelRegistration, hotel, jwt);
                yield delay(5000);
                // Check if user is still logged in
                const {updatedJWT, updatedHotel} = yield select((state: AppState) => ({
                    updatedJWT: state.user.jwt,
                    updatedHotel: state.user.hotel
                }));
                callSaga = updatedJWT && updatedHotel;
            }
        } catch (e) {
            console.error(e);
        }
    }
}

export function* mergeHotelRegistration(hotel, jwt): Generator<any, any, any> {
    const {lastSync} = yield call(post, Route.LAST_SYNC_HOTEL_REGISTRATION, null, jwt);
    if (lastSync && lastSync !== hotel.lastSync) {
        // Someone changed in the meanwhile the registration, so get the new one and merge the response
        const response = yield call(post, Route.GET_HOTEL_REGISTRATION, null, jwt);
        yield put(createAction(UserActions.MERGE_HOTEL_REGISTRATION)(response));
    }
}

export function* syncRegistration(disableMerge?: boolean): Generator<any, any, any> {
    const {jwt, hotel, selectedRequirements} = yield select((state: AppState) => ({
        jwt: state.user.jwt,
        hotel: state.user.hotel,
        selectedRequirements: state.user.selectedRequirements
    }));
    try {
        if (jwt && hotel) {
            if (!disableMerge) {
                yield call(mergeHotelRegistration, hotel, jwt);
            }
            yield put(createAction(UserActions.FETCH_SYNC_HOTEL_REGISTRATION)());
            const {timestamp} = yield call(post, Route.SYNC_HOTEL_REGISTRATION, {hotel, selectedRequirements}, jwt);
            yield put(createAction(UserActions.FETCH_SYNC_HOTEL_REGISTRATION_SUCCESS)(timestamp));
            if (disableMerge) {
                yield call(mergeHotelRegistration, hotel, jwt);
            }
        }
    } catch (e) {
        console.error(e);
        yield put(createAction(UserActions.FETCH_SYNC_HOTEL_REGISTRATION_ERROR)());
        if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
            yield put(createAction(UserActions.LOGOUT)());
        }
    }
}

export function* syncHotelRegistration(): Generator<any, any, any> {
    while (true) {
        try {
            const disableMerge = yield take(UserActions.SYNC_HOTEL_REGISTRATION);
            yield fork(syncRegistration, disableMerge);
        } catch (e) {
            console.error(e);
        }
    }
}

export function* loadHotelRegistration(): Generator<any, any, any> {
    while (yield take(UserActions.LOAD_HOTEL_REGISTRATION)) {
        const {jwt} = yield select((state: AppState) => ({jwt: state.user.jwt}));
        try {
            if (jwt) {
                yield put(createAction(UserActions.FETCH_LOAD_HOTEL_REGISTRATION)());
                const response = yield call(post, Route.GET_HOTEL_REGISTRATION, null, jwt);
                yield put(createAction(UserActions.FETCH_LOAD_HOTEL_REGISTRATION_SUCCESS)(response));
            }
        } catch (e) {
            console.error(e);
            yield put(createAction(UserActions.FETCH_SYNC_HOTEL_REGISTRATION_ERROR)());
            if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                yield put(createAction(UserActions.LOGOUT)());
            }
        }
    }
}

function* userSaga() {
    yield spawn(loginUserSaga);
    yield spawn(createUserSaga);
    yield spawn(uploadImagesSaga);
    yield spawn(deleteImageSaga);
    yield spawn(saveHotelSaga);
    yield spawn(syncHotelRegistration);
    yield spawn(loadHotelRegistration);
    yield spawn(periodicHotelRegistrationMergeSaga);
    yield spawn(cookieSaga);
    yield spawn(acceptCookieSaga);
}

export default userSaga;