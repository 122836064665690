// @flow
import {checkServerResponse, requestConfig, RequestMethod} from './RequestHelper';

export const Route = {
    LOAD_SEARCH_CATEGORIES: '/hotels/searchCategories',
    LOAD_HIGHLIGHTS: '/hotels/highlights',
    LOAD_HOTELS: '/hotels/get',
    LOAD_HOTEL: '/hotels/hotel',
    LOAD_COUNTIES: '/load/counties',
    SEND_CONTACT: '/contact/send',
    SEND_HOTEL_CONTACT: '/hotels/request',
    LOGIN_VERIFY: '/user/login/verify',
    CREATE_USER: '/user/login/create',
    CREATE_USER_WITH_FRAME_CONTRACT: '/user/login/create/frameContract',
    UPLOAD_IMAGE: '/user/upload',
    DELETE_IMAGE: '/user/image/delete',
    SAVE_HOTEL: '/user/hotel/save',
    SYNC_HOTEL_REGISTRATION: '/user/hotel/sync',
    LAST_SYNC_HOTEL_REGISTRATION: '/user/hotel/lastSync',
    GET_HOTEL_REGISTRATION: '/user/hotel/registration',
    SEND_REQUEST: '/contact/request',
    LOG_SHOW_PHONE_ACTION: '/hotels/log/phone',
    LOG_WEBSITE: '/hotels/log/website',
    LOAD_BLOG: '/api/blog',
    LOAD_BLOG_CATEGORY_POSTS: (id: number, page: number, pageSize: number) => `/api/blog/posts/category/${id}/${page}/${pageSize}`,
    LOAD_BLOG_POST: (id: number) => `/api/blog/post/${id}`,
    RECORD_POST_VIEW: '/api/blog/post/view',
    RECORD_CATEGORY_VIEW: '/api/blog/category/view'
};

export const fetchRequest = async (url: string, language: string): Promise<Array<Object>> =>
    checkServerResponse(await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, requestConfig(RequestMethod.GET, undefined, undefined, language)));

export const fetchArrayFromPost = async (url: string, body: Object, language: string): Promise<Array<Object>> =>
    checkServerResponse(await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, requestConfig(RequestMethod.POST, body, undefined, language)));

export const sendContactMessage = async (url: string, contactData: Object, language: string): Promise<typeof undefined | Object> =>
    checkServerResponse(await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, requestConfig(RequestMethod.POST, contactData, undefined, language)));

export const post = async (url: string, body: Object, jwt?: string, language: string): Promise<Array<Object>> =>
    checkServerResponse(await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, requestConfig(RequestMethod.POST, body, jwt, language)));