// @flow
import React from 'react';
import type {Hotel, LanguageType, Localization, Map} from '../types/FlowTypes';
import Home from '@material-ui/icons/Home';
import Accessible from '@material-ui/icons/Accessible';
import Star from '@material-ui/icons/Star';
import Tune from '@material-ui/icons/Tune';
import MyLocation from '@material-ui/icons/MyLocation';
import BeachAccess from '@material-ui/icons/BeachAccess';
import Spa from '@material-ui/icons/Spa';
import LocalFlorist from '@material-ui/icons/LocalFlorist';
import Terrain from '@material-ui/icons/Terrain';
import LocationCity from '@material-ui/icons/LocationCity';
import Group from '@material-ui/icons/Group';
import SportsBasketball from '@material-ui/icons/SportsBasketball';
import ContentLoader from 'react-content-loader';
import {withStyles} from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FetchStatus from '../api/FetchStatus';
import classNames from 'classnames';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {Language} from '../types/FlowTypes';

export const wait: Function = async (milliseconds: number) => await new Promise((resolve: Function) => window.setTimeout(resolve, milliseconds));

export const normalize = (array: Array<Object>, key: string): Map<string, Object> => {
    const normalized = {};
    array.forEach(it => normalized[it[key]] = it);
    return normalized;
};

export const denormalize = (map: Map<string, Object>): Array<Object> => Object.keys(map).map(key => map[key]);

export const getTitleImageForHotel: Function = (hotel: Hotel) => normalize(hotel.Images, 'id')[hotel.titleImageID];

export const getTitleImageSrcSetForHotel: Function = (titleImage: Object) => mapImageToSrcSet(titleImage);

export const mapImageToSrcSet = (image) => {
    return mapImage(`${process.env.REACT_APP_BASE_URL}/images/`, image);
};

export const mapBlogImageToSrcSet = (image) => {
    return mapImage(`${process.env.REACT_APP_BASE_URL}/blog/`, image);
}

const mapImage = (basePath: string, image) => {
    if (image) {
        return {
            webP: {
                lg2x: `${basePath}${image.path}-lg@2x.webp`,
                lg: `${basePath}${image.path}-lg@1x.webp`,
                md2x: `${basePath}${image.path}-md@2x.webp`,
                md: `${basePath}${image.path}-md@1x.webp`,
                sm2x: `${basePath}${image.path}-sm@2x.webp`,
                sm: `${basePath}${image.path}-sm@1x.webp`,
                xs2x: `${basePath}${image.path}-xs@2x.webp`,
                xs: `${basePath}${image.path}-xs.webp`
            },
            fallback: {
                lg2x: `${basePath}${image.path}-lg@2x.${image.type}`,
                lg: `${basePath}${image.path}-lg@1x.${image.type}`,
                md2x: `${basePath}${image.path}-md@2x.${image.type}`,
                md: `${basePath}${image.path}-md@1x.${image.type}`,
                sm2x: `${basePath}${image.path}-sm@2x.${image.type}`,
                sm: `${basePath}${image.path}-sm@1x.${image.type}`,
                xs2x: `${basePath}${image.path}-xs@2x.${image.type}`,
                xs: `${basePath}${image.path}-xs@1x.${image.type}`
            }
        }
    }
    return {webP: {}, fallback: {}}
}

const IconsByName = {
    'Home': Home,
    'Accessible': Accessible,
    'Star': Star,
    'Tune': Tune,
    'MyLocation': MyLocation,
    'BeachAccess': BeachAccess,
    'Spa': Spa,
    'LocalFlorist': LocalFlorist,
    'Terrain': Terrain,
    'LocationCity': LocationCity,
    'Group': Group,
    'SportsBasketball': SportsBasketball
}

export const getIconElementForString: Function = (name: string, className?: string) => {
    return React.createElement(IconsByName[name], {className}, null);
}

export const getContentLoader: Function = (width: number, title?: string) => (
    <div style={{
        width,
        height: width * 1.27
    }}>
        <ContentLoader
            height={293}
            width={236}
            speed={2}
            title={title}
            primaryColor={'#f3f3f3'}
            secondaryColor={'#d6d6d6'}>
            <rect x={'1'} y={'205'} rx={'4'} ry={'4'} width={'210'} height={'17'}/>
            <rect x={'186'} y={'122'} rx={'0'} ry={'0'} width={'0'} height={'0'}/>
            <rect x={'184'} y={'98'} rx={'0'} ry={'0'} width={'0'} height={'0'}/>
            <rect x={'185'} y={'87'} rx={'0'} ry={'0'} width={'0'} height={'0'}/>
            <rect x={'193'} y={'112'} rx={'0'} ry={'0'} width={'6'} height={'2'}/>
            <rect x={'0'} y={'21'} rx={'4'} ry={'4'} width={'236'} height={'144'}/>
            <rect x={'0'} y={'233'} rx={'4'} ry={'4'} width={'169'} height={'17'}/>
            <rect x={'0'} y={'181'} rx={'4'} ry={'4'} width={'119'} height={'9'}/>
            <rect x={'0'} y={'267'} rx={'4'} ry={'4'} width={'73'} height={'9'}/>
            <rect x={'0'} y={'284'} rx={'4'} ry={'4'} width={'54'} height={'9'}/>
        </ContentLoader>
    </div>);

export const mapHotelToHighlight: Function = (hotel: Hotel, language: string) => ({
    id: hotel.id,
    title: hotel.name,
    city: hotel.city,
    price: hotel.price ? hotel.price.toLocaleString() : '',
    texts: hotel.texts,
    srcSet: getTitleImageSrcSetForHotel(getTitleImageForHotel(hotel)),
    tags: hotel.Tags,
    highlightTags: hotel.HotelHighlights ? hotel.HotelHighlights.map(tag => tag.tagID) : []
});

export const getTextForKeyAndLanguage = (dictionary: Map<string, Map<string, string>>, language: string, key: string, useFallback = true): string => {
    if (dictionary && dictionary[language] && dictionary[language][key]) {
        return dictionary[language][key];
    } else if (useFallback && dictionary && dictionary['de_DE'] && dictionary['de_DE'][key]) {
        return dictionary['de_DE'][key];
    }
    return '';
};

export const ContactSnackbarContent = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        background: 'white',
        fontSize: 14,
        flexWrap: 'nowrap'
    },
    error: {
        color: 'red'
    },
    message: {
        lineHeight: '16px'
    }
}))((props) => {
    return (
        <SnackbarContent
            className={classNames(props.classes.root, props.level === FetchStatus.ERROR ? props.classes.error : '')}
            aria-describedby={'client-snackbar'}
            classes={{
                message: props.classes.message
            }}
            message={
                <span id={'client-snackbar'}>
                        {props.icon}
                    {props.message}
                    </span>
            }
            action={[
                <IconButton key={'close'} aria-label={'close'} color={'inherit'} onClick={props.onClose}>
                    <CloseIcon className={props.classes.icon}/>
                </IconButton>
            ]}
        />
    );
});

export const getTagForName: Function = (tags: Array<Object>, name: string): Object | typeof undefined => tags.filter(tag => tag.name === name)[0];

export const getTagsForTagCategory: Function = (tagCategories: Array<Object>, name: string): Array<Object> => {
    const category = tagCategories.filter(tagCategory => tagCategory.name === name)[0];
    if (category) {
        return category.Tags || category.elements;
    }
    return []
};

export const AppSnackbarContent = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        background: 'white',
        fontSize: 14,
        flexWrap: 'nowrap'
    },
    error: {
        color: 'red'
    },
    message: {
        lineHeight: '16px'
    }
}))((props) => {
    return (
        <SnackbarContent
            className={classNames(props.classes.root, props.level === FetchStatus.ERROR ? props.classes.error : '')}
            aria-describedby={'client-snackbar'}
            classes={{
                message: props.classes.message
            }}
            message={
                <span id={'client-snackbar'}>
                        {props.icon}
                    {props.message}
                    </span>
            }
        />
    );
});

export const existsAndNotEmpty: Function = (object: Object, key: string): boolean => object[key]?.toString().trim();

export const getPopper: Function = (showPopper: boolean, anchorRef: Object,
                                    surroundClass: string, close: Function, content: Object) => (
    <Popper open={showPopper}
            anchorEl={anchorRef.current} transition
            style={{zIndex: 10000}}>
        {({TransitionProps, placement}) => (
            <Grow
                {...TransitionProps}
                style={{transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'}}>
                <div className={surroundClass}>
                    <ClickAwayListener onClickAway={close}>
                        {content}
                    </ClickAwayListener>
                </div>
            </Grow>
        )}
    </Popper>);

export const getLowestHotelPrice: Function = (tagCategories: Array<Object> | Map<number, Object>, tags: Array<Object>) => {
    const prices = getTagsForTagCategory(tagCategories, 'prices');
    let price = '';
    if (prices.length) {
        const normalizedHotelTags = Array.isArray(tags) ? normalize(tags, 'id') : tags;
        const hotelTags = prices.filter(tag => !!normalizedHotelTags[tag.id]).map(tag => normalizedHotelTags[tag.id]);
        if (hotelTags.length) {
            const tag = hotelTags.filter(tag => tag.type === 'Number').sort((t1, t2) => {
                if (t1.value) {
                    return t1.value - t2.value;
                } else {
                    return t1.HotelTag.value - t2.HotelTag.value;
                }
            })[0];
            if (tag) {
                if (tag.value) {
                    price = tag.value;
                } else {
                    price = tag.HotelTag.value;
                }
            }
        }
    }
    return price;
}

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const getLocalizationValueForLanguageAndKey = (localizations: Localization[], key: LocalizationKey, language: LanguageType) => localizations.filter(it => it.lang === language && it.key === key)[0]?.value || localizations.filter(it => it.lang === Language.GERMAN && it.key === key)[0]?.value || '';

export const shuffleArray = (array: Array<any>) => {
    let j, x, i;
    for (i = array.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = array[i];
        array[i] = array[j];
        array[j] = x;
    }
    return array;
}