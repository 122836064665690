// @flow
import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@material-ui/core';
import ArrowDown from '../icons/ArrowDown';
import {makeStyles, Theme} from '@material-ui/core/styles';

type CookieAccordionProps = {
    title: string | React.Element;
    description: string | React.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
    expandedSummary: {
        minHeight: '38px !important',
        margin: '0 !important',
    },
    expandIcon: {
        marginRight: '-12px !important',
        position: 'absolute',
        right: 0,
        top: 0,
        width: 38,
        height: 38,
        padding: 0
    },
    icon: {
        width: 14,
        height: 9,
        color: theme.palette.primary.dark
    },
    accordion: {
        marginTop: theme.spacing(2),
        boxShadow: 'none',
        '&::before': {
            height: 0
        }
    },
    accordionExpanded: {
        margin: `${theme.spacing(2)}px 0 0 0 !important`
    },
    summary: {
        border: 'none',
        padding: 0,
        minHeight: 'auto'
    },
    summaryContent: {
        margin: 0,
        width: '100%',
        height: 38,
        alignItems: 'center'
    },
    details: {
        padding: theme.spacing(1, 0, 0)
    },
    description: {
        color: '#767E8C',
        fontSize: 14,
        lineHeight: '20px'

    },
    title: {
        paddingRight: theme.spacing(4),
        color: theme.palette.primary.dark,
        fontSize: 14,
        fontWeight: 600
    }
}));


const CookieAccordion = ({description, title}: CookieAccordionProps) => {
    const styles = useStyles();

    return (
        <Accordion className={styles.accordion} classes={{
            root: styles.accordion,
            expanded: styles.accordionExpanded
        }}>
            <AccordionSummary classes={{
                root: styles.summary,
                expanded: styles.expandedSummary,
                expandIcon: styles.expandIcon,
                content: styles.summaryContent
            }}
                              expandIcon={<ArrowDown className={styles.icon}/>}
                              aria-label={'essential-cookies'}
                              aria-controls={'essential-cookies-content'}>
                {typeof title === 'string' ? <Typography variant={'h4'} className={styles.title}>
                    {title}
                </Typography> : title}
            </AccordionSummary>
            <AccordionDetails className={styles.details}>
                {typeof description === 'string' ? <Typography variant={'body1'} className={styles.description}>
                    {description}
                </Typography> : description}
            </AccordionDetails>
        </Accordion>
    );
}

export default CookieAccordion;