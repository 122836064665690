// @flow

import type {BlogState} from '../types/FlowTypes';
import FetchStatus from '../api/FetchStatus';
import {handleActions} from 'redux-actions';
import {normalize} from '../helper/helperFunctions';

export const BlogActions = {
    LOAD_BLOG: 'BLOG/LOAD_BLOG',
    FETCH_LOAD_BLOG: 'BLOG/FETCH_LOAD_BLOG',
    FETCH_LOAD_BLOG_SUCCESS: 'BLOG/FETCH_LOAD_BLOG_SUCCESS',
    FETCH_LOAD_BLOG_ERROR: 'BLOG/FETCH_LOAD_BLOG_ERROR',
    RECORD_POST_VIEW: 'BLOG/RECORD_POST_VIEW',
    RECORD_CATEGORY_VIEW: 'BLOG/RECORD_CATEGORY_VIEW',
    RESET_BLOG_CATEGORY_PAGE: 'BLOG/RESET_BLOG_CATEGORY_PAGE',
    LOAD_BLOG_CATEGORY_POSTS: 'BLOG/LOAD_BLOG_CATEGORY_POSTS',
    FETCH_LOAD_BLOG_CATEGORY_POSTS: 'BLOG/FETCH_LOAD_BLOG_CATEGORY_POSTS',
    FETCH_LOAD_BLOG_CATEGORY_POSTS_SUCCESS: 'BLOG/FETCH_LOAD_BLOG_CATEGORY_POSTS_SUCCESS',
    FETCH_LOAD_BLOG_CATEGORY_POSTS_ERROR: 'BLOG/FETCH_LOAD_BLOG_CATEGORY_POSTS_ERROR',
    LOAD_BLOG_POST: 'BLOG/LOAD_BLOG_POSTS',
    FETCH_LOAD_BLOG_POST: 'BLOG/FETCH_LOAD_BLOG_POST',
    FETCH_LOAD_BLOG_POST_SUCCESS: 'BLOG/FETCH_LOAD_BLOG_POST_SUCCESS',
    FETCH_LOAD_BLOG_POST_ERROR: 'BLOG/FETCH_LOAD_BLOG_POST_ERROR',
    RESET_LOAD_BLOG_POST_FETCH_STATUS: 'BLOG/RESET_LOAD_BLOG_POST_FETCH_STATUS'
};

const initialState: BlogState = {
    posts: {},
    categories: {},
    latest: [],
    mostViewed: [],
    highlights: [],
    postFetchStatus: FetchStatus.DEFAULT,
    fetchStatus: FetchStatus.DEFAULT,
    postsByCategory: {}
}


const reducer: any = handleActions({
    [BlogActions.FETCH_LOAD_BLOG]: (state: BlogState) => ({
        ...state,
        fetchStatus: FetchStatus.ACTIVE
    }),
    [BlogActions.FETCH_LOAD_BLOG_ERROR]: (state: BlogState) => ({
        ...state,
        fetchStatus: FetchStatus.ERROR
    }),
    [BlogActions.FETCH_LOAD_BLOG_SUCCESS]: (state: BlogState, action) => {
        const postsByCategory = {};
        const posts = [...action.payload.mostViewed, ...action.payload.latest, ...action.payload.highlights];
        posts.forEach(it => {
            if (!postsByCategory[it.blogCategoryID]) {
                postsByCategory[it.blogCategoryID] = [];
            }
            if (!postsByCategory[it.blogCategoryID].includes(it.id)) {
                postsByCategory[it.blogCategoryID].push(it.id);
            }
        });
        return {
            ...state,
            fetchStatus: FetchStatus.SUCCESS,
            posts: normalize(posts, 'id'),
            categories: normalize(action.payload.categories.map(it => ({
                ...it, page: -1,
                pageSize: 24,
                numberOfPosts: -1,
                postFetchStatus: FetchStatus.DEFAULT
            })), 'id'),
            latest: action.payload.latest.map(it => it.id),
            mostViewed: action.payload.mostViewed.map(it => it.id),
            highlights: action.payload.highlights.map(it => it.id),
            postsByCategory
        }
    },
    [BlogActions.FETCH_LOAD_BLOG_CATEGORY_POSTS]: (state: BlogState, action) => {
        if (state.categories[action.payload]) {
            const category = {...state.categories[action.payload], postFetchStatus: FetchStatus.ACTIVE};
            return {
                ...state,
                categories: {...state.categories, [action.payload]: category}
            }
        }
        return state;
    },
    [BlogActions.FETCH_LOAD_BLOG_CATEGORY_POSTS_ERROR]: (state: BlogState, action) => {
        if (state.categories[action.payload]) {
            const category = {...state.categories[action.payload], postFetchStatus: FetchStatus.ERROR};
            return {
                ...state,
                categories: {...state.categories, [action.payload]: category}
            }
        }
        return state;
    },
    [BlogActions.FETCH_LOAD_BLOG_CATEGORY_POSTS_SUCCESS]: (state: BlogState, action) => {
        if (state.categories[action.payload.id]) {
            const category = {...state.categories[action.payload.id]};
            category.numberOfPosts = action.payload.count;
            category.page++;
            category.postFetchStatus = FetchStatus.SUCCESS;
            let postsByCategory = [...(state.postsByCategory[action.payload.id] || [])];
            postsByCategory = [...postsByCategory, ...action.payload.posts.filter(it => !postsByCategory.includes(it.id)).map(it => it.id)];
            return {
                ...state,
                posts: {...state.posts, ...normalize(action.payload.posts, 'id')},
                categories: {...state.categories, [action.payload.id]: category},
                postsByCategory: {...state.postsByCategory, [action.payload.id]: postsByCategory}
            }
        }
        return state;
    },
    [BlogActions.FETCH_LOAD_BLOG_POST]: (state: BlogState) => {
        return {
            ...state,
            postFetchStatus: FetchStatus.ACTIVE
        }
    },
    [BlogActions.FETCH_LOAD_BLOG_POST_SUCCESS]: (state: BlogState, action) => {
        return {
            ...state,
            postFetchStatus: FetchStatus.SUCCESS,
            posts: {...state.posts, [action.payload.id]: action.payload}
        }
    },
    [BlogActions.FETCH_LOAD_BLOG_POST_ERROR]: (state: BlogState) => {
        return {
            ...state,
            postFetchStatus: FetchStatus.ERROR
        }
    },
    [BlogActions.RESET_LOAD_BLOG_POST_FETCH_STATUS]: (state: BlogState) => {
        return {
            ...state,
            postFetchStatus: FetchStatus.DEFAULT
        }
    }
}, initialState);

export default reducer;