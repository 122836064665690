// @flow
import {take, put, call, spawn} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import {fetchRequest, Route} from '../api/Api';
import {SearchActions} from '../reducer/search';


export function* loadCounties() {
    while (yield take(SearchActions.LOAD_COUNTIES)) {
        try {
            // Get search configuration from state
            yield put(createAction(SearchActions.FETCH_LOAD_COUNTIES)());
            const counties = yield call(fetchRequest, Route.LOAD_COUNTIES);
            yield put(createAction(SearchActions.FETCH_LOAD_COUNTIES_SUCCESS, (counties: Array<Object>, countiesWithHotels: Array<number>) => ({
                counties,
                countiesWithHotels
            }))(counties.counties, counties.countiesWithHotels));
        } catch (e) {
            console.error(e);
            yield put(createAction(SearchActions.FETCH_LOAD_COUNTIES_ERROR)())
        }

    }
}

function* loadSaga() {
    yield spawn(loadCounties);
}

export default loadSaga;