// @flow
import React, {lazy, useMemo, useState, useEffect, Suspense} from 'react';
import {Switch} from 'react-router-dom';
import {Route} from 'react-router';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import type {AppState} from '../../types/FlowTypes';
import Menu from './skeleton/Menu';
import Footer from './skeleton/Footer';
import FetchStatus from '../../api/FetchStatus';
import {AppSnackbarContent} from '../../helper/helperFunctions';
import {useDispatch, useSelector} from 'react-redux';
import {createAction} from 'redux-actions';
import {HotelActions} from '../../reducer/hotels';
import {SearchActions} from '../../reducer/search';
import {ApplicationActions} from '../../reducer/application';
import {UserActions} from '../../reducer/user';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logo from '../../images/src/logo/logo.svg';
import MobileFooter from './skeleton/MobileFooter';
import {BlogActions} from '../../reducer/blog';
import CookieNotification from './cookies/CookieNotification';
import getGA from '../../analytics/GoogleAnalytics';


const Home = lazy(() => import('../presentational/sites/Home'));
const AboutUs = lazy(() => import('../presentational/sites/AboutUs'));
const ContactSite = lazy(() => import('../container/ContactSite'));
const LegalNotice = lazy(() => import('../presentational/sites/legal/LegalNotice'));
const TermsOfUse = lazy(() => import('../presentational/sites/legal/TermsOfUse'));
const Privacy = lazy(() => import('../presentational/sites/legal/Privacy'));
const Disclaimer = lazy(() => import('../presentational/sites/legal/Disclaimer'));
const DetailSite = lazy(() => import('../container/DetailSite'));
const DestinationSite = lazy(() => import('../container/DestinationSite'));
const LoginSite = lazy(() => import('../container/LoginSite'));
const RegisterSite = lazy(() => import('../container/RegisterSite'));
const Advertise = lazy(() => import('../presentational/sites/advertise/Advertise'));
const FAQSite = lazy(() => import('../presentational/sites/faq/FAQ'));
const Blog = lazy(() => import('../presentational/sites/blog/Blog'));


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            overflowX: 'hidden'
        }
    },
    snackBar: {
        fontSize: 14,
        lineHeight: '24px',
        background: 'white',
    },
    snackBarButton: {
        background: 'white',
        color: '#2F89FC',
        textTransform: 'none',
        fontSize: 14
    },
    imageHint: {
        color: '#102434',
        '& span': {
            fontWeight: 600
        }
    },
    privacyLink: {
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'pointer',
        transition: 'all 0.25s cubic-bezier(0.55, 0, 0.55, 1)',
        '&:hover': {
            color: '#2F89FC',
        }
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: 'white',
        zIndex: 2000
    },
    logo: {
        width: 400,
        [theme.breakpoints.down('xs')]: {
            width: '80%'
        }
    }
}));

const App = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isWidthDownXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const isWidthDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isWidthDownLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const {loginFetchStatus, enableAnalytics} = useSelector((state: AppState) => ({
        loginFetchStatus: state.application.loginFetchStatus,
        enableAnalytics: state.application.cookies.trackingEnabled,
    }))

    const [snackbar, setSnackbar] = useState({
        showSnackbar: false,
        snackbarText: '',
        snackbarLevel: FetchStatus.SUCCESS
    });

    useEffect(() => {
        let pageSize = 12;
        if (isWidthDownXS) {
            pageSize = 4;
        }
        if (isWidthDownSM) {
            pageSize = 6;
        }
        if (isWidthDownLG) {
            pageSize = 9;
        }
        dispatch(createAction(HotelActions.SET_HOTEL_PAGE_SIZE)(pageSize));
    }, [dispatch, isWidthDownLG, isWidthDownSM, isWidthDownXS]);

    useEffect(() => {
        dispatch(createAction(ApplicationActions.SET_LANGUAGE)((navigator.language || navigator.userLanguage).replace('-', '_')));
        dispatch(createAction(UserActions.RESET_UPLOAD_IMAGES_ERROR)());
        dispatch(createAction(HotelActions.LOAD_SEARCH_CATEGORIES)());
        dispatch(createAction(HotelActions.LOAD_HOTELS)());
        dispatch(createAction(SearchActions.LOAD_COUNTIES)());
        dispatch(createAction(BlogActions.LOAD_BLOG)());
        dispatch(createAction(ApplicationActions.CHECK_COOKIE_NOTIFICATION)());
    }, [dispatch]);

    useEffect(() => {
        if (loginFetchStatus === FetchStatus.ERROR) {
            setSnackbar({
                showSnackbar: true,
                snackbarText: 'Bitte überprüfen Sie Ihr Passwort.',
                snackbarLevel: FetchStatus.ERROR
            });
        }
    }, [loginFetchStatus]);


    const loader = useMemo(() => (
        <div className={classes.loader}>
            <img src={logo} alt={'Rolli-Hotels.de'} title={'Rolli-Hotels.de'}
                 className={classes.logo}/>
        </div>
    ), [classes.loader, classes.logo]);

    return (
        <React.Fragment>
            <Menu/>
            <div className={classes.root}>
                <Switch>
                    <Suspense fallback={loader}>
                        <Route path={'/'} component={() => {
                            if (enableAnalytics) {
                                getGA().set({page: window.location.pathname + window.location.search});
                                getGA().pageview(window.location.pathname + window.location.search);
                                // getFacebookPixel().pageView();
                            }
                            return null;
                        }}/>
                        <Route path='/aboutUs' component={AboutUs}/>
                        <Route path='/contact' component={ContactSite}/>
                        <Route path='/legal/LegalNotice' component={LegalNotice}/>
                        <Route path='/legal/Privacy' component={Privacy}/>
                        <Route path='/legal/Disclaimer' component={Disclaimer}/>
                        <Route path='/legal/termsOfUse' component={TermsOfUse}/>
                        <Route path='/hotels' component={DestinationSite}/>
                        <Route exact path='/hotel/:id' component={DetailSite}/>
                        <Route path='/hotel/:id/:name' component={DetailSite}/>
                        <Route path='/login' component={LoginSite}/>
                        <Route path='/register' component={RegisterSite}/>
                        <Route path='/advertise' component={Advertise}/>
                        <Route path='/faq' component={FAQSite}/>
                        <Route path='/blog' component={Blog}/>
                        <Route exact path='/' component={Home}/>
                    </Suspense>
                </Switch>
            </div>
            {isWidthDownXS ? <MobileFooter/> : <Footer/>}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={snackbar.showSnackbar}
                autoHideDuration={6000}
                onClose={() => setSnackbar({...snackbar, showSnackBar: false})}>
                <AppSnackbarContent
                    onClose={() => setSnackbar({...snackbar, showSnackBar: false})}
                    message={snackbar.snackbarText}
                    icon={snackbar.snackbarIcon}
                    level={snackbar.snackbarLevel}
                />
            </Snackbar>
            <CookieNotification/>
        </React.Fragment>
    );

}

export default App;
