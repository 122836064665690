// @flow
import {handleActions} from 'redux-actions';
import type {UserState} from '../types/FlowTypes';
import FetchStatus from '../api/FetchStatus';

export const UserActions = {
    LOGOUT: 'USER/LOGOUT',
    LOGIN_USER: 'USER/LOGIN_USER',
    FETCH_LOGIN_USER: 'USER_ACTIONS/FETCH_LOGIN_USER',
    FETCH_LOGIN_USER_SUCCESS: 'USER_ACTIONS/FETCH_LOGIN_USER_SUCCESS',
    FETCH_LOGIN_USER_ERROR: 'USER_ACTIONS/FETCH_LOGIN_USER_ERROR',
    SET_ACTIVE_STEP: 'USER_ACTIONS/SET_ACTIVE_STEP',
    TOGGLE_SELECTED_REQUIREMENT: 'USER_ACTIONS/TOGGLE_SELECTED_REQUIREMENT',
    SET_HOTEL_PROPERTY: 'USER_ACTIONS/SET_HOTEL_PROPERTY',
    SET_HOTEL_USER_PROPERTY: 'USER_ACTIONS/SET_HOTEL_USER_PROPERTY',
    SET_HOTEL_PROPERTY_FOR_LANGUAGE: 'USER_ACTIONS/SET_HOTEL_PROPERTY_FOR_LANGUAGE',
    TOGGLE_HOTEL_TAG: 'USER_ACTIONS/TOGGLE_HOTEL_TAG',
    SET_HOTEL_TAG_VALUE: 'USER_ACTIONS/SET_HOTEL_TAG_VALUE',
    UPLOAD_IMAGES: 'USER_ACTIONS/UPLOAD_IMAGES',
    FETCH_UPLOAD_IMAGES: 'USER_ACTIONS/FETCH_UPLOAD_IMAGES',
    FETCH_UPLOAD_IMAGES_SUCCESS: 'USER_ACTIONS/FETCH_UPLOAD_IMAGES_SUCCESS',
    FETCH_UPLOAD_IMAGES_ERROR: 'USER_ACTIONS/FETCH_UPLOAD_IMAGES_ERROR',
    RESET_UPLOAD_IMAGES_ERROR: 'USER_ACTIONS/RESET_UPLOAD_IMAGES_ERROR',
    ADD_IMAGE_TO_HOTEL: 'USER_ACTIONS/ADD_IMAGE_TO_HOTEL',
    SET_TITLE_IMAGE_TO_HOTEL: 'USER_ACTIONS/SET_TITLE_IMAGE_TO_HOTEL',
    DELETE_IMAGE: 'USER_ACTIONS/DELETE_IMAGE',
    FETCH_DELETE_IMAGE: 'USER_ACTIONS/FETCH_DELETE_IMAGE',
    FETCH_DELETE_IMAGE_SUCCESS: 'USER_ACTIONS/FETCH_DELETE_IMAGE_SUCCESS',
    FETCH_DELETE_IMAGE_ERROR: 'USER_ACTIONS/FETCH_DELETE_IMAGE_ERROR',
    SAVE_HOTEL: 'USER_ACTIONS/SAVE_HOTEL',
    FETCH_SAVE_HOTEL: 'USER_ACTIONS/FETCH_SAVE_HOTEL',
    FETCH_SAVE_HOTEL_SUCCESS: 'USER_ACTIONS/FETCH_SAVE_HOTEL_SUCCESS',
    FETCH_SAVE_HOTEL_ERROR: 'USER_ACTIONS/FETCH_SAVE_HOTEL_ERROR',
    RESTART_REGISTER: 'USER_ACTIONS/RESTART_REGISTER',
    SYNC_HOTEL_REGISTRATION: 'USER_ACTIONS/SYNC_HOTEL_REGISTRATION',
    FETCH_SYNC_HOTEL_REGISTRATION: 'USER_ACTIONS/FETCH_SYNC_HOTEL_REGISTRATION',
    FETCH_SYNC_HOTEL_REGISTRATION_SUCCESS: 'USER_ACTIONS/FETCH_SYNC_HOTEL_REGISTRATION_SUCCESS',
    FETCH_SYNC_HOTEL_REGISTRATION_ERROR: 'USER_ACTIONS/FETCH_SYNC_HOTEL_REGISTRATION_ERROR',
    LOAD_HOTEL_REGISTRATION: 'USER_ACTIONS/LOAD_HOTEL_REGISTRATION',
    FETCH_LOAD_HOTEL_REGISTRATION: 'USER_ACTIONS/FETCH_LOAD_HOTEL_REGISTRATION',
    FETCH_LOAD_HOTEL_REGISTRATION_SUCCESS: 'USER_ACTIONS/FETCH_LOAD_HOTEL_REGISTRATION_SUCCESS',
    FETCH_LOAD_HOTEL_REGISTRATION_ERROR: 'USER_ACTIONS/FETCH_LOAD_HOTEL_REGISTRATION_ERROR',
    SET_IMAGE_ORDER: 'USER_ACTIONS/SET_IMAGE_ORDER',
    SET_HOTEL_PENDING: 'USER_ACTIONS/SET_HOTEL_PENDING',
    MERGE_HOTEL_REGISTRATION: 'USER_ACTIONS/MERGE_HOTEL_REGISTRATION',
    START_PERIODIC_HOTEL_REGISTRATION_MERGE: 'USER_ACTIONS/START_PERIODIC_HOTEL_REGISTRATION_MERGE'
};

const initialState: UserState = {
    jwt: null,
    data: null,
    hotel: {
        published: false,
        lastCompletedStep: 0,
        allowPhoneContact: false
    },
    activeStep: 0,
    selectedRequirements: {},
    imageUploadErrors: []
};

const getHotelFromPayload = (payload: Object) => {
    if (payload.hotel.hotelRegistration) {
        return JSON.parse(payload.hotel.hotelRegistration.registrationJSON);
    }
    return {
        hotel: {
            ...payload.hotel, ...{
                Tags: {},
                Images: [],
                texts: {
                    'de_DE': {}
                }
            }
        }
    };
};

export default handleActions({
        [UserActions.LOGOUT]: (): UserState => ({
            ...initialState
        }),
        [UserActions.FETCH_LOGIN_USER]: (state: UserState): UserState => ({
            ...state,
            loginFetchStatus: FetchStatus.ACTIVE
        }),
        [UserActions.FETCH_LOGIN_USER_SUCCESS]: (state: UserState, action: Object): UserState => ({
            ...state,
            jwt: action.payload.token,
            data: action.payload.user,
            ...getHotelFromPayload(action.payload)
        }),
        [UserActions.FETCH_LOGIN_USER_ERROR]: (state: UserState): UserState => ({
            ...state,
            jwt: null,
            data: null
        }),
        [UserActions.SET_ACTIVE_STEP]: (state: UserState, action: Object): UserState => ({
            ...state,
            activeStep: action.payload > -1 && action.payload < 6 ? action.payload : state.activeStep,
            hotel: {
                ...state.hotel,
                lastCompletedStep: Math.max(state.hotel.lastCompletedStep || -1, action.payload > -1 && action.payload < 6 ? action.payload : state.activeStep)
            }
        }),
        [UserActions.TOGGLE_SELECTED_REQUIREMENT]: (state: UserState, action: Object): UserState => ({
            ...state,
            selectedRequirements: {
                ...state.selectedRequirements,
                [action.payload]: !state.selectedRequirements[action.payload]
            }
        }),
        [UserActions.SET_HOTEL_PROPERTY]: (state: UserState, action: Object): UserState => ({
            ...state,
            hotel: {
                ...state.hotel,
                [action.payload.property]: action.payload.value
            }
        }),
        [UserActions.SET_HOTEL_USER_PROPERTY]: (state: UserState, action: Object): UserState => ({
            ...state,
            hotel: {
                ...state.hotel,
                user: {
                    ...(state.hotel.user || ''),
                    [action.payload.property]: action.payload.value
                }
            }
        }),
        [UserActions.SET_HOTEL_PROPERTY_FOR_LANGUAGE]: (state: UserState, action: Object): UserState => {
            const texts = {...state.hotel.texts, ...{}};
            if (!texts[action.payload.language]) {
                texts[action.payload.language] = {};
            }
            texts[action.payload.language][action.payload.property] = action.payload.value;
            return {
                ...state,
                hotel: {
                    ...state.hotel,
                    texts
                }
            }
        },
        [UserActions.TOGGLE_HOTEL_TAG]: (state: UserState, action: Object): UserState => {
            const Tags = {...state.hotel.Tags};
            if (Tags[action.payload.id]) {
                delete Tags[action.payload.id];
            } else {
                Tags[action.payload.id] = action.payload
            }
            return {
                ...state,
                hotel: {
                    ...state.hotel,
                    Tags
                }
            }
        },
        [UserActions.SET_HOTEL_TAG_VALUE]: (state: UserState, action: Object): UserState => {
            const Tags = {...state.hotel.Tags};
            if (Tags[action.payload.id] && Tags[action.payload.id].type === 'Number' && !action.payload.value.trim()) {
                delete Tags[action.payload.id];
            } else {
                Tags[action.payload.id] = action.payload;
                if (Tags[action.payload.id].type === 'Text') {
                    Tags[action.payload.id].value = '';
                }
            }
            return {
                ...state,
                hotel: {
                    ...state.hotel,
                    Tags
                }
            }
        },
        [UserActions.ADD_IMAGE_TO_HOTEL]: (state: UserState, action: Object): UserState => {
            const images = [...(state.hotel.Images || []), {...action.payload, hidden: true}];
            images.forEach((image, index) => {
                image.order = image.order || index;
            });
            return {
                ...state,
                hotel: {
                    ...state.hotel,
                    Images: images
                }
            }
        },
        [UserActions.FETCH_UPLOAD_IMAGES_SUCCESS]: (state: UserState, action: Object): UserState => {
            let titleImageID = state.hotel.titleImageID;
            if (!titleImageID && state.hotel.Images.length) {
                titleImageID = state.hotel.Images[0].id;
            }
            return {
                ...state,
                hotel: {
                    ...state.hotel,
                    Images: [...state.hotel.Images].map(image => ({...image, hidden: false})),
                    titleImageID
                }
            }
        },
        [UserActions.FETCH_UPLOAD_IMAGES_ERROR]: (state: UserState, action: Object): UserState => ({
            ...state,
            imageUploadErrors: [...(state.imageUploadErrors || []), ...[action.payload]]
        }),
        [UserActions.RESET_UPLOAD_IMAGES_ERROR]: (state: UserState): UserState => ({
            ...state,
            imageUploadErrors: []
        }),
        [UserActions.SET_TITLE_IMAGE_TO_HOTEL]: (state: UserState, action: Object): UserState => ({
            ...state,
            hotel: {
                ...state.hotel,
                titleImageID: action.payload
            }
        }),
        [UserActions.SET_IMAGE_ORDER]: (state: UserState, action: Object): UserState => {
            const images = [...state.hotel.Images];
            images.forEach(image => {
                if (action.payload[image.id] !== undefined) {
                    image.order = action.payload[image.id];
                }
            });
            const titleImage = images.sort((i1, i2) => i1.order - i2.order)[0];
            return {
                ...state,
                hotel: {
                    ...state.hotel,
                    Images: images,
                    titleImageID: titleImage ? titleImage.id : undefined
                }
            }
        },
        [UserActions.FETCH_DELETE_IMAGE]: (state: UserState, action: Object): UserState => {
            const Images = [...state.hotel.Images];
            Images.forEach(image => {
                if (image.id === action.payload) {
                    image.deleteFetchStatus = FetchStatus.ACTIVE
                }
            });
            return {
                ...state,
                hotel: {
                    ...state.hotel,
                    Images
                }
            }
        },
        [UserActions.FETCH_DELETE_IMAGE_SUCCESS]: (state: UserState, action: Object): UserState => {
            let Images = [...state.hotel.Images];
            let imageIndex = -1;
            Images.forEach((image, index) => {
                if (image.id === action.payload) {
                    imageIndex = index;
                }
            });
            if (imageIndex > -1) {
                Images.splice(imageIndex, 1);
            }
            return {
                ...state,
                hotel: {
                    ...state.hotel,
                    Images,
                    titleImageID: state.hotel.titleImageID === action.payload ? undefined : state.hotel.titleImageID
                }
            }
        },
        [UserActions.FETCH_DELETE_IMAGE_ERROR]: (state: UserState, action: Object): UserState => {
            const Images = [...state.hotel.Images];
            Images.forEach(image => {
                if (image.id === action.payload) {
                    image.deleteFetchStatus = FetchStatus.ERROR
                }
            });
            return {
                ...state,
                hotel: {
                    ...state.hotel,
                    Images
                }
            }
        },
        [UserActions.RESTART_REGISTER]: (state: UserState): UserState => ({
            ...state,
            activeStep: 0
        }),
        [UserActions.FETCH_LOAD_HOTEL_REGISTRATION_SUCCESS]: (state: UserState, action: Object): UserState => ({
            ...state,
            hotel: action.payload.hotel.hotelRegistration ? JSON.parse(action.payload.hotel.hotelRegistration.registrationJSON) : {
                ...action.payload.hotel,
                Tags: {},
                Images: [],
                texts: {
                    'de_DE': {}
                }
            }
        }),
        [UserActions.SET_HOTEL_PENDING]: (state: UserState): UserState => ({
            ...state,
            hotel: {
                ...state.hotel,
                pending: true
            }
        }),
        [UserActions.SET_ALLOW_PHONE_CONTACT]: (state: UserState, action: Object): UserState => ({
            ...state,
            hotel: {
                ...state.hotel,
                allowPhoneContact: action.payload
            }
        }),
        [UserActions.SET_ALLOW_WEBSITE_REQUEST]: (state: UserState, action: Object): UserState => ({
            ...state,
            hotel: {
                ...state.hotel,
                allowWebsiteRequest: action.payload
            }
        }),
        [UserActions.FETCH_SYNC_HOTEL_REGISTRATION_SUCCESS]: (state: UserState, action: Object): UserState => ({
            ...state,
            hotel: {
                ...state.hotel,
                lastSync: action.payload
            }
        }),
        [UserActions.MERGE_HOTEL_REGISTRATION]: (state: UserState, action: Object): UserState => {
            const parsed = JSON.parse(action.payload.hotel.hotelRegistration.registrationJSON);
            const merged = {
                ...state,
                hotel: {
                    ...state.hotel,
                    ...parsed.hotel
                },
                selectedRequirements: {
                    ...state.selectedRequirements,
                    ...parsed.selectedRequirements
                }
            };
            merged.hotel.lastSync = parsed.lastSync;
            return merged;
        }
    },
    initialState);