import {takeLatest, put, call, spawn, select, takeEvery} from 'redux-saga/effects';
import {BlogActions} from '../reducer/blog';
import {createAction} from 'redux-actions';
import {fetchRequest, post, Route} from '../api/Api';
import type {AppState, BlogCategory} from '../types/FlowTypes';

function* recordPostViewSaga() {
    yield takeLatest(BlogActions.RECORD_POST_VIEW, function* (action) {
        try {
            yield call(post, Route.RECORD_POST_VIEW, {id: action.payload.id});
        } catch (e) {
            console.error(e);
        }
    });
}

function* recordCategoryViewSaga() {
    yield takeLatest(BlogActions.RECORD_CATEGORY_VIEW, function* (action) {
        try {
            yield call(post, Route.RECORD_CATEGORY_VIEW, {id: action.payload.id});
        } catch (e) {
            console.error(e);
        }
    });
}

function* loadBlogSaga() {
    yield takeLatest(BlogActions.LOAD_BLOG, function* () {
        try {
            yield put(createAction(BlogActions.FETCH_LOAD_BLOG)());
            const blog = yield call(fetchRequest, Route.LOAD_BLOG);
            yield put(createAction(BlogActions.FETCH_LOAD_BLOG_SUCCESS)(blog));
        } catch (e) {
            console.error(e);
            yield put(createAction(BlogActions.FETCH_LOAD_BLOG_ERROR)());
        }
    });
}

function* loadBlogCategoryPostsSaga() {
    yield takeLatest(BlogActions.LOAD_BLOG_CATEGORY_POSTS, function* (action) {
        try {
            const category: BlogCategory = yield select((state: AppState) => state.blog.categories[action.payload]);
            if (category) {
                yield put(createAction(BlogActions.FETCH_LOAD_BLOG_CATEGORY_POSTS)(action.payload));
                const {
                    posts,
                    count
                } = yield call(fetchRequest, Route.LOAD_BLOG_CATEGORY_POSTS(action.payload, category.page + 1, category.pageSize));
                yield put(createAction(BlogActions.FETCH_LOAD_BLOG_CATEGORY_POSTS_SUCCESS)({
                    id: action.payload,
                    posts,
                    count
                }));
            }
        } catch (e) {
            console.error(e);
            yield put(createAction(BlogActions.FETCH_LOAD_BLOG_CATEGORY_POSTS_ERROR)(action.payload));
        }
    });
}


function* loadBlogPostSaga() {
    yield takeEvery(BlogActions.LOAD_BLOG_POST, function* (action) {
        try {
            yield put(createAction(BlogActions.FETCH_LOAD_BLOG_POST)());
            const post = yield call(fetchRequest, Route.LOAD_BLOG_POST(action.payload));
            yield put(createAction(BlogActions.FETCH_LOAD_BLOG_POST_SUCCESS)(post));
        } catch (e) {
            console.error(e);
            yield put(createAction(BlogActions.FETCH_LOAD_BLOG_POST_ERROR)());
        }
        // Reset the fetch status to enable blog post loading for other posts.
        yield put(createAction(BlogActions.RESET_LOAD_BLOG_POST_FETCH_STATUS)());
    });
}

function* blogSaga() {
    yield spawn(loadBlogSaga);
    yield spawn(recordPostViewSaga);
    yield spawn(recordCategoryViewSaga);
    yield spawn(loadBlogCategoryPostsSaga);
    yield spawn(loadBlogPostSaga);
}

export default blogSaga;