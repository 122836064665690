// @flow
import {take, put, call, select, race, delay, spawn, takeLatest} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import {fetchRequest, fetchArrayFromPost, Route, sendContactMessage, post} from '../api/Api';
import {HotelActions} from '../reducer/hotels';
import type {AppState} from '../types/FlowTypes';
import {ApplicationActions} from '../reducer/application';

export function* highlightSaga() {
    while (yield take(ApplicationActions.START_HIGHLIGHT_SAGA)) {
        let runSaga = true;
        while (runSaga) {
            const {next} = yield race({
                next: delay(5000),
                stop: take(ApplicationActions.STOP_HIGHLIGHT_SAGA)
            });
            if (next) {
                const highlights = yield select((state: AppState) => state.application.highlights);
                const current = highlights.availableCategories.indexOf(highlights.selectedCategory);
                yield put(createAction(ApplicationActions.SET_SELECTED_HIGHLIGHT_CATEGORY)(highlights.availableCategories[(current + 1) % highlights.availableCategories.length]));
            } else {
                runSaga = false;
            }
        }
    }
}

export function* loadSearchCategories() {
    while (yield take(HotelActions.LOAD_SEARCH_CATEGORIES)) {
        try {
            yield put(createAction(HotelActions.FETCH_LOAD_SEARCH_CATEGORIES)());
            const categories = yield call(fetchRequest, Route.LOAD_SEARCH_CATEGORIES);
            yield put(createAction(HotelActions.FETCH_LOAD_SEARCH_CATEGORIES_SUCCESS)(categories));
        } catch (e) {
            console.error(e);
            yield put(createAction(HotelActions.FETCH_LOAD_SEARCH_CATEGORIES_ERROR)())
        }
    }
}

export function* loadHighlights() {
    while (yield take(HotelActions.LOAD_HIGHLIGHTS)) {
        try {
            yield put(createAction(HotelActions.FETCH_LOAD_HIGHLIGHTS)());
            const highlights = yield call(fetchRequest, Route.LOAD_HIGHLIGHTS);
            yield put(createAction(HotelActions.FETCH_LOAD_HIGHLIGHTS_SUCCESS)(highlights));
        } catch (e) {
            console.error(e);
            yield put(createAction(HotelActions.FETCH_LOAD_HIGHLIGHTS_ERROR)())
        }
    }
}

export function* loadHotels() {
    while (yield take(HotelActions.SEARCH_HOTELS)) {
        try {
            // Get search configuration from state
            const {
                value,
                tags,
                counties,
                pageSize,
                results,
                dirty
            } = yield select((state: AppState) => state.hotels.search);
            yield put(createAction(HotelActions.FETCH_SEARCH_HOTELS)());
            const result = yield call(fetchArrayFromPost, `${Route.LOAD_HOTELS}/0/${pageSize}`, {
                value,
                tags,
                counties,
                results: dirty ? [] : results
            });
            yield put(createAction(HotelActions.FETCH_SEARCH_HOTELS_SUCCESS)(result));
        } catch (e) {
            console.error(e);
            yield put(createAction(HotelActions.FETCH_SEARCH_HOTELS_ERROR)())
        }

    }
}

export function* loadPage() {
    yield takeLatest(HotelActions.LOAD_HOTEL_PAGE, function* () {
        try {
            // Get search configuration from state
            const {
                value,
                tags,
                counties,
                page,
                pageSize,
                results
            } = yield select((state: AppState) => state.hotels.search);
            yield put(createAction(HotelActions.FETCH_LOAD_HOTEL_PAGE)());
            const result = yield call(fetchArrayFromPost, `${Route.LOAD_HOTELS}/${page}/${pageSize}`, {
                value,
                tags,
                counties,
                results
            });
            yield put(createAction(HotelActions.FETCH_LOAD_HOTEL_PAGE_SUCCESS)(result));
        } catch (e) {
            console.error(e);
            yield put(createAction(HotelActions.FETCH_LOAD_HOTEL_PAGE_ERROR)())
        }
    });
}

export function* loadHotel() {
    while (true) {
        try {
            const action = yield take(HotelActions.LOAD_HOTEL);
            // Get search configuration from state
            yield put(createAction(HotelActions.FETCH_LOAD_HOTEL)());
            const hotel = yield call(fetchRequest, `${Route.LOAD_HOTEL}/${action.payload}`);
            yield put(createAction(HotelActions.FETCH_LOAD_HOTEL_SUCCESS)({...hotel.hotel, ...{hotelTags: hotel.hotelTags}}));
        } catch (e) {
            console.error(e);
            yield put(createAction(HotelActions.FETCH_LOAD_HOTEL_ERROR)())
        }

    }
}


export function* sendRequestMessage() {
    while (true) {
        const action = yield take(HotelActions.SEND_CONTACT_MESSAGE);
        try {
            // Get search configuration from state
            yield put(createAction(HotelActions.FETCH_SEND_CONTACT_MESSAGE)(action.payload.id));
            yield call(sendContactMessage, Route.SEND_HOTEL_CONTACT, action.payload);
            yield put(createAction(HotelActions.FETCH_SEND_CONTACT_MESSAGE_SUCCESS)(action.payload.id));
        } catch (e) {
            console.error(e);
            yield put(createAction(HotelActions.FETCH_SEND_CONTACT_MESSAGE_ERROR)(action.payload.id))
        }

    }
}

export function* logShowPhoneNumberSaga() {
    while (true) {
        const action = yield take(HotelActions.LOG_SHOW_PHONE_NUMBER);
        // Silently send log to server, no ui actions required
        try {
            yield call(post, Route.LOG_SHOW_PHONE_ACTION, {id: action.payload});
        } catch (e) {
            console.error(e);
        }
    }
}

export function* logShowWebsiteSaga() {
    while (true) {
        const action = yield take(HotelActions.LOG_WEBSITE);
        // Silently send log to server, no ui actions required
        try {
            yield put(createAction(HotelActions.FETCH_LOG_WEBSITE)(action.payload));
            yield call(post, Route.LOG_WEBSITE, {id: action.payload});
            yield put(createAction(HotelActions.FETCH_LOG_WEBSITE_SUCCESS)(action.payload));
        } catch (e) {
            yield put(createAction(HotelActions.FETCH_LOG_WEBSITE_ERROR)(action.payload));
            console.error(e);
        }
    }
}

function* hotelSaga() {
    yield spawn(loadSearchCategories);
    yield spawn(loadHighlights);
    yield spawn(loadHotels);
    yield spawn(loadHotel);
    yield spawn(sendRequestMessage);
    yield spawn(highlightSaga);
    yield spawn(logShowPhoneNumberSaga);
    yield spawn(loadPage);
    yield spawn(logShowWebsiteSaga);
}

export default hotelSaga;