// @flow
import {handleActions} from 'redux-actions';
import type {HotelState} from '../types/FlowTypes';
import FetchStatus from '../api/FetchStatus';
import {normalize} from '../helper/helperFunctions';

export const HotelActions = {
    LOAD_SEARCH_CATEGORIES: 'HOTELS/LOAD_SEARCH_CATEGORIES',
    FETCH_LOAD_SEARCH_CATEGORIES: 'HOTELS/FETCH_LOAD_SEARCH_CATEGORIES',
    FETCH_LOAD_SEARCH_CATEGORIES_SUCCESS: 'HOTELS/FETCH_LOAD_SEARCH_CATEGORIES_SUCCESS',
    FETCH_LOAD_SEARCH_CATEGORIES_ERROR: 'HOTELS/FETCH_LOAD_SEARCH_CATEGORIES_ERROR',
    LOAD_HIGHLIGHTS: 'HOTELS/LOAD_HIGHLIGHTS',
    FETCH_LOAD_HIGHLIGHTS: 'HOTELS/FETCH_LOAD_HIGHLIGHTS',
    FETCH_LOAD_HIGHLIGHTS_SUCCESS: 'HOTELS/FETCH_LOAD_HIGHLIGHTS_SUCCESS',
    FETCH_LOAD_HIGHLIGHTS_ERROR: 'HOTELS/FETCH_LOAD_HIGHLIGHTS_ERROR',
    LOAD_HOTELS: 'HOTELS/LOAD_HOTELS',
    FETCH_LOAD_HOTELS: 'HOTELS/FETCH_LOAD_HOTELS',
    FETCH_LOAD_HOTELS_SUCCESS: 'HOTELS/FETCH_LOAD_HOTELS_SUCCESS',
    FETCH_LOAD_HOTELS_ERROR: 'HOTELS/FETCH_LOAD_HOTELS_ERROR',
    UPDATE_SEARCH_VALUE: 'HOTELS/UPDATE_SEARCH_VALUE',
    ADD_SEARCH_TAG: 'HOTELS/ADD_SEARCH_TAG',
    REMOVE_SEARCH_TAG: 'HOTELS/REMOVE_SEARCH_TAG',
    REMOVE_ALL_SEARCH_TAGS: 'HOTELS/REMOVE_ALL_SEARCH_TAGS',
    ADD_SEARCH_COUNTY: 'HOTELS/ADD_SEARCH_COUNTY',
    SET_SEARCH_COUNTIES: 'HOTELS/SET_SEARCH_COUNTIES',
    REMOVE_SEARCH_COUNTY: 'HOTELS/REMOVE_SEARCH_COUNTY',
    SEARCH_HOTELS: 'HOTELS/SEARCH_HOTELS',
    FETCH_SEARCH_HOTELS: 'HOTELS/FETCH_SEARCH_HOTELS',
    FETCH_SEARCH_HOTELS_SUCCESS: 'HOTELS/FETCH_SEARCH_HOTELS_SUCCESS',
    FETCH_SEARCH_HOTELS_ERROR: 'HOTELS/FETCH_SEARCH_HOTELS_ERROR',
    LOAD_HOTEL: 'HOTELS/LOAD_HOTEL',
    FETCH_LOAD_HOTEL: 'HOTELS/FETCH_LOAD_HOTEL',
    FETCH_LOAD_HOTEL_SUCCESS: 'HOTELS/FETCH_LOAD_HOTEL_SUCCESS',
    FETCH_LOAD_HOTEL_ERROR: 'HOTELS/FETCH_LOAD_HOTEL_ERROR',
    TOGGLE_CARD_VIEW: 'HOTELS/TOGGLE_CARD_VIEW',
    SEND_CONTACT_MESSAGE: 'HOTELS/SEND_CONTACT_MESSAGE',
    FETCH_SEND_CONTACT_MESSAGE: 'HOTELS/FETCH_SEND_CONTACT_MESSAGE',
    FETCH_SEND_CONTACT_MESSAGE_SUCCESS: 'HOTELS/FETCH_SEND_CONTACT_MESSAGE_SUCCESS',
    FETCH_SEND_CONTACT_MESSAGE_ERROR: 'HOTELS/FETCH_SEND_CONTACT_MESSAGE_ERROR',
    RESET_FETCH_SEND_CONTACT_MESSAGE_STATUS: 'HOTELS/RESET_FETCH_SEND_CONTACT_MESSAGE_STATUS',
    ADD_HIGHLIGHT_TAG: 'HOTELS/ADD_HIGHLIGHT_TAG',
    REMOVE_HIGHLIGHT_TAG: 'HOTELS/REMOVE_HIGHLIGHT_TAG',
    REMOVE_ALL_SEARCH_COUNTIES: 'HOTELS/REMOVE_ALL_SEARCH_COUNTIES',
    LOG_SHOW_PHONE_NUMBER: 'HOTELS/LOG_SHOW_PHONE_NUMBER',
    LOG_WEBSITE: 'HOTELS/LOG_WEBSITE',
    FETCH_LOG_WEBSITE: 'HOTELS/FETCH_LOG_WEBSITE',
    FETCH_LOG_WEBSITE_SUCCESS: 'HOTELS/FETCH_LOG_WEBSITE_SUCCESS',
    FETCH_LOG_WEBSITE_ERROR: 'HOTELS/FETCH_LOG_WEBSITE_ERROR',
    RESET_LOG_WEBSITE_FETCH_STATUS: 'HOTELS/RESET_LOG_WEBSITE_FETCH_STATUS',
    LOAD_HOTEL_PAGE: 'HOTELS/LOAD_HOTEL_PAGE',
    FETCH_LOAD_HOTEL_PAGE: 'HOTELS/FETCH_LOAD_HOTEL_PAGE',
    FETCH_LOAD_HOTEL_PAGE_SUCCESS: 'HOTELS/FETCH_LOAD_HOTEL_PAGE_SUCCESS',
    FETCH_LOAD_HOTEL_PAGE_ERROR: 'HOTELS/FETCH_LOAD_HOTEL_PAGE_ERROR',
    SET_HOTEL_PAGE_SIZE: 'HOTELS/SET_HOTEL_PAGE_SIZE',
};

const initialState: HotelState = {
    search: {
        results: [],
        value: null,
        tags: [],
        counties: [],
        fetchStatus: FetchStatus.SUCCESS,
        showCard: false,
        page: 0,
        pageSize: 24,
        totalNumberOfResults: 0,
        pageFetchStatus: FetchStatus.SUCCESS,
        dirty: false
    },
    searchCategories: {
        fetchStatus: FetchStatus.SUCCESS,
        categories: {}
    },
    fetchStatus: FetchStatus.SUCCESS,
    elements: {},
    highlights: {
        fetchStatus: FetchStatus.SUCCESS,
        elements: [],
        tags: []
    },
    logWebsiteFetchStatus: FetchStatus.DEFAULT
};

export const getElementWithGroupedLocalizations = (element: Object) => {
    if (element.Localizations && Array.isArray(element.Localizations)) {
        element.texts = {};
        element.Localizations.forEach((localization: Object) => {
            if (!element.texts[localization.lang]) {
                element.texts[localization.lang] = {};
            }
            element.texts[localization.lang][localization.key] = localization.value;
        });
        delete element.Localizations;
    }
    return element;
};

const getNormalizedHotels: Function = (hotels: Array<Object>): Map<number, Object> => {
    const normalized = normalize(hotels, 'id');
    Object.keys(normalized).forEach((id: number) => {
        let element = normalized[id];
        element.contactFetchStatus = FetchStatus.DEFAULT;
        normalized[id] = getElementWithGroupedLocalizations(element);
    });
    return normalized;
};

const getNormalizedSearchCategories = (categories: Array<Object>): Map<Number, Object> => {
    const normalized = normalize(categories, 'id');
    Object.keys(normalized).forEach((id: number) => {
        let element = normalized[id];
        element.contactFetchStatus = FetchStatus.SUCCESS;
        normalized[id] = getElementWithGroupedLocalizations(element);
        element.TagCategories = element.TagCategories.map(tagCategory => getElementWithGroupedLocalizations(tagCategory));
        element.TagCategories.forEach(tagCategory => {
            tagCategory.Tags = tagCategory.Tags.map(tag => getElementWithGroupedLocalizations(tag));
        });
    });
    return normalized;
};

export default handleActions({
        [HotelActions.FETCH_LOAD_SEARCH_CATEGORIES]: (state: HotelState, action: Object) => ({
            ...state,
            searchCategories: {
                ...state.searchCategories,
                fetchStatus: FetchStatus.ACTIVE
            }
        }),
        [HotelActions.FETCH_LOAD_SEARCH_CATEGORIES_SUCCESS]: (state: HotelState, action: Object) => ({
            ...state,
            searchCategories: {
                ...state.searchCategories,
                fetchStatus: FetchStatus.SUCCESS,
                categories: getNormalizedSearchCategories(action.payload)
            }
        }),
        [HotelActions.FETCH_LOAD_SEARCH_CATEGORIES_ERROR]: (state: HotelState, action: Object) => ({
            ...state,
            searchCategories: {
                ...state.searchCategories,
                fetchStatus: FetchStatus.ERROR
            }
        }),
        [HotelActions.FETCH_LOAD_HIGHLIGHTS]: (state: HotelState, action: Object) => ({
            ...state,
            highlights: {
                ...state.highlights,
                fetchStatus: FetchStatus.ACTIVE
            }
        }),
        [HotelActions.FETCH_LOAD_HIGHLIGHTS_SUCCESS]: (state: HotelState, action: Object) => ({
            ...state,
            highlights: {
                ...state.highlights,
                highlights: FetchStatus.SUCCESS,
                elements: action.payload.map(it => it.id)
            },
            elements: {
                ...state.elements,
                ...getNormalizedHotels(action.payload)
            }
        }),
        [HotelActions.FETCH_LOAD_HIGHLIGHTS_ERROR]: (state: HotelState, action: Object) => ({
            ...state,
            highlights: {
                ...state.highlights,
                fetchStatus: FetchStatus.ERROR
            }
        }),
        [HotelActions.FETCH_LOAD_HOTELS]: (state: HotelState, action: Object) => ({
            ...state,
            fetchStatus: FetchStatus.ACTIVE
        }),
        [HotelActions.FETCH_LOAD_HOTELS_SUCCESS]: (state: HotelState, action: Object) => {
            return {
                ...state,
                fetchStatus: FetchStatus.SUCCESS,
                elements: {
                    ...state.elements,
                    ...getNormalizedHotels(action.payload)
                }
            }
        },
        [HotelActions.FETCH_LOAD_HOTELS_ERROR]: (state: HotelState, action: Object) => ({
            ...state,
            fetchStatus: FetchStatus.ERROR
        }),
        [HotelActions.UPDATE_SEARCH_VALUE]: (state: HotelState, action: Object) => ({
            ...state,
            search: {
                ...state.search,
                value: action.payload
            }
        }),
        [HotelActions.ADD_SEARCH_TAG]: (state: HotelState, action: Object) => {
            const reduced = {...state};
            reduced.search = {...reduced.search};
            reduced.search.dirty = true;
            reduced.search.tags = [...reduced.search.tags];
            // Check if tag already exists, if so delete it
            const index = reduced.search.tags.map(tag => tag.id).indexOf(action.payload.id);
            if (index > -1) {
                reduced.search.tags.splice(index, 1);
            }
            reduced.search.tags = [...reduced.search.tags, action.payload];
            return reduced;
        },
        [HotelActions.REMOVE_SEARCH_TAG]: (state: HotelState, action: Object) => {
            const reduced = {...state};
            reduced.search = {...reduced.search};
            reduced.search.dirty = true;
            reduced.search.tags = [...reduced.search.tags];
            const index = reduced.search.tags.map(tag => tag.id).indexOf(action.payload);
            if (index > -1) {
                reduced.search.tags.splice(index, 1);
            }
            return reduced;
        },
        [HotelActions.REMOVE_ALL_SEARCH_TAGS]: (state: HotelState) => {
            const reduced = {...state};
            reduced.search = {...reduced.search};
            reduced.search.dirty = true;
            reduced.search.tags = [];
            return reduced;
        },
        [HotelActions.FETCH_SEARCH_HOTELS]: (state: HotelState) => ({
            ...state,
            search: {
                ...state.search,
                fetchStatus: FetchStatus.ACTIVE
            }
        }),
        [HotelActions.FETCH_SEARCH_HOTELS_SUCCESS]: (state: HotelState, action: Object) => ({
            ...state,
            search: {
                ...state.search,
                results: action.payload.hotels.map(hotel => hotel.id),
                totalNumberOfResults: action.payload.count,
                fetchStatus: FetchStatus.SUCCESS,
                page: 1,
                dirty: false
            },
            elements: {
                ...state.elements,
                ...getNormalizedHotels(action.payload.hotels)
            }
        }),
        [HotelActions.FETCH_SEARCH_HOTELS_ERROR]: (state: HotelState) => ({
            ...state,
            search: {
                ...state.search,
                results: [],
                fetchStatus: FetchStatus.ERROR,
                dirty: false
            }
        }),
        [HotelActions.TOGGLE_CARD_VIEW]: (state: HotelState) => ({
            ...state,
            search: {
                ...state.search,
                showCard: !state.search.showCard
            }
        }),
        [HotelActions.FETCH_LOAD_HOTEL]: (state: HotelState, action: Object) => ({
            ...state,
            fetchStatus: FetchStatus.ACTIVE
        }),
        [HotelActions.FETCH_LOAD_HOTEL_SUCCESS]: (state: HotelState, action: Object) => ({
            ...state,
            fetchStatus: FetchStatus.SUCCESS,
            elements: {
                ...state.elements,
                ...getNormalizedHotels([action.payload])
            }
        }),
        [HotelActions.FETCH_LOAD_HOTEL_ERROR]: (state: HotelState, action: Object) => ({
            ...state,
            fetchStatus: FetchStatus.ERROR
        }),
        [HotelActions.FETCH_SEND_CONTACT_MESSAGE]: (state: HotelState, action: Object) => ({
            ...state,
            elements: {
                ...state.elements,
                ...{
                    [action.payload]: {
                        ...state.elements[action.payload],
                        contactFetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }),
        [HotelActions.FETCH_SEND_CONTACT_MESSAGE_SUCCESS]: (state: HotelState, action: Object) => ({
            ...state,
            elements: {
                ...state.elements,
                [action.payload]: {
                    ...state.elements[action.payload],
                    contactFetchStatus: FetchStatus.SUCCESS
                }
            }
        }),
        [HotelActions.FETCH_SEND_CONTACT_MESSAGE_ERROR]: (state: HotelState, action: Object) => ({
            ...state,
            elements: {
                ...state.elements,
                [action.payload]: {
                    ...state.elements[action.payload],
                    contactFetchStatus: FetchStatus.ERROR
                }
            }
        }),
        [HotelActions.RESET_FETCH_SEND_CONTACT_MESSAGE_STATUS]: (state: HotelState, action: Object) => ({
            ...state,
            elements: {
                ...state.elements,
                [action.payload]: {
                    ...state.elements[action.payload],
                    contactFetchStatus: FetchStatus.DEFAULT
                }
            }
        }),
        [HotelActions.ADD_HIGHLIGHT_TAG]: (state: HotelState, action: Object) => ({
            ...state,
            highlights: {
                ...state.highlights,
                tags: [...state.highlights.tags, action.payload]
            }
        }),
        [HotelActions.REMOVE_HIGHLIGHT_TAG]: (state: HotelState, action: Object) => {
            const reduced = {...state};
            reduced.highlights = {...reduced.highlights};
            reduced.highlights.tags = [...reduced.highlights.tags];
            if (reduced.highlights.tags.includes(action.payload)) {
                reduced.highlights.tags.splice(reduced.highlights.tags.indexOf(action.payload), 1);
            }
            return reduced;
        },
        [HotelActions.ADD_SEARCH_COUNTY]: (state: HotelState, action: Object) => ({
            ...state,
            search: {
                ...state.search,
                counties: [...state.search.counties, action.payload],
                dirty: true
            }
        }),
        [HotelActions.SET_SEARCH_COUNTIES]: (state: HotelState, action: Object) => ({
            ...state,
            search: {
                ...state.search,
                counties: [...action.payload],
                dirty: true
            }
        }),
        [HotelActions.REMOVE_SEARCH_COUNTY]: (state: HotelState, action: Object) => {
            const reduced = {...state};
            reduced.search = {...reduced.search};
            reduced.search.counties = [...reduced.search.counties];
            if (reduced.search.counties.includes(action.payload)) {
                reduced.search.counties.splice(reduced.search.counties.indexOf(action.payload), 1);
            }
            return reduced;
        },
        [HotelActions.REMOVE_ALL_SEARCH_COUNTIES]: (state: HotelState, action: Object) => {
            const reduced = {...state};
            reduced.search = {...reduced.search};
            reduced.search.counties = [];
            return reduced;
        },
        [HotelActions.SET_HOTEL_PAGE_SIZE]: (state: HotelState, action: Object) => {
            const reduced = {...state};
            reduced.search = {...reduced.search};
            reduced.search.pageSize = action.payload;
            return reduced;
        },
        [HotelActions.FETCH_LOAD_HOTEL_PAGE]: (state: HotelState) => ({
            ...state,
            search: {
                ...state.search,
                pageFetchStatus: FetchStatus.ACTIVE
            }
        }),
        [HotelActions.FETCH_LOAD_HOTEL_PAGE_SUCCESS]: (state: HotelState, action: Object) => ({
            ...state,
            search: {
                ...state.search,
                results: [...state.search.results, ...action.payload.hotels.map(hotel => hotel.id)],
                page: state.search.page + 1,
                fetchStatus: FetchStatus.SUCCESS,
                pageFetchStatus: FetchStatus.SUCCESS
            },
            elements: {
                ...state.elements,
                ...getNormalizedHotels(action.payload.hotels)
            }
        }),
        [HotelActions.FETCH_LOAD_HOTEL_PAGE_ERROR]: (state: HotelState) => ({
            ...state,
            search: {
                ...state.search,
                pageFetchStatus: FetchStatus.ERROR
            }
        }),
        [HotelActions.FETCH_LOG_WEBSITE]: (state: HotelState) => ({
            ...state,
            logWebsiteFetchStatus: FetchStatus.ACTIVE
        }),
        [HotelActions.FETCH_LOG_WEBSITE_SUCCESS]: (state: HotelState) => ({
            ...state,
            logWebsiteFetchStatus: FetchStatus.SUCCESS
        }),
        [HotelActions.FETCH_LOG_WEBSITE_ERROR]: (state: HotelState) => ({
            ...state,
            logWebsiteFetchStatus: FetchStatus.ERROR
        }),
        [HotelActions.RESET_LOG_WEBSITE_FETCH_STATUS]: (state: HotelState) => ({
            ...state,
            logWebsiteFetchStatus: FetchStatus.DEFAULT
        }),
    },
    initialState);