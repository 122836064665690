// @flow
import React from 'react';
import {useLocalize} from 'react-localize';
import logo from '../../../images/src/logo/image_mark.svg';
import facebook from '../../../images/src/social/facebook.svg';
import instagram from '../../../images/src/social/instagram.svg';
import pinterest from '../../../images/src/social/pinterest.svg';
import twitter from '../../../images/src/social/twitter.svg';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {Button, makeStyles} from '@material-ui/core';
import {createAction} from 'redux-actions';
import {ApplicationActions} from '../../../reducer/application';
import {Language} from '../../../types/FlowTypes';
import german from '../../../images/icons/german.svg';
import english from '../../../images/icons/english.svg';
import {useDispatch, useSelector} from 'react-redux';
import type {AppState} from '../../../types/FlowTypes';


const useStyles = makeStyles((theme) => ({
    root: {
        background: '#FBFCFD',
        paddingTop: 100,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 16
        }
    },
    content: {
        display: 'flex',
        maxWidth: 1240,
        width: '95%',
        margin: '0 auto',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            width: '90%'
        }
    },
    contactColumn: {
        width: 360,
        paddingLeft: 30
    },
    contactSurround: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    link: {
        ...theme.link,
        color: theme.palette.primary.light,
        fontSize: 14
    },
    contactDivider: {
        color: theme.palette.primary.light
    },
    headline: {
        color: '#102434',
        fontSize: 22,
        lineHeight: '28px',
        fontWeight: 600,
        marginBottom: 19,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 13,
            marginTop: 16,
            fontSize: 20
        }
    },
    footerLink: {
        marginBottom: 16,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 11,
            fontSize: 16,
            lineHeight: '20px'
        }
    },
    footerLinkFirst: {
        marginTop: 47,
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        }
    },
    divider: {
        maxWidth: 1412,
        width: '95%',
        margin: '72px auto 28px',
        height: 1,
        background: '#E4E4E4',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: '24px auto 14px',
        }
    },
    copyright: {
        textAlign: 'center',
        paddingBottom: 31,
        fontSize: 14,
        color: theme.palette.primary.light
    },
    legalSurround: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0 auto'
    },
    socialSurround: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(3, 0)
    },
    logo: {
        height: 55
    },
    socialIcon: {
        height: 30
    },
    languageSwitchSurround: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
    },
    switch: {
        background: 'transparent',
        border: '1px solid #868D99',
        boxShadow: 'none',
        textTransform: 'none',
        color: '#193150',
        fontSize: 15,
        fontWeight: 600,
        '&:hover': {
            boxShadow: 'none'
        }
    },
    languageIcon: {
        width: 24,
        height: 24,
        marginRight: theme.spacing(1),
        borderRadius: 4
    }
}));

const MobileFooter = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {language} = useSelector((state: AppState) => ({
        language: state.application.language.selected
    }));
    const {localize} = useLocalize();
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.handicappedSurround}>
                    <Typography variant={'h2'} className={classes.headline}>
                        {localize('footer.handicapped.headline')}
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/'} className={classes.link}>
                            {localize('menu.home')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/hotels'} className={classes.link}>
                            {localize('menu.destinations')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/aboutUs'} className={classes.link}>
                            {localize('menu.about.us')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/contact'} className={classes.link}>
                            {localize('menu.contact')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/faq'} className={classes.link}>
                            {localize('menu.faq')}
                        </Link>
                    </Typography>
                </div>
                <div className={classes.escalesSurround}>
                    <Typography variant={'h2'} className={classes.headline}>
                        {localize('footer.escales.headline')}
                    </Typography>
                    <Typography variant={'body1'}
                                className={classNames(classes.footerLink, classes.footerLinkFirst)}>
                        <a href={'http://www.escales-verlag.de'} className={classes.link} target={'_blank'}
                           rel={'noopener noreferrer'}>
                            {localize('footer.escales.headline')}
                        </a>
                    </Typography>
                    <Typography variant={'body1'}
                                className={classNames(classes.footerLink, classes.footerLink)}>
                        <a href={'http://www.irma-messe.de'} className={classes.link} target={'_blank'}
                           rel={'noopener noreferrer'}>
                            {localize('link.irma')}
                        </a>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <a href={'http://www.rollstuhl-kurier.de'} className={classes.link} target={'_blank'}
                           rel={'noopener noreferrer'}>
                            {localize('link.rollstuhl.kurier')}
                        </a>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <a href={'http://www.handicapped-reisen.de'} className={classes.link}
                           target={'_blank'} rel={'noopener noreferrer'}>
                            {localize('link.handicapped.reisen')}
                        </a>
                    </Typography>
                </div>
                <div className={classes.socialSurround}>
                    <img src={logo} className={classes.logo} alt={'Rolli-Hotels.de'}
                         title={'Rolli-Hotels.de'}/>
                    <img src={facebook} className={classes.socialIcon} alt={'Facebook'} title={'Facebook'}/>
                    <img src={twitter} className={classes.socialIcon} alt={'Twitter'} title={'Twitter'}/>
                    <img src={pinterest} className={classes.socialIcon} alt={'Pinterest'}
                         title={'Pinterest'}/>
                    <img src={instagram} className={classes.socialIcon} alt={'Instagram'}
                         title={'Instagram'}/>
                </div>
                <div className={classes.languageSwitchSurround}>
                    <Button variant={'contained'} className={classes.switch} onClick={() => {
                        dispatch(createAction(ApplicationActions.SET_LANGUAGE)(language === Language.ENGLISH ? Language.GERMAN : Language.ENGLISH));
                    }}>
                        <img src={language === Language.ENGLISH ? german : english} alt={''} title={''}
                             className={classes.languageIcon}/>
                        {language === Language.ENGLISH ? localize('language.german') : localize('language.english')}
                    </Button>
                </div>
                <div className={classes.legalSurround}>
                    <Typography variant={'body1'}
                                className={classNames(classes.footerLink, classes.footerLinkFirst)}>
                        <Link to={'/legal/legalnotice'} className={classes.link}>
                            {localize('menu.legal.notice')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/legal/privacy'} className={classes.link}>
                            {localize('menu.data.privacy')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/legal/disclaimer'} className={classes.link}>
                            {localize('menu.disclaimer')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/legal/termsofuse'} className={classes.link}>
                            {localize('menu.terms.of.use')}
                        </Link>
                    </Typography>
                </div>
            </div>
            <div className={classes.divider}/>
            <Typography variant={'body1'} className={classes.copyright}>
                &copy; {new Date().getFullYear()} - Rolli-Hotels.de <br/>All Rights reserved
            </Typography>
        </div>
    );
}

export default MobileFooter;
