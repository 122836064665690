// @flow
import {createTheme} from '@material-ui/core/styles';

let theme: Object = createTheme({});
theme = createTheme({
    ...theme, ...{
        spacing: 8,
        palette: {
            primary: {
                light: '#5c646f',
                main: '#2F89FC',
                dark: '#193150',
                contrastText: '#ffffff',
            },
            secondary: {
                light: '#4A4A4A',
                main: '#FF2C3A',
                dark: '#23262F',
                contrastText: '#ffffff',
            }
        },
        typography: {
            useNextVariants: true,
            fontFamily: [
                'Nexa',
                'sans-serif'
            ],
            caption: {
                fontSize: 16,
                textTransform: 'uppercase',
                fontWeight: 500
            },

            h1: {
                fontSize: 46,
                lineHeight: '52px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 35,
                    lineHeight: '45px',
                }
            },
            h2: {
                fontSize: 34,
                lineHeight: '39px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    letterSpacing: '-0.5px',
                    fontSize: 32,
                    lineHeight: '42px',
                }
            },
            h3: {
                fontSize: 18,
                fontWeight: 500,
                lineHeight: 1,
                letterSpacing: '-0.5px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 20,
                    lineHeight: '28px',
                }
            },
            body1: {
                fontSize: 16,
                lineHeight: '24px',
                color: '#5c646f',
                [theme.breakpoints.down('lg')]: {
                    fontSize: 16,
                    lineHeight: '24px'
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                    lineHeight: '24px'
                }
            },
            body2: {
                fontSize: 20,
                lineHeight: '35px'
            }
        },
        link: {
            color: 'inherit',
            textDecoration: 'none',
            cursor: 'pointer',
            transition: 'all 0.25s cubic-bezier(0.55, 0, 0.55, 1)',
            '&:hover': {
                color: '#2F89FC',
            }
        },
        button: {
            background: '#B51F29',
            color: 'white',
            borderRadius: 0,
            fontSize: 15,
            padding: 15
        },
        fullSizeImage: {
            minHeight: '100%',
            minWidth: '100%',
            height: 'auto',
            width: 'auto',
            position: 'absolute',
            top: '-100%',
            bottom: '-100%',
            left: '-100%',
            right: '-100%',
            margin: 'auto',
            zIndex: 1
        },
        body: {
            width: '90%',
            maxWidth: 1240,
            margin: '0 auto'
        },
        input: {
            fontSize: 15,
            width: '100%',
            resize: 'none',
            outline: 'none',
            WebkitAppearance: 'none',
            padding: '16px 21px',
            appearance: 'none',
            boxSizing: 'border-box',
            borderRadius: 5,
            border: '1px solid #DDDFE7',
            borderStyle: 'solid',
            transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)',
            fontFamily: [
                'Nexa',
                'sans-serif'
            ],
            '&:focus': {
                color: '#2F89FC',
                boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
                border: '1px solid white',
            }
        },
        gradientButton: {
            background: 'linear-gradient(195.53deg, #2F89FC 0%, #30E3CA 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,0.2)',
            borderRadius: 5,
            color: theme.palette.primary.contrastText,
            fontSize: 16,
            textTransform: 'none',
            paddingTop: 16,
            paddingBottom: 16,
            [theme.breakpoints.down('lg')]: {
                paddingTop: 12,
                paddingBottom: 12,
            }
        }
    }
});

export default theme;
