// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Theme, Typography, useMediaQuery} from '@material-ui/core';
import CookieAccordion from './CookieAccordion';
import {useLocalize} from 'react-localize';

const useStyles = makeStyles(() => ({
    root: {
    },
    description: {
        color: '#767E8C',
        fontSize: 14,
        lineHeight: '20px'
    }
}));


const CookieInformation = () => {
    const styles = useStyles();
    const {localize} = useLocalize();

    const isWidthDownXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    if (isWidthDownXS) {
        return (
            <div className={styles.root}>
                <Typography variant={'body1'} className={styles.description}>
                    {localize('cookies.explanation.descriptionMobile')}
                </Typography>
            </div>
        )
    }
    return (
        <div className={styles.root}>
            <Typography variant={'body1'} className={styles.description}>
                {localize('cookies.explanation.description')}
            </Typography>
            <CookieAccordion title={localize('cookies.explanation.device.title')}
                             description={localize('cookies.explanation.device.description')}/>
            <CookieAccordion title={localize('cookies.explanation.content.title')}
                             description={localize('cookies.explanation.content.description')}/>
        </div>
    );
}

export default CookieInformation;