// @flow
import {Language} from '../types/FlowTypes';

const dictionary = {
    [Language.GERMAN]: {
        'app.name': 'Rolli-Hotels.de',
        'menu.home': 'Home',
        'menu.escales': 'Escales GmbH',
        'menu.destinations': 'Reiseziele',
        'menu.blog': 'Reiseblog',
        'menu.about.us': 'Über uns',
        'menu.contact': 'Kontakt',
        'menu.rent': 'Vermieter werden',
        'menu.login': 'Vermieten',
        'menu.logout': 'Logout',
        'menu.legal.notice': 'Impressum',
        'menu.data.privacy': 'Datenschutz',
        'menu.disclaimer': 'Disclaimer',
        'menu.terms.of.use': 'AGB',
        'menu.faq': 'FAQ',
        'menu.hotel': 'Meine Unterkunft',
        'footer.escales.headline': 'Escales GmbH',
        'footer.handicapped.headline': 'Rolli-Hotels.de',
        'footer.legal.headline': 'Daten und Sicherheit',
        'link.irma': 'IRMA',
        'link.rollstuhl.kurier': 'Rollstuhl-Kurier',
        'link.handicapped.reisen.de': 'Rolli-Hotels.de',
        'link.handicapped.reisen': 'Handicapped-Reisen',

        'cookie.notice.hint': 'Wir verwenden Cookies. <a href="/legal/privacy">Datenschutz</a>',
        'cookie.notice.accept': 'Zustimmen',

        'language.german': 'Deutsch',
        'language.english': 'Englisch',


        /***********************************************************************************
         * Site title
         ***********************************************************************************/
        'title': 'Rolli-Hotels',
        'title.home': 'Ihr zuverlässiger Partner für rollstuhlgerechte Unterkünfte!',
        'title.faq': 'FAQ',
        'title.advertise': 'Vorteile',
        'title.register': 'Registrieren',
        'title.login': 'Login',
        'title.destinations': 'Reiseziele',
        'title.termsOfUse': 'AGB',
        'title.disclaimer': 'Disclaimer',
        'title.privacy': 'Datenschutz',
        'title.legalNotice': 'Impressum',
        'title.contact': 'Kontakt',
        'title.aboutUs': 'Über Uns',

        /***********************************************************************************
         * General wordings
         ***********************************************************************************/
        'filter': 'Filter',
        'filter.reset': 'Filter löschen',
        'search.restart': 'Suche erneut starten',
        'filter.apply': 'Unterkünfte anzeigen',
        'search': 'Suchen',


        /***********************************************************************************
         * Home
         ***********************************************************************************/
        'home.search.headline': 'Ihr zuverlässiger Partner für rollstuhlgerechte Unterkünfte!',
        'home.search.subline': 'Mit uns finden Sie Urlaub in rollstuhlgerechten Hotels, barrierefreien Ferienwohnungen, behindertengerechten Pensionen und Bauernhöfen in Deutschland und Europa.',
        'home.search.headline.mobile': 'Ihr Partner für rollstuhl- gerechte Unterkünfte',
        'home.search.subline.mobile': 'Mit uns finden Sie Ihr nächstes barrierefreies Urlaubsziel.',
        'home.search.button': 'Wohin soll es gehen?',
        'home.search.county': 'Wohin soll es gehen?',
        'home.search.county.hint': '(Bitte wählen)',
        'home.search.arrival': 'Anreise',
        'home.search.departure': 'Abreise',
        'home.search.persons': 'Personen',
        'home.search.rooms': 'Zimmer',

        'home.highlights.headline.1': 'Ausgewählte Feriendomizile',
        'home.highlights.headline.beach': 'am Wasser',
        'home.highlights.headline.wellness': 'mit Wellness',
        'home.highlights.headline.nature': 'in der Natur',
        'home.highlights.headline.mountains': 'in den Bergen',
        'home.highlights.headline.city': 'mit Stadt und Kultur',
        'home.highlights.headline.groups': 'für Gruppen',
        'home.highlights.headline.sport': 'mit sportlichen Angeboten',
        'home.highlights.more': 'Mehr ansehen',
        'hotel.price.from': 'ab',
        'hotel.price.night': 'Nacht',
        'hotel.price.per.night': 'pro Nacht',
        'home.hotel.details': 'Details',
        'hotel.number.of.rooms.for.wheelchair.user': 'Anzahl rollstuhlgerechte Zimmer',
        'hotel.number.of.rooms.for.handicapped.user': 'Anzahl gehbehindertengerechte Zimmer',
        'home.categories.headline': 'Wählen Sie Ihre Kategorie',
        'home.categories.text': 'Jede Form des Urlaubs ist mit Behinderung realisierbar, wenn man weiß, wo und wie! Unsere rollstuhlgerechten Hotels, Pensionen und Ferienwohnungen machen es möglich. Barrierefreie Aufenthaltsform wählen und los geht’s.',

        'home.categories.mountains': 'Berge',
        'home.categories.mountains.description': 'Mit geeigneten Hilfsmitteln und rollstuhlgerechten Unterkünften erobern Sie auch die Berge im Urlaub. Wo es barrierefreie Angebote gibt, erfahren Sie hier.',
        'home.categories.sea': 'Strand',
        'home.categories.sea.description': 'Strandurlaub mit dem Rollstuhl oder Ausflüge ans Meer mit einer Behinderung sind kein Problem. Barrierefreie Angebote an Strand und Küste finden Sie bei uns!',
        'home.categories.wellness': 'Wellness',
        'home.categories.wellness.description': 'Barrierefreie Wellness-Angebote, rollstuhlgerechte Spa-Hotels und Thermen, ebenerdige Saunabereiche und Pools mit Liftern finden Sie nur bei uns!',
        'home.categories.nature': 'Natur',
        'home.categories.nature.description': 'Urlaub mit dem Rollstuhl in der Natur in den schönsten barrierefreien Feriengebieten. Hier finden Sie passende behindertengerechte Hotels, Pensionen und Höfe!',
        'home.categories.sport': 'Sport',
        'home.categories.sport.description': 'Sportliche Aktivitäten mit dem Rollstuhl im Urlaub? Entdecken Sie bekannte und neue barrierefreie Sportarten in unseren rollstuhlgerechten Unterkünften.',
        'home.categories.groups': 'Gruppenreisen',
        'home.categories.groups.description': 'Angebote für Gruppen mit Behinderten und Rollstuhlfahrern in rollstuhlgerechten Hotels, barrierefreien Ferienhäusern und Pensionen mit genügend Platz finden!',
        'home.categories.city': 'Stadt & Kultur',
        'home.categories.city.description': 'Barrierefreie Museen, Denkmäler oder historische Stadtteile entdecken? Mit dem Rollstuhl Geschichte erkunden und passende rollstuhlgerechte Hotels finden!',
        'home.divider.text': '"Rollstuhlgerechtes Reisen kann so einfach sein! Barrierefreie Hotels, Pensionen oder Ferienwohnungen zu finden ist der erste Schritt. Entdecken Sie europaweit barrierefreie Unterkünfte in traumhaften Feriengebieten."',
        'home.divider.slide.1': 'Reisen im Rollstuhl ist für Menschen mit Handicap oftmals eine Herausforderung. Traumhafte Landschaften, barrierefreie Wanderwege und andere rollstuhlgerechte Aktivitäten im Urlaub finden sich jedoch auch zahlreich in Deutschland. Barrierefreie Hotels gibt es im wunderschönen Bayern genauso wie rollstuhlgerechte Ferienwohnungen in der sächsischen Schweiz oder behindertengerechte Pensionen an der Nordsee und im Mittelgebirge.',

        'home.blog.title': 'Unsere liebsten Blogbeiträge',
        'home.blog.description': 'Schauen Sie regelmäßig in unserem barriefreien Reiseblog rein. Wir aktualisieren unsere Beiträge regelmäßig für Sie.',



        /***********************************************************************************
         * About Us
         ***********************************************************************************/
        'about.us.headline': '35 Jahre barrierefreies Reisen',
        'about.us.subline': 'Eine kleine Entstehungsgeschichte',
        'about.us.goals.headline': 'Wir und unsere barrierefreien Ziele',
        'about.us.goals.text.1': 'Vor über 35 Jahren nahmen wir aufgrund persönlicher Erfahrungen und eigener Betroffenheit unsere Arbeit für Sie auf und gründeten einen ganz besonderen Familienbetrieb. Mit unseren Printmedien, Internetseiten und unserer Reha-Messe IRMA sind stets die Belange von Menschen mit Behinderung und Rollstuhlfahrern im Fokus. Unser Ziel damals wie heute: Menschen mit Behinderung sollen die ',
        'about.us.goals.text.1.mobile': 'Vor über 35 Jahren nahmen wir aufgrund persönlicher Erfahrungen und eigener Betroffenheit unsere Arbeit für Sie auf und gründeten einen ganz besonderen Familienbetrieb. Mit unseren Printmedien, Internetseiten und unserer Reha-Messe IRMA sind stets die Belange von Menschen mit Behinderung und Rollstuhlfahrern im Fokus.',
        'about.us.goals.text.2': 'Welt bereisen und am Leben teilhaben können wie jeder andere auch! Rolli-Hotels.de ist ein Portal, in dem sich Menschen mit Behinderung, Rollstuhlfahrer und Pflegebedürftige eigenständig und zuverlässig über Unterkünfte informieren können. Barrierefreiheit und Integration haben bei uns Tradition!',
        'about.us.goals.text.2.mobile': 'Unser Ziel damals wie heute: Menschen mit Behinderung sollen die Welt bereisen und am Leben teilhaben können wie jeder andere auch! Rolli-Hotels.de ist ein Portal, in dem sich Menschen mit Behinderung, Rollstuhlfahrer und Pflegebedürftig eigenständig und zuverlässig über Unterkünfte informieren können. Barrierefreiheit und Integration haben bei uns Tradition!',
        'about.us.section.1.title': '35 Jahre Reisen für Menschen mit Behinderung und Rollstuhlfahrer',
        'about.us.section.1.text.1': 'Barrierefreies Reisen hat bei uns seit über 35 Jahren Tradition. Wir arbeiten bis heute ausschließlich für und mit Menschen mit Behinderung.',
        'about.us.section.1.text.2': 'Als erster Anbieter in Deutschland sammelten wir flächendeckend umfassende Informationen zu rollstuhlgerechten Unterkünften und stellen diese zunächst in gedruckter Form, im Reiseratgeber „Handicapped-Reisen“, zur Verfügung. Vor den Zeiten des Internets eröffneten wir vielen Menschen mit Behinderung so erstmals die Möglichkeit die eigenen vier Wände zu verlassen und Urlaub zu machen.',
        'about.us.section.2.title': 'Oft problematisch: Informationen zur Barrierefreiheit im Internet',
        'about.us.section.2.text': 'Doch auch im Zeitalter des Internets sind zuverlässige Informationen für Menschen mit Handicap und Rollstuhlfahrer leider Mangelware. Informationen, gerade in großen Reiseportalen, sind oft zu ungenau, fehlerhaft und ungeprüft. Kaum ein Betroffener hat hier nicht schon schlechte Erfahrungen machen müssen, die bis zum Abbruch des geplanten Urlaubs führen können. Auch wir erleben solche Umstände, im Rahmen unserer Reisereportagen für unsere Zeitschrift Rollstuhl-Kurier leider immer noch häufig.',
        'about.us.section.3.title': 'Sorgfältig und zuverlässig: <br />Unser Standard',
        'about.us.section.3.text': 'Die hier gelisteten Unterkünfte wurden von uns mit höchster Sorgfalt recherchiert, ausführlich befragt und zum Teil persönlich getestet. Rolli-Hotels.de hat sich, dem Namen entsprechend, ausschließlich an den Belangen von Rollstuhlfahrern orientiert. Kaum an anderen Stellen finden Sie einen solchen Standard vor. Wer mit uns plant reist sicher. Die hier aufgeführten Betriebe zeichnen sich nicht nur durch barrierefreie Unterkunftsbedingungen aus, sondern auch dadurch, dass sie Menschen mit Behinderung als besonders willkommene Gäste empfangen (mehr zu den rollstuhlgerechten Unterkünften hier).',
        'about.us.guide.book.headline': 'Unser Ratgeber in A5',
        'about.us.guide.book.text': 'Falls Print Ihnen lieber ist, bestellen Sie Ihr aktuelles Exemplar von „Handicapped-Reisen“ jetzt einfach und bequem online. Mit unserem handlichen Taschenbuch sind sie immer gut beraten. Bestellen Sie jetzt Ihr persönliches Exemplar online!',
        'about.us.guide.book.button': 'Jetzt bestellen',
        'about.us.guide.book.button.mobile': 'Jetzt Ihren persönlichen Ratgeber bestellen',


        'hotels.categories.0': 'Restaurant',
        'hotels.categories.1': 'Spa',
        'hotels.categories.2': 'Garten',
        'hotels.detail.price.per.night': '/ Nacht',
        'hotels.detail.price.per.night.mobile': 'pro Nacht',
        'hotels.detail.price.from': 'ab',
        'hotels.detail.contact.headline': 'Urlaub anfragen',
        'hotels.detail.contact.name': 'Ihr Name',
        'hotels.detail.contact.email': 'Ihre E-Mail',
        'hotels.detail.contact.phone': 'Ihre Telefonnummer',
        'hotels.detail.contact.number.of.guests': 'Anzahl Personen',
        'hotels.detail.contact.number.of.rooms': 'Anzahl Zimmer',
        'hotels.detail.contact.message': 'Ihre Nachricht...',
        'hotels.detail.contact.send': 'Anfrage abschicken',
        'hotels.detail.contact.send.active': 'Anfrage wird gesendet',
        'hotels.detail.description.headline': 'Beschreibung',
        'hotels.detail.accessibility.headline': 'Barrierefreiheit',
        'hotels.detail.facilities.headline': 'Ausstattung',
        'hotels.detail.offers.headline': 'Angebote vor Ort',
        'hotels.detail.position.headline': 'Lage',
        'hotels.detail.contact.request': 'Urlaub anfragen',
        'hotels.detail.contact.request.button': 'Nachricht senden',
        'hotels.detail.contact.send.error': 'Ihre Anfrage konnte nicht gesendet werden.',
        'hotels.detail.contact.send.success': 'Ihre Nachricht wurde erfolgreich gesendet.',
        'hotels.detail.collage.show.light.box': 'Fotos ansehen',
        'hotels.detail.contact.arrival.departure': 'Anreise/Abreise',
        'hotels.detail.contact.link': 'Anfragen',
        'hotels.detail.phone': 'Anzeigen',
        'hotels.detail.phone.show': 'Anrufen',
        'hotels.detail.website': 'Webseite',


        /***********************************************************************************
         * Contact
         ***********************************************************************************/
        'contact.headline': 'Kontaktieren Sie uns',
        'contact.caption': 'Melden Sie sich gerne bei jedem Anliegen.',
        'contact.name': 'Ihr Name',
        'contact.email': 'Ihre E-Mail',
        'contact.phone.input': 'Ihre Telefonnummer',
        'contact.message': 'Ihre Nachricht...',
        'contact.privacy.accept.1': 'Ich stimme der ',
        'contact.privacy.accept.2': ' zu',
        'contact.privacy': 'Datenschutzerklärung',
        'contact.send': 'Nachricht senden',
        'contact.send.active': 'Nachricht wird gesendet',
        'contact.address.headline': 'Adresse',
        'contact.address': 'Auf dem Rapsfeld 31, 22359 Hamburg',
        'contact.mail.headline': 'Mail',
        'contact.mail': 'info@rolli-hotels.de',
        'contact.phone.headline': 'Telefon',
        'contact.phone': '+49 (0)40 26 100 360',
        'contact.send.success': 'Ihre Nachricht wurde gesendet.',
        'contact.send.error': 'Ihre Nachricht konnte nicht gesendet werden.',
        'contact.send.error.fields': 'Bitte füllen Sie alle Felder aus und stimmen der Datenschutzerklärung zu.',


        /***********************************************************************************
         * Destinations
         ***********************************************************************************/
        'destinations.search.results.empty': 'Leider war bei Ihrer Suche nicht das passende dabei! Versuchen Sie es mit weniger Filtern.',
        'destinations.divider.1.title': 'Sie brauchen Infos zu Hilfsmitteln im Urlaub? Vergleichen und testen Sie auf der internationalen Reha- und Mobilitätsmesse IRMA.',
        'destinations.divider.1.button': 'Jetzt vergleichen',
        'destinations.divider.2.title': 'Ausführliche Reise- und Erfahrungsberichte zu barrierefreien Urlaubsgebieten und Unterkünften gibt es nur im Magazin Rollstuhl-Kurier.',
        'destinations.divider.2.button': 'Hier mehr erfahren',
        'destinations.divider.3.title': 'Rollstuhlgerechte Unterkünfte und wertvolle Tipps zum Thema Reisen mit Handicap gibt es seit 35 Jahren auch als Buch „Handicapped-Reisen“.',
        'destinations.divider.3.button': 'Zeig mir das Buch',
        'destinations.search.card.view': 'Karte zeigen',
        'destinations.loading.title': 'Ihr Unterkünfte werden geladen',
        'destinations.search.headline': 'Finden Sie barrierefreie Unterkünfte mit allen wichtigen Details',
        'destinations.search.subline': 'Sie erwarten rollstuhlgerechte Hotels, barrierefreie Ferienwohnungen oder behindertengerechte Pensionen und Bauernhöfe europaweit',
        'destinations.search.headline.mobile': 'Barrierefreie Unterkünfte finden',
        'destinations.search.subline.mobile': 'Rollstuhlgerecht europaweit.',
        'destinations.search.category.apply': 'Anwenden',

        'destinations.main.title': 'Wir sind Rollihotels – Ihr Anbieter für barrierefreies Reisen',
        'destinations.main.description': 'Seit über 35 Jahren arbeiten wir als erster Anbieter auf dem Markt für die Vermittlung behindertengerechter Unterkünfte daran, Menschen mit Behinderung einen rollstuhlgerechten Urlaub ohne böse Überraschungen zu ermöglichen. Auf Rollihotels finden Sie wirklich rollstuhlgerechte Hotels, barrierefreie Ferienwohnungen, behindertengerechte Pensionen, barrierearme Bauernhöfe und vieles mehr. Den Standard bei der Auswahl unserer barrierefreien Unterkünfte halten wir seit jeher hoch. Umfangreiche Befragungen, Registrierungsprozesse und Besichtigungen stellen dies sicher. Jeder Betrieb auf Rollihotels bietet Übernachtungsmöglichkeiten für Rollstuhlfahrer an. Detailreiche Angaben auf den behindertengerechten Urlaubsprofilen zu Abständen, Türbreiten, Bewegungsspielräumen, Hilfsmitteln, Pflege und Aktivitäten mit Behinderung helfen Ihnen bei der Planung Ihrer barrierefreien Reise. Viele der barrierefreien Unterkünfte haben sich sogar ausschließlich auf Übernachtungsmöglichkeiten für Menschen mit Handicap spezialisiert. Dementsprechend werden behinderte Gäste hier im Urlaub als besonders willkommene Gäste empfangen. Einige dieser rollstuhlgerechten Anbieter haben wir bereits in ausführlichen Reportagen in unserem Reisemagazin Rollstuhl-Kurier vorgestellt (www.rollstuhl-kurier.de). Reisen mit Behinderung oder Mobilitätseinschränkung hat bei uns Tradition. ',

        'destinations.read.more': 'Mehr über Rolli Hotels erfahren',
        'destinations.read.less': 'Weniger lesen',

        'destinations.extended.1.title': 'Rollstuhlgerechter Urlaub – Aktivitäten in Deutschland und Europa planen',
        'destinations.extended.1.description': 'Wer selbstständig seinen barrierefreien Urlaub planen will, ist bei uns genau richtig, denn das Wichtigste ist, zunächst eine passende rollstuhlgerechte Unterkunft zu finden. Rollihotels bietet barrierefreie Hotels, rollstuhlgerechte Pensionen, Ferienwohnungen und Bauernhöfe in ganz Deutschland an. Bei der Planung von barrierefreien Aktivitäten im Urlaub können Sie hier zum Teil auf jahrzehntelange Erfahrung bei der Vermietung an behinderte Urlauber zurückgreifen. Welches Hilfsmittel ist im Urlaub mit Mobilitätseinschränkung das richtige? Was gibt es für rollstuhlgerechte Ausflugsziele, barrierefreie Sehenswürdigkeiten und Restaurants in der Umgebung? Zu all diesen Themen beraten unsere barrierefreien Anbieter Sie gern. Reisen Sie aktiv mit dem Handbike durch den flachen Norden, erobern Sie mit dem Elektrorollstuhl die Berge in Bayern und Österreich oder genießen Sie einfach Ihren Aufenthalt in einem rollstuhlgerechten Wellnesshotel. Möglichkeiten gibt es viele. Auch für Elektrorollstuhlfahrer oder Familien mit Pflegebedürftigen finden sich auf Rollihotels passende barrierefreie Urlaubsangebote. Aktivitäten für Menschen mit Behinderung im Urlaub gibt es nahezu überall, die Erfahrung zeigt Ihnen, wo!',

        'destinations.extended.2.title': 'Hilfsmittel, Pflege und Sanitärräume im Urlaub',
        'destinations.extended.2.description': 'Alle barrierefreien Unterkünfte auf Rollihotels sind ebenerdig mit Hilfsmitteln befahrbar. Zimmer, Speiseräume sowie Badezimmer sind stufenlos zugänglich. Je nach barrierefreiem Urlaubsort und Anbieter können sogar große Gruppen mit behinderten Menschen eine passende barrierefreie Unterkunft finden. Reisen von Selbsthilfegruppen oder Behinderten-Sportvereinen sind ebensowenig ein Problem wie Urlaub mit Pflegebedürftigen oder Angebote für breite Elektrorollstühle. Alle rollstuhlgerechten Unterkünfte verfügen über Zimmer mit rollstuhlgerechten Badezimmern. Großzügige Bewegungsspielräume sind hier die Regel. Toiletten sind i.d.R. von mindestens einer Seite anfahrbar und mit mindestens einem festen Haltegriff versehen. Duschen sind schwellenlos befahrbar, mit Haltegriffen, z.T. auch mit speziellen Duschstühlen oder klappbaren Sitzen versehen. Waschbecken sind unterfahrbar, z.T. gibt es absenkbare Spiegel und vieles mehr. Pflegeangebote im Urlaub gibt es genauso wie andere therapeutische Maßnahmen vor Ort für behinderte Gäste. Die Zusammenarbeit mit Sanitätshäusern ermöglicht es meist, passende Hilfsmittel für Ihren Aufenthalt im Urlaub zu liefern. Pflegebetten oder elektrisch höhenverstellbare Betten sind oftmals bereits Standard, genauso wie der inklusive Gedanke.',

        /*******************************************************
         * Login
         ********************************************************/
        'login.headline': 'Endlich barrierefrei Reisen für alle.',
        'login.description': 'Sollten Sie bereits einen Zugangscode erhalten haben, nutzen Sie diesen um sich hier einzuloggen.',
        'login.email.placeholder': 'E-Mail Adresse',
        'login.password.placeholder': 'Passwort',
        'login.submit': 'Login',
        'login.success': 'Sie wurden erfolgreich eingeloggt.',
        'login.error': 'Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort.',
        'register.request': 'Sie sind noch kein Vermieter? Mehr erfahren Sie hier.',
        'hotels.add.name': 'Unterkunfts-Name',
        'hotels.add.gender': 'Anrede',
        'hotels.add.gender.-1': 'Anrede',
        'hotels.add.gender.0': 'Herr',
        'hotels.add.gender.1': 'Frau',
        'hotels.add.first.name': 'Vorname',
        'hotels.add.last.name': 'Nachname',
        'hotels.add.email': 'E-Mail',
        'hotels.add.phone': 'Telefon',
        'hotels.actions.add.title': 'Registrieren',
        'hotels.actions.add.cancel': 'Abbrechen',
        'hotels.actions.add.submit': 'Registrieren',
        'hotels.actions.add.success': 'Sie wurden erfolgreich registriert. Sie erhalten in Kürze eine Nachricht von uns.',
        'hotels.actions.add.error': 'Sie konnte nicht registriert werden. Versuchen Sie es später erneut.',

        'register.headline': 'Inserieren Sie Ihre barrierefreie Unterkunft!',
        'register.help.download': 'Ausfüllhilfe herunterladen',
        'register.step.1': 'Allgemeine Angaben',
        'register.step.2': 'Qualifizieren Sie sich als Rolli-Hotel',
        'register.step.3': 'Beschreiben Sie Ihre Unterkunft',
        'register.step.4': 'Fügen Sie Ihre Barrierefreiheit hinzu',
        'register.step.5': 'Fügen Sie Fotos Ihrer Unterkunft hinzu',
        'register.step.6': 'Bestätigen Sie Ihre Angaben',
        'register.hotel.name': 'Name Ihrer Unterkunft',
        'register.hotel.subtitle': 'Beschreiben Sie Ihre Unterkunft in einem Satz',
        'register.hotel.street': 'Straße',
        'register.hotel.street.number': 'Nr.',
        'register.hotel.zip.code': 'PLZ',
        'register.hotel.city': 'Ort',
        'register.hotel.country': 'Land',
        'register.hotel.region': 'Reiseregion',
        'register.hotel.county': 'Bundesland',
        'register.add.language': 'Übersetzung hinzufügen',
        'register.remove.language': 'Übersetzung entfernen',
        'register.step.1.phone.contact': 'Ich möchte telefonische Anfragen erhalten können (Nur für Kunden des Fixkosten-Modells).',
        'register.step.1.website.request': 'Ich möchte Anfragen an meine eigene Website weiterleiten können (Nur für Kunden des Fixkosten-Modells).',

        'register.step.1.request.hint.title': 'Hinweis Provisions-Modell',
        'register.step.1.request.hint.content': 'Nachrichten- und Telefonfunktionen werden nach Eintragerstellung auf Rollihotels umgestellt. Weiterleitungen zur Webseite werden deaktiviert.',

        'register.hotel.contact.phone': 'Telefonnummer',
        'register.hotel.website': 'www.unterkunft.de/buchungslink',
        'register.optional': 'optional',
        'register.optional.prices': 'nur ausfüllen, wenn verfügbar',
        'register.step.next': 'Weiter',
        'register.step.back': 'Zurück',
        'register.step.finish': 'Freigabe beantragen',
        'register.step.finish.hint': 'Wenn Sie die Prüfung beantragen, können Sie vorerst keine Änderungen vornehmen. Stellen Sie sicher, dass Sie alles eingetragen und geprüft haben. Änderungen sind erst nach der Freigabe wieder möglich.lo',
        'register.step.update': 'Speichern & live stellen',
        'register.step.2.headline': 'Haben Sie eine barrierefreie Unterkunft?',
        'register.step.2.subline': 'Folgende Angaben müssen mindestens auf eines Ihrer Zimmer zutreffen: ',
        'register.step.2.step.1.headline': 'Die Unterkunft',
        'register.step.2.step.1.bullet.1': 'Die Unterkunft ist vom Parkplatz aus stufenlos erreichbar.',
        'register.step.2.step.1.bullet.2': 'Der Weg vom Eingang bis zum Zimmer ist ebenfalls stufenlos erreichbar.',
        'register.step.2.step.1.bullet.3': 'Die Türen in der gesamten Unterkunft sind mind. 70 cm breit.',
        'register.step.2.step.1.bullet.4': 'Die Essbereiche oder das Restaurant sind stufenlos erreichbar.',

        'register.step.2.step.2.headline': 'Die Wege und Zimmer',
        'register.step.2.step.2.bullet.1': 'Alle Flure und Durchgangstüren vom Eingang zum Zimmer sind mindestens 70 cm breit.',
        'register.step.2.step.2.bullet.2': 'Im Zimmer ist eine Freifläche zum Wenden vorhanden (min. 80 x 100 cm)',
        'register.step.2.step.2.bullet.3': 'Das Bett kann von mindestens einer Seite angefahren werden (Abstand zur Wand min. 70 cm)',

        'register.step.2.step.3.headline': 'Das Badezimmer',
        'register.step.2.step.3.bullet.1': 'Der Zugang zum Badezimmer beträgt mindestens 70 cm, Rollstuhlfahrer können ohne Probleme wenden (Freifläche im Bad min. 120 x 100 cm)',
        'register.step.2.step.3.bullet.2': 'Das WC ist von mindestens einer Seite anfahrbar (Freiraum links oder rechts min. 70 cm) und mit einem Haltegriff versehen.',
        'register.step.2.step.3.bullet.3': 'Der Duschbereich ist ebenerdig mit dem Rollstuhl befahrbar und beträgt mindestens 100 x 80 cm.',
        'register.step.2.step.3.bullet.4': 'In der Dusche befindet sich mindestens ein Haltegriff, Duschhocker oder Klappsitze sind empfehlenswert.',
        'register.step.2.success': 'Hurra! Ihre Unterkunft ist barrierefrei',

        'register.step.3.category.headline': 'Kategorien',
        'register.step.3.category.subline': 'Wählen Sie bis zu 3 Kategorien aus: ',
        'register.step.3.category.selected': 'Kategorie ausgewählt',
        'register.step.3.category.selected.plural': 'Kategorien ausgewählt',
        'register.step.3.description.headline': 'Beschreibung',
        'register.step.3.description.placeholder': 'Hier können Sie Ihre Unterkunft beschreiben ...',
        'register.step.3.distances.headline': 'Entfernungen',
        'register.step.3.prices.headline': 'Preise',
        'register.step.4.rooms.headline': 'Wieviele Zimmer haben Sie für…',
        'register.step.4.rooms.headline.wheel.chair': 'Rollstuhlfahrer',
        'register.step.4.rooms.wheel.chair.content': 'Wie viele rollstuhlgerechte Unterkünfte mit rohlstuhlgerechter DU/WC haben Sie?',
        'register.step.4.rooms.number': 'Anzahl',
        'register.step.4.rooms.headline.handicapped': 'Gehbehinderte',
        'register.step.4.rooms.handicapped.content': 'Wie viele Unterkünfte sind behindertengerecht erreichbar und ohne rollstuhlgerechtes Bad?',
        'register.step.5.drop.headline': 'Drag and Drop',
        'register.step.5.drop.content': 'Alternativ durchsuchen Sie Ihre Dateien',
        'register.step.5.drop.click': 'hier.',
        'register.step.5.drop.accept': 'Wir akzeptieren Bilder im .jpg oder .png Format mit bis zu 5 MB pro Bild.',
        'register.step.5.drop.content.active': 'Ihre Bilder werden hochgeladen.',
        'register.step.5.select.title.image': 'Wählen Sie Ihr Titelbild aus:',
        'register.step.5.title.image': 'Titelbild',
        'register.step.5.highlights': 'Highlights',
        'register.step.5.additional.images': 'Weitere Bilder',
        'register.step.5.drag.drop.hint': 'Sie können die Reihenfolge der Bilder per Drag & Drop verändern.',
        'register.step.5.images.hint': 'Denken Sie daran ein Bild vom <span>Badezimmer</span> hochzuladen. Über 80% der Handicapped-Reisenden entscheiden nachdem Sie das Badezimmer gesehen haben.',
        'register.step.5.images.hint.accept': 'Verstanden',
        'register.step.5.images.error.hint': 'Die folgenden Bilder konnten nicht hochgeladen werden:',
        'register.step.5.images.error.accept': 'Schließen',
        'register.complete.active.headline': 'Wir bereiten Ihre Unterkunft vor',
        'register.approval.success.content': 'Vielen Dank für Ihren Eintrag! Nach erfolgreicher Prüfung melden wir uns bei Ihnen.',
        'register.complete.success.headline': 'Hooray 🥳🥳🥳!',
        'register.complete.success.content': 'Ihre Unterkunft ist jetzt online!',
        'register.complete.success.button': 'Zur Unterkunft',
        'register.complete.error.button': 'Schließen & zurück zur Startseite',

        'advertise.prices.headline': 'Barrierefreie Unterkünfte erfolgreich vermieten',
        'advertise.prices.sub.line': 'Seit über 40 Jahren setzen Anbieter von barrierefreien Urlaubs- und Übernachtungsmöglichkeiten auf unsere Expertise.',
        'advertise.switch.timing.advertisement': 'Jetzt unverbindlich & kostenlos testen!',
        'advertise.switch.timing.yearly': 'Jährliche Abrechnung',
        'advertise.switch.timing.monthly': 'Monatliche Abrechnung',
        'advertise.switch.headline': 'Bitte wählen Sie Ihr Preismodell:',
        'advertise.switch.fix': 'Fixkosten-Modell',
        'advertise.switch.provision': 'Provisions-Modell',
        'advertise.switch.fix.mobile': 'Fixkosten',
        'advertise.switch.provision.mobile': 'Provision',
        'advertise.prices.explanations.headline': 'Ihre Vorteile auf einen Blick',
        'advertise.prices.explanations.bullet.1': 'Nutzen Sie unsere Expertise',
        'advertise.prices.explanations.bullet.2': 'Verwalten Sie Ihre Unterkunft selbst',
        'advertise.prices.explanations.bullet.3': 'Ungenutze Kapazitäten nutzen',
        'advertise.prices.explanations.bullet.4': 'Top-Inserat: Ihre Anzeige erscheint auf der Startseite für einen Monat',
        'advertise.prices.explanations.bullet.5': 'Sie erhalten 20% Rabatt bei jährlicher Abrechnung',
        'advertise.prices.explanations.bullet.6': 'Monatlich kündbar. <br />Keine Mindestlaufzeit',
        'advertise.prices.explanations.bullet.advertisement.1': 'Unterkunft selbst verwalten und jeder Zeit Änderungen vornehmen.',
        'advertise.prices.explanations.bullet.advertisement.2': 'Kostenfreies Basis-Abo Rollstuhl-Kurier zur Auslage in Ihren Räumlichkeiten.',
        'advertise.prices.explanations.bullet.advertisement.3': '<span>10% Rabatt</span> auf alle Inserate in Handicapped-Reisen oder ein kostenfreier Basiseintrag.',
        'advertise.prices.explanations.bullet.advertisement.4': 'Erschließung einer neuen Zielgruppe durch detaillierte Darstellung Ihrer barrierefreien Ausstattungsmerkmale.',
        'advertise.prices.explanations.bullet.advertisement.5': '<span>Kein Erfolg, keine Kosten!</span> Bei Vermittlung zahlen Sie eine Erfolgs-Provision.',
        'advertise.prices.explanations.bullet.advertisement.6': 'Jetzt <span>6 Monate kostenfrei testen</span>, danach nur 10 Euro pro Monat.',
        'advertise.prices.explanations.bullet.advertisement.7': 'Erhalten Sie unbegrenzte E-Mail- sowie Telefonanfragen und verlinken Sie Ihre eigene Homepage.',
        'advertise.prices.explanations.bullet.advertisement.8': 'Laufzeit 12 Monate, jährliche Abrechnung, keine versteckten Kosten.',
        'advertise.prices.explanations.bullet.advertisement.9': 'Wir übernehmen den Kundenkontakt, klären wichtige Details und übermitteln die Buchungsdaten. Verlassen Sie sich bei der Vermittlung auf unsere Expertise.',
        'advertise.prices.explanations.bullet.advertisement.10': 'Detaillierte Darstellbarkeit Ihrer barrierefreien Ausstattungsmerkmale.',
        'advertise.prices.explanations.bullets.expand': 'Weitere Vorteile anzeigen',
        'advertise.prices.explanations.bullets.shrink': 'Weniger Vorteile anzeigen',
        'advertise.register.fix.headline.hotel': 'Daten zur Unterkunft',
        'advertise.register.provision.headline.frame.contract': 'Rahmenvertrag bereits abgeschlossen',
        'advertise.register.provision.headline.hotel': 'Noch keinen Rahmenvertrag:',
        'advertise.register.fix.hotel.label': 'Unterkunftsname',
        'advertise.register.fix.hotel.placeholder': 'Rollstuhl GmbH',
        'advertise.register.fix.street.label': 'Straße',
        'advertise.register.fix.street.placeholder': 'Rollstuhlgerechte Straße',
        'advertise.register.fix.houseNumber.label': 'Hausnummer',
        'advertise.register.fix.houseNumber.placeholder': '123',
        'advertise.register.fix.zip.label': 'Postleitzahl',
        'advertise.register.fix.zip.placeholder': '12345',
        'advertise.register.fix.city.label': 'Stadt',
        'advertise.register.fix.city.placeholder': 'Barrierefreie Stadt',
        'advertise.register.fix.country.label': 'Land',
        'advertise.register.fix.country.placeholder': 'Rollstuhlgerechtes Land',
        'advertise.register.fix.headline.contact': 'Daten zum Ansprechpartner',
        'advertise.register.fix.contact.gender.label': 'Anrede',
        'advertise.register.fix.contact.gender.placeholder': 'Anrede',
        'advertise.register.fix.contact.gender.male': 'Herr',
        'advertise.register.fix.contact.gender.female': 'Frau',
        'advertise.register.fix.contact.gender.none': 'Keine Angabe',
        'advertise.register.fix.contact.firstName.label': 'Vorname',
        'advertise.register.fix.contact.firstName.placeholder': 'Max',
        'advertise.register.fix.contact.name.label': 'Nachname',
        'advertise.register.fix.contact.name.placeholder': 'Mustermann',
        'advertise.register.fix.contact.email.label': 'E-Mail Adresse (geschäftlich)',
        'advertise.register.fix.contact.email.placeholder': 'mustermann@unterkunft.de',
        'advertise.register.fix.contact.phone.label': 'Telefon (geschäftlich)',
        'advertise.register.fix.contact.phone.placeholder': '+49 160 123456789',
        'advertise.register.frame.contract.input.label': 'Rahmenvertragsnummer',
        'advertise.register.frame.contract.input.placeholder': 'XXXXX-XXX',
        'advertise.register.frame.contract.error': 'Die eingegebene Rahmenvertragsnummer ist nicht korrekt.',
        'advertise.register.frame.contract.submit': 'Unterkunft registrieren',
        'advertise.register.frame.contract.description': 'Sie haben noch keine Rahmenvertragsnummer? Nutzen Sie die unteren Felder, um unseren Vertrieb zu kontaktieren. Alternativ können Sie sich auch unter <a href="tel:+49 40 26 100 360">+49 40 26 100 360</a> oder <a href="mailto:info@rolli-hotels.de">info@rolli-hotels.de</a> bei uns melden.',
        'advertise.register.frame.contract.tooltip.headline': 'Wie erhalte ich eine Rahmenvertragsnummer?',
        'advertise.register.frame.contract.tooltip.content': 'Kontaktieren Sie uns einfach per Telefon unter <a href="tel:+494026100360">+49 40 26 100 360</a>, E-Mail an <a href="mailto:info@rolli-hotels.de">info@rolli-hotels.de</a> oder unten stehendes Kontaktformular Wir setzen uns dann umgehend mit Ihnen in Verbindung. Gemeinsam legen wir die Bedingungen in einem Rahmenvertrag fest. Nach Vertragsabschluss erhalten Sie Ihre Rahmenvertragsnummer, mit der Sie sich registrieren und Ihre Unterkunft anlegen können. Mit einer Vertragsnummer können Sie auch mehrere Objekte registrieren. ',
        'advertise.register.accept': 'Ich stimme den <a href="/legal/termsOfUse">AGBs</a> und der <a href="/legal/privacy">Datenschutzerklärung</a> zu.',
        'advertise.contact.sales': 'Ich habe noch keinen Rahmenvertrag abgeschlossen und möchte diesen hiermit unverbindlich anfragen.',
        'advertise.register.send': 'Unterkunft registrieren',
        'advertise.register.sent': 'Gesendet',
        'advertise.contact.sales.button': 'Vertrieb kontaktieren',
        'advertise.register.success.frame.contract': 'Ihre Rahmenvertragsnummer wurde an uns übermittelt. Wir melden uns in Kürze bei Ihnen.',
        'advertise.register.error.frame.contract': 'Ihre Rahmenvertragsnummer konnte nicht übermittelt werden. Bitte versuchen Sie es später erneut.',
        'month': 'Monat',
        'quarter': '3 Monate',
        'advertise.prices.explanation.discount.per.year': 'Sie sparen 60€ im Jahr',
        'advertise.prices.explanation.price.per.advertisement': 'Nach dem Probeabo kann ab 20€ pro Monat das Inserat dauerhaft freigeschaltet werden.',
        'advertise.prices.explanation.price.per.advertisement.2020': 'Jetzt kostenlos inserieren und Buchungsanfragen erhalten.',
        'advertise.prices.explanation.price.per.year': '240€ pro Unterkunft bei jährlicher Abrechnung',
        'advertise.prices.explanation.price.per.month': '300€ pro Unterkunft bei monatlicher Abrechnung',
        'advertise.prices.trial': 'Kostenlos testen',
        'advertise.prices.trial.advertisement': 'Jetzt online inserieren*',
        'advertise.prices.test': 'Jetzt 3 Monate unverbindlich & kostenlos testen!',
        'advertise.prices.test.advertisement': '*Ihr Probeabo läuft automatisch ab. Es entstehen keine Kosten',
        'advertise.prices.test.advertisement.2020': '*Laufzeit 12 Monate. Bis 5 Anfragen kostenlos. Kein Erfolg, keine Kosten. Anfragen erhalten Sie per Telefon oder Mail über das Portal.',
        'advertise.prices.test.mobile': 'Jetzt unverbindlich & kostenlos testen!',
        'advertise.prices.trial.months': 'Nach 3 Monaten Testzeit können Sie entscheiden, ob Sie kostenpflichtig inserieren möchten.',
        'advertise.request.advertisment': 'Fragen Sie Ihr unverbindliches Angebot Probeabo an:',
        'advertise.request.monthly': 'Fragen Sie jetzt Ihr unverbindliches Angebot für eine monatliche Abrechnung an:',
        'advertise.request.yearly': 'Fragen Sie jetzt Ihr unverbindliches Angebot für eine jährliche Abrechnung an:',
        'advertise.request.message': 'Ihre Nachricht',
        'advertise.accept.privacy': '<style>a {text-decoration: none; color:#2F89FC; transition: all 0.3s cubic-bezier(0.55, 0, 0.6, 1)}  a:hover {color:#102434;}</style>Ich stimme der <a href="/legal/privacy" rel="noreferrer" target="_blank">Datenschutzerklärung</a> zu.',
        'advertise.accept.terms.of.use': '<style>a {text-decoration: none; color:#2F89FC; transition: all 0.3s cubic-bezier(0.55, 0, 0.6, 1)}  a:hover {color:#102434;}</style>Ich akzeptiere die <a href="/legal/termsOfUse" rel="noreferrer" target="_blank">allgemeinen Geschäftsbedigungen.</a>',
        'advertise.request.send': 'Anfragen',
        'advertise.request.send.active': 'Anfrage wird gesendet',
        'advertise.request.fill.fields': 'Bitte füllen Sie alle Felder aus und geben eine korrekte E-Mail-Adresse an.',
        'advertise.request.send.success': 'Ihre Anfrage wurde erfolgreich an uns gesendet. Sie erhalten in Kürze eine E-Mail von uns.',
        'advertise.request.sales.send.success': 'Ihre Anfrage wurde erfolgreich an uns gesendet.  Unser Vertrieb wird sich in Kürze bei Ihnen melden.',
        'advertise.request.send.error': 'Ihre Anfrage konnte nicht an uns gesendet werden. Bitte versuchen Sie es später erneut.',
        'advertise.headline': 'Mit uns erfolgreich und barrierefrei Vermieten!',
        'advertise.content.1': 'Seit über 35 Jahren setzten Betriebe mit rollstuhlgerechten Zimmern auf unsere Expertise. In uns haben Sie einen starken Werbepartner, der Ihnen effektiv zu einer optimalen Auslastung verhilft. ',
        'advertise.content.2': 'Wir stehen ihnen bei Fragen zur Vermietung stets mit Rat und Tat zur Seite. Unser Erfolg, der unserer Kunden und zufriedene Urlauber geben uns Recht. Überzeugen Sie sich selbst.',
        'advertise.about.headline': 'Den perfekten Marktplatz für barrierefreie Unterkünfte finden Sie bei uns!',
        'advertise.about.1': 'Als erster Anbieter sammelten und dokumentierten wir rollstuhlgerechte Unterkünfte in Deutschland und Europa. Zunächst in Buchform, dann Online und als Zeitschrift. Profitieren auch Sie von unserem hohen Erfahrungsschatz und guten Namen. Wenn es um Fragen rund um die Vermietung von rollstuhlgerechten und barrierefreien Räumlichkeiten geht, sind wir stets der richtige Ansprechpartner. Der behinderte Gast steht dabei genauso im Vordergrund, wie Ihr Erfolg.',
        'advertise.about.2': 'Ob Ferienwohnungen, Hotels oder Pensionen. Ihre Unterkunft ist bei uns in guten Händen wenn Sie sich eine hohe Auslastung ihres Angebotes wünschen. Ob pflegebedürftige, Rollstuhlfahrer, Senioren, Menschen mit Mobilitätseinschränkungen oder anderen Handicaps. Mit uns gewinnen Sie die richtigen Gäste für ihre Unterkunft. Wer zu Ihnen passt entscheiden Sie mit Ihrem Inserat, den Rest erledigen wir!',
        'advertise.divider.text': 'Kostenlos testen & inserieren!',
        'advertise.divider.button': 'Legen Sie los',
        'advertise.divider.about.text': 'Sie wollen mehr zu Rolli Hotels erfahren?',
        'advertise.divider.about.button': 'Mehr erfahren',
        'advertise.advantage.placement.headline': 'Effektivere Platzierung',
        'advertise.advantage.placement.text': 'Profitieren Sie von den zahlreichen Werbemöglichkeiten unseres Portfolios.',
        'advertise.advantage.occupancy.headline': 'Volle Belegung',
        'advertise.advantage.occupancy.text': 'Erreichen Sie eine konstante Belegung mit Menschen die es schätzen! ',
        'advertise.advantage.coverage.headline': 'Bessere Reichweite',
        'advertise.advantage.coverage.text': 'Sie erreichen zielsicher Kunden mit konkreten Absichten!',
        'advertise.advantage.rollstuhl.kurier.headline': 'Rollstuhl-Kurier-Rabatte',
        'advertise.advantage.rollstuhl.kurier.text': 'Gewähren Sie unseren Lesern Rabatt, erhalten Sie eine dauerhafte Sonderhervorhebung!',
        'advertise.advantages.headline': 'Ihre Vorteile auf einen Blick',
        'advertise.jump.button.text': 'Jetzt hier registrieren',


        /*******************************************************
         * FAQ
         ********************************************************/
        'faq.headline': 'Häufig gestellte Fragen',
        'faq.subline': 'Tipp & Hilfestellung: Barrierefrei Daten wie Abstände erfassen',
        'faq.content': 'Bei der Erstellung Ihres Unterkunft-Profils werden in Schritt 4 („Fügen Sie Ihre Barrierefreiheit hinzu“) Türbreiten, Abstände und Bewegungsspielräume abgefragt. Diese liegen i.d.R. NICHT vor. Nach dem Login finden Sie unsere „Ausfüllhilfe“ als PDF vor. Drucken Sie dieses Dokument aus und erfassen Sie die be- nötigten Daten in den entsprechenden Räumen mit einem Zollstock oder Maßband. Übertragen Sie diese Daten dann am Computer in Ihr Profil. Alternativ loggen Sie sich mit einem Smartphone oder Tablet in Ihrem Profil ein und geben die Daten direkt im Profil ein.',

        'faq.item.00.headline': 'Mehrere rollstuhlgerechte Zimmer/Wohnungen?',
        'faq.item.00.content': 'Pro Registrierung erfassen Sie immer eine Wohneinheit (Zimmer) mit Bad. Sollten Sie mehrere Ferien- wohnungen oder Zimmer anbieten, können Sie weitere Einträge erstellen. Fordern Sie hierzu einfach neue Login-Daten an. Drei zusätzliche Einträge (pro Hotel) sind für Provisionskunden kostenfrei. Für Fixkosten- Modell-Kunden gibt es Ermäßigungen.',

        'faq.item.01.headline': 'Eine Ferienwohnung mit mehreren Zimmern?',
        'faq.item.01.content': 'Geben Sie die gesamte Anzahl an Zimmern an, die Rollstuhlfahrern oder Gehbehinderten in der Ferien- wohnung oder dem Ferienhaus zur Verfügung stehen. Zimmer, die nur für Fußgänger nutzbar sind, teilen Sie als „Gehbehinderte“ Zimmer ein. Bei der Beschreibung von Abständen in Zimmer und Bad müssen Sie sich dann für ein Musterzimmer entscheiden. Im Kommentarfeld können Sie auf mehr Details eingehen und die Gesamtpersonenzahl angeben, die beherbergt werden kann.',

        'faq.item.02.headline': 'Was ist mit Mindesttürbreite gemeint?',
        'faq.item.02.content': 'Hier muss die Tür mit der geringsten Breite in der Unterkunft angegeben werden, die einen für Rollstuhl- fahrer relevanten Bereich zugänglich macht. Beispiel Hotels: Alle Türen auf dem Weg ins Zimmer sowie zum Speiseraum und zur Lobby. Beispiel Ferienwohnung: Zimmer, Flure, Wohn- und Esszimmer, Hausein- gang. Zugänglichkeiten beispielsweise von Spa-Bereichen, Terrassen, Fitnessräumen o.a. Räumlichkeiten sind zwar wünschenswert, für einen erfolgreichen Aufenthalt aber nicht zwingend erforderlich.',

        'faq.item.03.headline': 'Türbreite messen?',
        'faq.item.03.content': 'Hier messen Sie die Durchgangsbreite im inneren Rahmen. Also die maximale Breite, die Gegenstände haben können, um durch die Tür zu passen.',

        'faq.item.04.headline': 'Freiraum neben dem Bett?',
        'faq.item.04.content': 'Wie viel Platz hat der Rollstuhlfahrer, um an einer Seite an das Bett zu fahren? Messen Sie den Abstand von der Bettkante zu einer Wand oder einem Hindernis, das am Heranfahren hindert.',

        'faq.item.05.headline': 'Bettkanten-Höhe?',
        'faq.item.05.content': 'Wird vom Fußboden bis zur oberen Matratzen-Kante gemessen.',

        'faq.item.06.headline': 'Was ist mit Bewegungsspielraum gemeint?',
        'faq.item.06.content': 'Der Bewegungsspielraum gibt an, wieviel Platz der Rollstuhlfahrer in einem Raum zum Drehen und Wen- den hat. Gemessen wird die größtmögliche Frei-Fläche (Länge mal Breite) ohne Hindernisse.',

        'faq.item.07.headline': 'WC rechts- oder linksseitig messen?',
        'faq.item.07.content': 'Die korrekte Perspektive ist die mit dem Rücken zur Wand, also auf dem WC sitzend. Rollstuhlfahrer fah- ren i.d.R. rückwärts an das WC heran und setzen dann um. Ist das WC linksseitig anfahrbar, wird also vom Rollstuhl links auf die Toilette nach rechts umgesetzt.',

        'faq.item.08.headline': 'Ebenerdig: Bedeutung?',
        'faq.item.08.content': 'Flächen, die auf einer Ebene ohne Stufen, Sockel oder Kanten zu erreichen sind. Ebenerdige Erreichbar- keit ist auch gegeben, wenn Höhenunterschiede beispielsweise über Rampen, leichtes Gefälle oder Aufzü- ge überwunden werden können.',

        'faq.item.09.headline': 'Bewegungsspielraum Duschen mit Vorhängen?',
        'faq.item.09.content': 'Bei Duschen mit Vorhang oder gar ganz ohne Spritzschutz (offen im Raum) werden Vorhang oder Boden- markierungen nicht als Hindernis zur Messung des Bewegungsspielraumes betrachtet.',

        'faq.item.10.headline': 'Muss ich Abstände angeben?',
        'faq.item.10.content': 'Ja. Für viele Gäste mit Behinderung stellen diese einen entscheidender Faktor dar. Unterkünfte, die Aus- kunft über Türbreiten, Bewegungsspielräume etc. geben, werden häufiger gebucht.',

        'faq.item.11.headline': 'Angaben zu Aktivitäten?',
        'faq.item.11.content': 'Sollten Sie keine barrierefreien Freizeitaktivitäten in Ihrer Umgebung kennen, kann es sich lohnen, diese ausfindig zu machen. Mit einem Anruf z.B. beim Museum oder im nahe gelegenen Restaurant lässt sich häufig schnell klären, ob dieses barrierefrei zugänglich ist oder nicht. Auch örtliche Tourismuszentralen können bei dieser Frage behilflich sein.',

        'faq.item.12.headline': 'Login: Passwort anfordern?',
        'faq.item.12.content': 'Um einen Zugang mit Passwort zur Profilerstellung zu erhalten, gehen Sie auf www.rolli-hotels.de/adver- tise. Alternativ klicken Sie die Registerkarte „Vermieter werden“ oben in der Homepageleiste an. Klicken Sie unten rechts im Bildschirm auf „Jetzt hier registrieren“ oder scrollen Sie nach unten, um zu den Datenfeldern zu gelangen. Wählen Sie ein Preismodell aus. Beim Provisionsmodell muss vorher ein Rah- menvertrag abgeschlossen werden. Geben Sie Ihre Daten ein (alles muss ausgefüllt sein) und klicken Sie auf „Unterkunft registrieren“. Sie erhalten unmittelbar im Anschluss eine E-Mail mit Ihrem Passwort für den Login.',

        'faq.item.13.headline': 'Login: Kein Passwort erhalten?',
        'faq.item.13.content': 'Sollten Sie nach dem Absenden Ihrer Daten (siehe Login: Passwort anfordern) kein Passwort erhalten haben, schauen Sie bitte zunächst im Spam-Ordner nach und aktualisieren Sie Ihren E-Mail-Ordner. Füllen Sie die Datenfelder erneut aus und achten Sie dabei auf die korrekte Schreibweise Ihrer Emailadresse. Sollte das Problem weiterhin bestehen, kontaktieren Sie uns telefonisch unter der Nummer (040) 261 00 360.',

        'faq.item.14.headline': 'Login: Profil erstellen?',
        'faq.item.14.content': 'Unter www.rolli-hotels.de/login oder unter der Registerkarte „Vermieten“ oben rechts in der Homepage- leiste können Sie Ihr Passwort eingeben. Achten Sie auf Groß- und Kleinschreibung. Kopieren Sie Ihr Pass- wort (Strg + c) am besten und fügen es danach ein (Strg + v). Bestätigen Sie die Eingabe auf „Login“ und erstellen Sie Ihr Unterkunftsprofil.',


        /*******************************************************
         * Blog
         ********************************************************/
        'blog.title': 'Willkommen in unserer barrierefreien Reisewelt',
        'blog.description.1': 'Seit fast 40 Jahren arbeiten wir barrierefreie Urlaubsangebote aus und versorgen Menschen mit Behinderung mit wichtigen Informationen rund um das Reisen. Unsere Beiträge geben Hilfestellungen, enthalten viele nützliche Tipps und vermitteln fundiertes Hintergrundwissen.',
        'blog.description.2': 'Dieses Wissen kann sich für Rollstuhlfahrer sowie Menschen mit Pflegebedarf oder anderen Einschränkungen auch finanziell bezahlt machen – denn selbst nach Jahren mit einer Behinderung kann man nicht alles wissen, z.B. was Gesetze und Versicherungsleistungen betrifft. Wir recherchieren für Sie!',
        'blog.discover.category': 'Kategorie entdecken',
        'blog.overview.most.seen.title': 'Unsere beliebtesten Beiträge',
        'blog.overview.most.seen.description': 'Lesen Sie hier, welche Themen und Hilfestellungen rund um das barrierefreie Reisen andere Leser mit Handicap aktuell interessieren. Viel Spaß beim Stöbern!',
        'blog.overview.latest.title': 'Unsere neuesten Beiträge',
        'blog.overview.latest.description': 'Hier finden Sie unsere neuesten barrierefreien Reisetipps und Infos. Organisation und Planung sind das A und O für Menschen mit Behinderung, wir helfen dabei!',
        'blog.discover.now': 'Jetzt entdecken',
        'blog.category.posts': 'Beiträge in dieser Kategorie',
        'blog.booking': 'Jetzt Reiseziel buchen',

        'cookies.title': 'Datenschutz und Nutzungserlebnis auf rolli-hotels.de',
        'cookies.actions.settingsMobile': 'Datenschutz und Nutzungserlebnis auf rolli-hotels.de',
        'cookies.actions.settings': 'Einstellungen oder ablehnen',
        'cookies.actions.declineAsSelected': 'Ablehnen wie ausgewählt',
        'cookies.actions.acceptAll': 'Alle Cookies akzeptieren',
        'cookies.explanation.rights': 'Widerspruchsrechte zu Datenverarbeitungen auf Grundlage von berechtigten Interessen können Sie ebenfalls unter „Einstellungen oder ablehnen“ ausüben.',
        'cookies.privacy': 'Datenschutz',
        'cookies.legalNotice': 'Impressum',
        'cookies.explanation.description': 'Wir übermitteln personenbezogene Daten an Drittanbieter, die uns helfen, unser Webangebot zu verbessern. In diesem Zusammenhang werden auch Nutzungsprofile (u.a. auf Basis von Cookie-IDs) gebildet und angereichert, auch außerhalb des EWR. Hierfür und um bestimmte Dienste zu nachfolgend aufgeführten Zwecken verwenden zu dürfen, benötigen wir Ihre Einwilligung. Indem Sie "Alle akzeptieren" klicken, stimmen Sie diesen (jederzeit widerruflich) zu. Dies umfasst auch Ihre Einwilligung nach Art. 49 (1) (a) DSGVO. Unter "Einstellungen oder ablehnen" können Sie Ihre Einstellungen ändern oder die Datenverarbeitung ablehnen.',
        'cookies.explanation.descriptionMobile': 'Die Einstellungen helfen Ihnen bei der Aktivierung und Deaktivierung verschiedener Tags, Tracker und Analysetools, die auf dieser Webseite verwendet werden.',
        'cookies.explanation.device.title': 'Informationen auf einem Gerät speichern und/oder abrufen',
        'cookies.explanation.device.description': 'Für die Ihnen angezeigten Verarbeitungszwecke können Cookies, Geräte-Kennungen oder andere Informationen auf Ihrem Gerät gespeichert oder abgerufen werden.',
        'cookies.explanation.content.title': 'Personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessungen, Erkenntnisse über Zielgruppen und Produktentwicklungen',
        'cookies.explanation.content.description': 'Anzeigen und Inhalte können basierend auf einem Profil personalisiert werden. Es können mehr Daten hinzugefügt werden, um Anzeigen und Inhalte besser zu personalisieren. Die Performance von Anzeigen und Inhalten kann gemessen werden. Erkenntnisse über Zielgruppen, die die Anzeigen und Inhalte betrachtet haben, können abgeleitet werden. Daten können verwendet werden, um Benutzerfreundlichkeit, Systeme und Software aufzubauen oder zu verbessern.',
        'cookies.settings.description': 'Die Einstellungen helfen Ihnen bei der Aktivierung und Deaktivierung verschiedener Tags, Tracker und Analysetools, die auf dieser Webseite verwendet werden.',
        'cookies.settings.device.titleMobile': 'Essentielle Cookies',
        'cookies.settings.device.title': 'Informationen auf einem Gerät speichern und/oder abrufen (essentiell)',
        'cookies.settings.switch.on': 'An',
        'cookies.settings.device.description': 'Informationen wie z. B. Cookies und Geräte-Kennungen zu den dem Nutzer angezeigten Verarbeitungszwecken auf dem Gerät speichern und abrufen.',
        'cookies.settings.content.titleMobile': 'Marketing und Analysen',
        'cookies.settings.content.title': 'Personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessungen, Erkenntnisse über Zielgruppen und Produktentwicklungen',
        'messages.cookies.settings.switch.off': 'Aus',
        'cookies.settings.content.vendors.title': 'Eingebundene Anbieter',
        'cookies.settings.content.vendors.googleAnalytics': 'Google Analytics',
        'cookies.settings.content.vendors.googleAds': 'Google Ads',
        'cookies.settings.content.vendors.googleTagManager': 'Google Tag Manager',
        'cookies.settings.content.vendors.facebookPixel': 'Facebook Pixel'
    },
    [Language.ENGLISH]: {
        'app.name': 'Rolli-Hotels.de',
        'menu.home': 'Home',
        'menu.escales': 'Escales GmbH',
        'menu.destinations': 'Destination',
        'menu.blog': 'Blog',
        'menu.about.us': 'About us',
        'menu.contact': 'Contact',
        'menu.rent': 'Advantages',
        'menu.login': 'Rent',
        'menu.logout': 'Logout',
        'menu.legal.notice': 'Legal notice',
        'menu.data.privacy': 'Privacy Policy',
        'menu.disclaimer': 'Disclaimer',
        'menu.terms.of.use': 'GTC',
        'menu.faq': 'FAQ',
        'menu.hotel': 'My accommodation',
        'footer.escales.headline': 'Escales GmbH',
        'footer.handicapped.headline': 'Rolli-Hotels.de',
        'footer.legal.headline': 'Legal Notice',
        'link.irma': 'IRMA',
        'link.rollstuhl.kurier': 'Rollstuhl-Kurier',
        'link.handicapped.reisen.de': 'Rolli-Hotels.de',
        'link.handicapped.reisen': 'Handicapped-Reisen',

        'cookie.notice.hint': 'We use cookies. <a href="/legal/privacy">Data privacy</a>',
        'cookie.notice.accept': 'Agree',

        'language.german': 'German',
        'language.english': 'English',

        /***********************************************************************************
         * Site title
         ***********************************************************************************/
        'title': 'Rolli-Hotels',
        'title.home': 'Your reliable partner for wheelchair accessible accommodations!',
        'title.faq': 'FAQ',
        'title.advertise': 'Advantages',
        'title.register': 'Register',
        'title.login': 'Login',
        'title.destinations': 'Destination',
        'title.termsOfUse': 'GTC',
        'title.disclaimer': 'Disclaimer',
        'title.privacy': 'Privacy Policy',
        'title.legalNotice': 'Legal Notice',
        'title.contact': 'Contact',
        'title.aboutUs': 'About us',

        /***********************************************************************************
         * General wordings
         ***********************************************************************************/
        'filter': 'Filter',
        'filter.reset': 'Filter reset',
        'search.restart': 'Restart search',
        'filter.apply': 'Show accomodations',
        'search': 'Search',


        /***********************************************************************************
         * Home
         ***********************************************************************************/
        'home.search.headline': 'Your reliable partner for wheelchair accessible accommodations!',
        'home.search.subline': 'Find wheelchair accessible Hotels, barrier-free holiday homes, handicapped accessible bed and breakfast places and farms in Germany and Europe with us.',
        'home.search.headline.mobile': 'Your partner for wheelchair accessible accommodations',
        'home.search.subline.mobile': 'Find your next accessible holiday destination with us.',
        'home.search.button': 'Where do you want to go?',
        'home.search.county': 'Where do you want to go?',
        'home.search.county.hint': '(Please choose)',
        'home.search.arrival': 'Arrival',
        'home.search.departure': 'Departure',
        'home.search.persons': 'Persons',
        'home.search.rooms': 'Rooms',

        'home.highlights.headline.1': 'Selected destinations',
        'home.highlights.headline.beach': 'near ocean',
        'home.highlights.headline.wellness': 'Wellness and spa',
        'home.highlights.headline.nature': 'in the nature',
        'home.highlights.headline.mountains': 'in the mountains',
        'home.highlights.headline.city': 'with city and culture',
        'home.highlights.headline.groups': 'for groups',
        'home.highlights.headline.sport': 'with sport offers',
        'home.highlights.more': 'See more',
        'hotel.price.from': 'from',
        'hotel.price.night': 'Night',
        'hotel.price.per.night': 'per night',
        'home.hotel.details': 'Details',
        'hotel.number.of.rooms.for.wheelchair.user': 'Number of wheelchair accessible rooms',
        'hotel.number.of.rooms.for.handicapped.user': 'Number of mobility handicapped rooms',
        'home.categories.headline': 'Choose your category',
        'home.categories.text': 'Any form of vacation is possible with a disability if you know where and how! Our wheelchair accessible hotels, pensions and holiday apartments make it possible.Choose a barrier-free type of housing and let\'s go.',

        'home.categories.mountains': 'Mountains',
        'home.categories.mountains.description': 'With proper aids and wheelchair accessible housings you can even spent your vacation in the mountains. We show you these accessible housings here.',
        'home.categories.sea': 'Beach',
        'home.categories.sea.description': 'Holidays and trips tot he beach for wheelchair users or people with other handicaps are no problem. You\'ll find barrier-free beach offers here!',
        'home.categories.wellness': 'Wellness',
        'home.categories.wellness.description': 'Barrier-free wellness oasis, accessible spa-hotels, thermes with ground level saunas and pools with lift systems you will only find with us!',
        'home.categories.nature': 'Nature',
        'home.categories.nature.description': 'Holidays with your wheelchair in the nature in beautiful holiday regions. Find suitable handicapped accessible Hotels, guesthouses and Farms here!',
        'home.categories.sport': 'Sport',
        'home.categories.sport.description': 'Looking for sport offers with wheelchair on vacation? Discover new and familiar barrier-free sports in our accomodations.',
        'home.categories.groups': 'Group travel',
        'home.categories.groups.description': 'Find offers for vacation groups with plenty wheelchair users or people with other handicaps in accessible hotels, holiday homes or Farms with enough space here!',
        'home.categories.city': 'City & Culture',
        'home.categories.city.description': 'Want to discover barrier-free museums or historic districts? Explore history with your wheelchair and find suitable hotels for your journey!',
        'home.divider.text': '"Wheelchair accessible traveling can be so easy! Finding accessible accommodations like hotels or holiday homes is the first step. Discover Europe-wide barrier-free housings in beautiful holiday regions."',
        'home.divider.slide.1': 'Traveling with wheelchair ist sometimes challenging. But there are still plenty of options like finding lovely and accessible landscapes, driving on hiking trails or doing other barrier-free activities on vacation in germany. You find beautiful accessible hotels in Bavaria just as wheelchair accessible holiday homes in Saxon Switzerland, the North Sea or the german sub-mountainous region.',

        'home.blog.title': 'Our favorite blog posts',
        'home.blog.description': 'Check our barrie free travel blog regularly. We update our posts regularly for you.',

        /***********************************************************************************
         * About Us
         ***********************************************************************************/
        'about.us.headline': '37 years barrier-free traveling',
        'about.us.subline': 'A little evolutionary history',
        'about.us.goals.headline': 'We and our barrier-free intentions',
        'about.us.goals.text.1': 'Almost 40 years ago we startet our work for people with handicap in our family business. One reason was personal experiences. With our print-medias, websites and our rehabilitation-fair IRMA are always needs of people with handicap and wheelchair users focused. Our intentions then and now: People with handicap should be able to ',
        'about.us.goals.text.1.mobile': 'Almost 40 years ago we startet our work for people with handicap in our family business. One reason was personal experiences. With our print-medias, websites and our rehabilitation-fair IRMA are always needs of people with handicap and wheelchair users focused.',
        'about.us.goals.text.2': 'travel the world and take part on life as anybody else! Rolli-Hotels.de is a portal in which people with handicap, wheelchair user and people in need of care are able to get independent reliable informations about accommodations!',
        'about.us.goals.text.2.mobile': 'Our intentions then and now: People with handicap should be able to travel the world and take part on life as anybody else! Rolli-Hotels.de is a portal in which people with handicap, wheelchair user and people in need of care are able to get independent reliable informations about accommodations. Accessibility and integration are part of our tradition!',
        'about.us.section.1.title': '37 years of barrier-free traveling for people with disablilities and wheelchair users',
        'about.us.section.1.text.1': 'Barrier-free traveling is a tradition in our company für more than 37 years. Till now we work exclusively for and with people with handicap.',
        'about.us.section.1.text.2': 'Our family business was the first provider in germany, which collected area-wide informations about wheelchair-accessible housings. These informations were presented in our print guide „Handicapped-Reisen“ fort he first time. This guide was in times before the internet came up the only possibility for people with disabilities to get informations about holidays and to leave their own home for traveling.',
        'about.us.section.2.title': 'Often a problem: Barrier-free informations in the internet',
        'about.us.section.2.text': 'Sadly also in times oft he internet are reliable informations for people with handicap and wheelchair user often rarely. Informations in big travel portals are often inaccurate, incomplete and unchecked. Almsot every person concerned has made bad experience with this. Unsuitable accomodations can cause an early breaking off a journey. We also made these experience over the years during our reports for our magazin Rollstuhl-Kurier now and than.',
        'about.us.section.3.title': 'Accurate and reliable: <br />Our standard',
        'about.us.section.3.text': 'Listed accommodations you find here were checked in detailes and some of them also tested personally by our company. Like our name Rollihotel (engl. Wheelchair-hotels) suggests we only concern to needs for wheelchair user and immobile people. You rarely find such standards on other sides. People who travel with us are on the save side. Here listed housings are not just having accessible offers, they especially like to welcome people with handicap at their places (more about our wheelchair accessible housings here).',
        'about.us.guide.book.headline': 'Our print-guide',
        'about.us.guide.book.text': 'If you prefer informations in book-form you can order our current guide Handicapped-Reisen (engl. Handicap-Traveling) online if you want. You are also well advised with this choice. Order your personal guide online now!',
        'about.us.guide.book.button': 'Order now',
        'about.us.guide.book.button.mobile': 'Order your personal guide now',


        'hotels.categories.0': 'Restaurant',
        'hotels.categories.1': 'Spa',
        'hotels.categories.2': 'Garden',
        'hotels.detail.price.per.night': '/ Night',
        'hotels.detail.price.per.night.mobile': 'per night',
        'hotels.detail.price.from': 'from',
        'hotels.detail.contact.headline': 'Request vacation',
        'hotels.detail.contact.name': 'Your name',
        'hotels.detail.contact.email': 'Your E-Mail',
        'hotels.detail.contact.phone': 'Your phone number',
        'hotels.detail.contact.number.of.guests': 'Number persons',
        'hotels.detail.contact.number.of.rooms': 'Number rooms',
        'hotels.detail.contact.message': 'Your message...',
        'hotels.detail.contact.send': 'Sent request',
        'hotels.detail.contact.send.active': 'Request sent',
        'hotels.detail.description.headline': 'Description',
        'hotels.detail.accessibility.headline': 'Accessibility',
        'hotels.detail.facilities.headline': 'Facilities',
        'hotels.detail.offers.headline': 'Offers on location',
        'hotels.detail.position.headline': 'Position',
        'hotels.detail.contact.request': 'Request vacation',
        'hotels.detail.contact.request.button': 'Submit message',
        'hotels.detail.contact.send.error': 'Your request couldn\'t be sent.',
        'hotels.detail.contact.send.success': 'Your request was sent successfully.',
        'hotels.detail.collage.show.light.box': 'Show images',
        'hotels.detail.contact.arrival.departure': 'Arrival/departure',
        'hotels.detail.contact.link': 'Request',
        'hotels.detail.phone': 'Show',
        'hotels.detail.phone.show': 'Show number',
        'hotels.detail.website': 'Website',


        /***********************************************************************************
         * Contact
         ***********************************************************************************/
        'contact.headline': 'Contact us',
        'contact.caption': 'Sent a message at any time.',
        'contact.name': 'Your name',
        'contact.email': 'Your mail',
        'contact.phone.input': 'Your phone number',
        'contact.message': 'Your message...',
        'contact.privacy.accept.1': 'I agree to ',
        'contact.privacy.accept.2': ' the',
        'contact.privacy': 'Privacy Policy',
        'contact.send': 'Submit message',
        'contact.send.active': 'Message will be sent',
        'contact.address.headline': 'Adress',
        'contact.address': 'Auf dem Rapsfeld 31, 22359 Hamburg',
        'contact.mail.headline': 'Mail',
        'contact.mail': 'info@rolli-hotels.de',
        'contact.phone.headline': 'Phone',
        'contact.phone': '+49 (0)40 26 100 360',
        'contact.send.success': 'Your message has been sent.',
        'contact.send.error': 'Your message couldn\'t be sent.',
        'contact.send.error.fields': 'Please fill in all fields and agree to the privacy.',


        /***********************************************************************************
         * Destinations
         ***********************************************************************************/
        'destinations.search.results.empty': 'Sadly your search wasn\'t successful. Please try again and choose less filters.',
        'destinations.divider.1.title': 'You need informations for technical aids on vacation? Visit the international rehabilitation-exhibition IRMA.',
        'destinations.divider.1.button': 'Compare now',
        'destinations.divider.2.title': 'Detailed travel-reports about barrier-free locations you will only find in the magazin Rollstuhl-Kurier.',
        'destinations.divider.2.button': 'Find out more here',
        'destinations.divider.3.title': 'Wheelchair accessible housings and useful tips about traveling with handicap are existing for almost 40 years in our guide Handicapped-Reisen.',
        'destinations.divider.3.button': 'Show it to me',
        'destinations.search.card.view': 'Show map',
        'destinations.loading.title': 'Your housings are loading',
        'destinations.search.headline': 'Find barrier-free accommodations with all details',
        'destinations.search.subline': 'Wheelchair accessible hotels, barrier-free holiday flats and accessible guesthouses are waiting for you europe-wide.',
        'destinations.search.headline.mobile': 'Find accessible housings',
        'destinations.search.subline.mobile': 'Wheelchair accessible europe-wide.',
        'destinations.search.category.apply': 'Apply',

        'destinations.main.title': 'We are Wheelchairhotels – Your partner for accessible traveling',
        'destinations.main.description': 'Almost 40 years ago our company was the first provider for handicapped accessible accomodations in germany. Now and than our priority was to give people with disabilities options to travel without any bad suprises. With Rollihotels (engl. Wheelchairhotels) you find realy accessible hotels, barrier-free holiday flats as well as guesthouses and farms for people with handicap. Our standards for accessibility are always high. Extensive questionings and register processes for our providers are making high standards possible. Every accommodation on our plattform has at least accessible rooms for wheelchair user so that they can spent the night barrier-free. In the profiles of our providers you find detailed informations about spaces, width of entrances, the freedom of movement in rooms, technical aids, care options, activity options and other helpful things you need to know with a disability. Some of our housings are even totaly specialized on handicap people. According to this all of our holiday vacations welcome handicapped people in particular. Some of our accommodations has been visited and tested by our editorial office of our magazin Rollstuhl-Kurier (engl. Wheelchair-News). Until now we constantly report about barrier-free areas and housings europe-wide.',

        'destinations.read.more': 'Find out more about Rollihotels',
        'destinations.read.less': 'Show less',

        'destinations.extended.1.title': 'Wheelchair accessible holidays – planing aktivities in Germany and Europe',
        'destinations.extended.1.description': 'Every person with disability who wants to plan barrier-free vacation by themself is on the right place with Rollihotels because the most important thing is finding accessible housings. Our homepage offers barrier-free hotels, holiday homes and farms all over Germany. Some of our housing partners are having decades of experience in renting rooms to disability persons so that they can also tell you about accessible activities in their area. They can tell you which technical aids are the best for the landscape around as well as other relevant things. Where are accessible places, can i visit the museum of art with my wheelchair and are any restaurants reachable on ground level? You can find answers for most of these questions when you contact our providers. Go and discover the enviroment with your handbike in the flat north of Germany, visit the mountains in Bavaria or Austria with electrical aids or just relax a few days in one of our barrier-free wellness and spa hotels. There are plenty of options for you. Electric wheelchair user find suitable holiday offers as well as families with people in need of care. Activities for people with handicap are existing everywhere. Our experience shows you where!',

        'destinations.extended.2.title': 'Technical aids, care and bathrooms on vacation',
        'destinations.extended.2.description': 'All of the barrier-free accommodations on Rollihotels are at ground level reachable. Rooms can be entered without passing any stairs or other fences. You can also reach bathrooms and dining areas without problems in your wheelchair. Depending on the lodging there are some who can even accommodate groups with many wheelchair users. Traveling with your support group or handicap sports clubs are just as little a problem like holidays with people in need of care or wide electric wheelchair users. Every house here is equipped with barrier-free rooms which are also having accessible bathrooms. In general there is enough freedom of movement in our locations. Toilets are having at least enough space on one side to drive by with a wheelchair and are also provided with solid handles. You can get into showers at ground level. Most of the showers are also equiped with shower chairs, other kind of seats and handles as well. Sinks are also usable with wheelchair. We have offers for maintance for people in need of care on holidays and also housings with several therapeutic actions. Many accommodation facilities are connected to medical supply stores which enables guest to rent technical aids for their journey. Beeing equipped with hospital beds and other useful add-ons are often a standard for our providers like the integrative mind.',

        /*******************************************************
         * Login
         ********************************************************/
        'login.headline': 'Finally barrier-free traveling for everyone.',
        'login.description': 'If you already reveived your access code, please use it to log-in here.',
        'login.email.placeholder': 'E-Mail adress',
        'login.password.placeholder': 'Password',
        'login.submit': 'Login',
        'login.success': 'Your login was successful.',
        'login.error': 'Please check your E-Mail and password.',
        'register.request': 'Still no renting-account? Find out more here.',
        'hotels.add.name': 'Name of accommodation',
        'hotels.add.gender': 'Titel',
        'hotels.add.gender.-1': 'Titel',
        'hotels.add.gender.0': 'Mr.',
        'hotels.add.gender.1': 'Mrs.',
        'hotels.add.first.name': 'First name',
        'hotels.add.last.name': 'Family name',
        'hotels.add.email': 'E-Mail',
        'hotels.add.phone': 'Phone',
        'hotels.actions.add.title': 'Register',
        'hotels.actions.add.cancel': 'Cancel',
        'hotels.actions.add.submit': 'Register',
        'hotels.actions.add.success': 'Your registration was successful. You receive a message soon.',
        'hotels.actions.add.error': 'You couldn\'t get registered. Please try again later.',

        'register.headline': 'Advertise your barrier-free accomodation!',
        'register.help.download': 'Download fill-in help',
        'register.step.1': 'General data',
        'register.step.2': 'Get qualified as accessible housing',
        'register.step.3': 'Describe your accommodation',
        'register.step.4': 'Add your accessibility',
        'register.step.5': 'Add pictures of your accommodation',
        'register.step.6': 'Confirm your Informations',
        'register.hotel.name': 'Name of your accommodation',
        'register.hotel.subtitle': 'Describe your housing in one phrase',
        'register.hotel.street': 'Street',
        'register.hotel.street.number': 'No.',
        'register.hotel.zip.code': 'ZIP',
        'register.hotel.city': 'City',
        'register.hotel.country': 'Country',
        'register.hotel.region': 'Region',
        'register.hotel.county': 'State',
        'register.add.language': 'Add translation',
        'register.remove.language': 'Remove translation',
        'register.step.1.phone.contact': 'I want to get contacted by phone (only for customers of the fixed cost model).',
        'register.step.1.website.request': 'I want to redirect requests to my own website (only for customers of the fixed cost model).',

        'register.step.1.request.hint.title': 'Note commission model',
        'register.step.1.request.hint.content': 'Message and phone functions will be switched to Rollihotels after entry creation. Redirects to the website will be disabled.',

        'register.hotel.contact.phone': 'Phone number',
        'register.hotel.website': 'www.accomodation.de/booking-link',
        'register.optional': 'optional',
        'register.optional.prices': 'just fill in if available',
        'register.step.next': 'Next',
        'register.step.back': 'Back',
        'register.step.finish': 'Request approval',
        'register.step.finish.hint': 'When you request the approval, you cannot make any changes for the time being. Make sure that you have entered and checked everything. Changes are only possible again after approval.',
        'register.step.update': 'Save & put live',
        'register.step.2.headline': 'Do you have an accessible housing?',
        'register.step.2.subline': 'Following datas must at least fit for one of your rooms: ',
        'register.step.2.step.1.headline': 'The accommodation',
        'register.step.2.step.1.bullet.1': 'The accommodation is accessible at ground level from parking place.',
        'register.step.2.step.1.bullet.2': 'You don\'t need to use stairs to reach the room.',
        'register.step.2.step.1.bullet.3': 'All relevant doors are at least 70 cm wide.',
        'register.step.2.step.1.bullet.4': 'Dining area or restaurant are accessible without passing stairs.',

        'register.step.2.step.2.headline': 'Ways and rooms',
        'register.step.2.step.2.bullet.1': 'All floors and connecting doors from entrance to the room are at least 70 cm wide.',
        'register.step.2.step.2.bullet.2': 'At least there is an open space in the room of min. 80 x 100 cm to turn around',
        'register.step.2.step.2.bullet.3': 'At least there is enough space between bed and wall on one side of min. 70 cm.',

        'register.step.2.step.3.headline': 'The bathroom',
        'register.step.2.step.3.bullet.1': 'The door width of the bathroom is at least 70 cm wide. Wheelchair user can turn around (open space min. 100 x 100 cm)',
        'register.step.2.step.3.bullet.2': 'At least on one side of the toilette (left or right) is enough space for a wheelchair to get between (min. 70 cm) and at least one handle.',
        'register.step.2.step.3.bullet.3': 'You can get into the shower at ground level. There is open space in the showe of at least 100 x 80 cm.',
        'register.step.2.step.3.bullet.4': 'There is a handle in the shower. Shower chairs or other kind of seats in the shower are advisable.',
        'register.step.2.success': 'Hooray! Your housing is accessible',

        'register.step.3.category.headline': 'Category',
        'register.step.3.category.subline': 'Choose up to 3 categorys: ',
        'register.step.3.category.selected': 'Category selected',
        'register.step.3.category.selected.plural': 'Categories selected',
        'register.step.3.description.headline': 'Description',
        'register.step.3.description.placeholder': 'You can describe your accommodation here...',
        'register.step.3.distances.headline': 'Distances',
        'register.step.3.prices.headline': 'Prices',
        'register.step.4.rooms.headline': 'How many rooms do you have for…',
        'register.step.4.rooms.headline.wheel.chair': 'Wheelchair user',
        'register.step.4.rooms.wheel.chair.content': 'How many accessible housings with accessible showers/toilets do you have?',
        'register.step.4.rooms.number': 'Number',
        'register.step.4.rooms.headline.handicapped': 'Walking disabilities',
        'register.step.4.rooms.handicapped.content': 'How many housings are reachable handicapped accessible and without accessible bathroom?',
        'register.step.5.drop.headline': 'Drag and Drop',
        'register.step.5.drop.content': 'Alternative search in your files',
        'register.step.5.drop.click': 'here.',
        'register.step.5.drop.accept': 'We accept pictures in .jpg or .png format up to 5 mb per picture.',
        'register.step.5.drop.content.active': 'Your pictures are loading.',
        'register.step.5.select.title.image': 'Choose your cover picture:',
        'register.step.5.title.image': 'Cover',
        'register.step.5.highlights': 'Highlights',
        'register.step.5.additional.images': 'More images',
        'register.step.5.drag.drop.hint': 'You can change the sequence of pictures by Drag & Drop.',
        'register.step.5.images.hint': 'Think on uploading a picture of the <span>bathroom</span>. More than 80% of people with handicap decide in subjection of the bathroom.',
        'register.step.5.images.hint.accept': 'I got it',
        'register.step.5.images.error.hint': 'The following pictures couldn\'t get uploaded:',
        'register.step.5.images.error.accept': 'Close',
        'register.complete.active.headline': 'We prepare your accommodation',
        'register.approval.success.content': 'Thank you for your entry! After successful verification we will contact you.',
        'register.complete.success.headline': 'Hooray 🥳🥳🥳!',
        'register.complete.success.content': 'Your accommodation is online now!',
        'register.complete.success.button': 'See accommodation',
        'register.complete.error.button': 'Close & back to home',

        'advertise.prices.headline': 'Successfully rent your barrier-free properties',
        'advertise.prices.sub.line': 'Accommodations with accessible rooms count on our expertise for almost 40 years.',
        'advertise.switch.timing.advertisement': 'Test nonbinding & free now!',
        'advertise.switch.timing.yearly': 'Annual billing',
        'advertise.switch.timing.monthly': 'Monthly billing',
        'advertise.prices.explanations.headline': 'Your advantages at a glance',
        'advertise.prices.explanations.bullet.1': 'Use our expertise',
        'advertise.prices.explanations.bullet.2': 'Manage your housing by yourself',
        'advertise.prices.explanations.bullet.3': 'Use unused capacities',
        'advertise.prices.explanations.bullet.4': 'Top-Ad: Your accommodation will be shown on landing page',
        'advertise.prices.explanations.bullet.5': 'Get 20% off by annual billing',
        'advertise.prices.explanations.bullet.6': 'Monthly cancelable. <br />No minimum duration',
        'advertise.prices.explanations.bullet.advertisement.1': 'Manage accommodation yourself and make changes at any time.',
        'advertise.prices.explanations.bullet.advertisement.2': 'Free basic Rollstuhl-Kurier subscription for display on your premises.',
        'advertise.prices.explanations.bullet.advertisement.3': '<span>10% discount</span> on all listings in Handicapped-Reisen or a free basic listing.',
        'advertise.prices.explanations.bullet.advertisement.4': 'Develop a new target audience by detailing your accessible features.',
        'advertise.prices.explanations.bullet.advertisement.5': '<span>No success, no costs!</span> With mediation you pay a success commission',
        'month': 'Month',
        'quarter': '3 months',
        'advertise.prices.explanation.discount.per.year': 'Save 60€ per year',
        'advertise.prices.explanation.price.per.advertisement': 'After trial you can get permanent unlocked for 20 Euro per month.',
        'advertise.prices.explanation.price.per.advertisement.2020': 'Advertise now and get requests for free.',
        'advertise.prices.explanation.price.per.year': '240€ per accommodation and annual billing',
        'advertise.prices.explanation.price.per.month': '300€ per accommodation with monthly billing',
        'advertise.prices.trial': 'Try for free',
        'advertise.prices.trial.advertisement': 'Test for free now*',
        'advertise.prices.test': 'Test for nonbinding and free for 3 months now!',
        'advertise.prices.test.advertisement': '*Your testing time automatically expires. There will be no costs',
        'advertise.prices.test.advertisement.2020': '*12 months of contract period. Up to 5 requests for free. No succsess, no costs! You will receive requests per phone or mail.',
        'advertise.prices.test.mobile': 'Test nonbinding and free now!',
        'advertise.prices.trial.months': 'After 3 month of testing you can decide if you want to subscribe or not',
        'advertise.request.advertisment': 'Request your free and nonbinding test:',
        'advertise.request.monthly': 'Request a nonbinding offer for a monthly billing now:',
        'advertise.request.yearly': 'Request a nonbinding offer for an annual billing now:',
        'advertise.request.message': 'Your message',
        'advertise.accept.privacy': '<style>a {text-decoration: none; color:#2F89FC; transition: all 0.3s cubic-bezier(0.55, 0, 0.6, 1)}  a:hover {color:#102434;}</style>I agree to the <a href="/legal/privacy" rel="noreferrer" target="_blank">Privacy Policy</a>.',
        'advertise.accept.terms.of.use': '<style>a {text-decoration: none; color:#2F89FC; transition: all 0.3s cubic-bezier(0.55, 0, 0.6, 1)}  a:hover {color:#102434;}</style>I agree to the <a href="/legal/termsOfUse" rel="noreferrer" target="_blank">terms and conditions</a>.',
        'advertise.request.send': 'Request',
        'advertise.request.send.active': 'Request will be sent',
        'advertise.request.fill.fields': 'Please fill-in all fields and write a correct email address.',
        'advertise.headline': 'Successfully rent your properties with us!',
        'advertise.content.1': 'Since almost 40 years accommodations with accessible rooms count on our expertise. With us you have a strong partner helping you to a better utilised capacity of accessible rooms.',
        'advertise.content.2': 'We help with words and deeds if you have any questions. Our succsess as well es the success of our cutomers are confirming this. Get convinced by yourself!',
        'advertise.about.headline': 'Here you find the perfekt marketplace for barrier-free housings!',
        'advertise.about.1': 'Our company was the first one which documented barrier-free accommodations in germany and europe. First of all we started printing this offers in our book Handicapped-Traveling and after this in our magazin Rollstuhl-Kurier (engl. Wheelchair-News). Take benefit from this experience and offer your housings with us. In the matter of renting accessible rooms and accommodations we have the right market place for you. Handicapped people are in the foreground just as your success.',
        'advertise.about.2': 'Whether hotel, holiday home or guesthouse. Your accommodation is in good hands if you are looking for a better utilised capacity. From people in need of care, wheelchair user, elderly people, people with other restrictions to their mobility or other handicaps. You can win proper guests for your housing with us. Which handicap fits to your proposal is your decision, depends on your insertion and we will do the rest!',
        'advertise.divider.text': 'Test for free & advertise!',
        'advertise.divider.button': 'Get started',
        'advertise.divider.about.text': 'Get to know more about Rolli Hotels?',
        'advertise.divider.about.button': 'Learn more',
        'advertise.advantage.placement.headline': 'More effective placement',
        'advertise.advantage.placement.text': 'Benefit from our large advertising opportunities',
        'advertise.advantage.occupancy.headline': 'Full booking',
        'advertise.advantage.occupancy.text': 'Reach better booking-rates with people who appreciate this service!',
        'advertise.advantage.coverage.headline': 'Greater range',
        'advertise.advantage.coverage.text': 'You unerringly reach people with concrete intentions!',
        'advertise.advantage.rollstuhl.kurier.headline': 'Wheelchair-News-discount',
        'advertise.advantage.rollstuhl.kurier.text': 'Give a rebate to our readers and get a permanent display!',
        'advertise.advantages.headline': 'Your benefits at a glance',
        'advertise.jump.button.text': 'Register here',
        'advertise.switch.headline': 'Please choose your pricing model:',
        'advertise.switch.fix': 'fixed costs pricing',
        'advertise.switch.provision': 'commission pricing',
        'advertise.switch.fix.mobile': 'fixed costs',
        'advertise.switch.provision.mobile': 'commission',
        'advertise.prices.explanations.bullet.advertisement.6': 'Test now <span>6 months free of charge</span>, afterwards only 10 Euro per month.',
        'advertise.prices.explanations.bullet.advertisement.7': 'Receive unlimited email as well as phone inquiries and link your own homepage.',
        'advertise.prices.explanations.bullet.advertisement.8': 'Term 12 months, annual billing, no hidden costs.',
        'advertise.prices.explanations.bullet.advertisement.9': 'We take over the customer contact, clarify important details and transmit the booking data. Rely on our expertise for the mediation.',
        'advertise.prices.explanations.bullet.advertisement.10': 'Detailed display of your barrier-free equipment features.',
        'advertise.prices.explanations.bullets.expand': 'Show more advantages',
        'advertise.prices.explanations.bullets.shrink': 'Show less advantages',
        'advertise.register.fix.headline.hotel': 'Details about your accommodationt',
        'advertise.register.provision.headline.frame.contract': 'Framework agreement already concluded',
        'advertise.register.fix.hotel.label': 'Accommodation name',
        'advertise.register.fix.hotel.placeholder': 'Wheelchair Company',
        'advertise.register.fix.street.label': 'Street',
        'advertise.register.fix.street.placeholder': 'Wheelchair Road',
        'advertise.register.fix.houseNumber.label': 'House number',
        'advertise.register.fix.houseNumber.placeholder': '123',
        'advertise.register.fix.zip.label': 'Zip Code',
        'advertise.register.fix.zip.placeholder': '12345',
        'advertise.register.fix.city.label': 'City',
        'advertise.register.fix.city.placeholder': 'accessible city',
        'advertise.register.fix.country.label': 'Country',
        'advertise.register.fix.country.placeholder': 'barrier free country',
        'advertise.register.fix.headline.contact': 'Data on the contact person',
        'advertise.register.fix.contact.gender.label': 'Salutation',
        'advertise.register.fix.contact.gender.placeholder': 'Salutation',
        'advertise.register.fix.contact.gender.male': 'Male',
        'advertise.register.fix.contact.gender.female': 'Female',
        'advertise.register.fix.contact.gender.none': 'n/a',
        'advertise.register.fix.contact.firstName.label': 'First name',
        'advertise.register.fix.contact.firstName.placeholder': 'John',
        'advertise.register.fix.contact.name.label': 'Last name',
        'advertise.register.fix.contact.name.placeholder': 'Doe',
        'advertise.register.fix.contact.email.label': 'Email address (business)',
        'advertise.register.fix.contact.email.placeholder': 'doe@accommodation.com',
        'advertise.register.fix.contact.phone.label': 'Phone (business)',
        'advertise.register.fix.contact.phone.placeholder': '+49 160 123456789',
        'advertise.register.frame.contract.input.label': 'Contract number',
        'advertise.register.frame.contract.input.placeholder': 'XXXXX-XXX',
        'advertise.register.frame.contract.error': 'The entered contract number is not correct.',
        'advertise.register.frame.contract.submit': 'Unterkunft registrieren',
        'advertise.register.frame.contract.tooltip.headline': 'How do I get a framework agreement?',
        'advertise.register.frame.contract.tooltip.content': 'Simply contact us by phone at <a href="tel:+494026100360">+49 40 26 100 360</a>, e-mail to <a href="mailto:info@rolli-hotels.de">info@rolli-hotels.de</a> or use the contact form below. We will then get in touch with you immediately. Together we will define the terms and conditions in a framework agreement. After signing the contract, you will receive your framework contract number, which you can use to register and create your accommodation. With one contract number you can also register several objects.',
        'advertise.register.accept': 'I agree to the <a href="/legal/termsOfUse">terms and conditions</a> and the <a href="/legal/privacy">privacy policy</a>.',
        'advertise.contact.sales': 'I have not yet concluded a framework agreement and would like to inquire about it herewith without obligation.',
        'advertise.register.send': 'Register accommodation',
        'advertise.register.sent': 'Sent',
        'advertise.contact.sales.button': 'Contact sales',
        'advertise.request.send.success': 'Your request has been successfully sent to us. You will receive an email from us shortly.',
        'advertise.request.sales.send.success': 'Your request has been successfully sent to us.  Our sales department will contact you shortly.',
        'advertise.request.send.error': 'Your request could not be sent to us. Please try again later.',


        /*******************************************************
         * FAQ
         ********************************************************/
         'faq.headline': 'Frequently asked questions',
         'faq.subline': 'Tip & Help: Enter accessibility data like distances',
         'faq.content': 'When you create your accommodation profile, step 4 ("Add your accessibility") asks for door widths, distances and movement clearances. These are usually NOT available. After logging in, you will find our "Filling out help" as a PDF. Print this document and record the required data in the corresponding rooms with a folding rule or tape measure. Then transfer this data to your profile on the computer. Alternatively, log into your profile using a smartphone or tablet and enter the data directly into the profile.',

         'faq.item.00.headline': 'Multiple wheelchair accessible rooms/apartments?',
         'faq.item.00.content': 'Always enter one accommodation unit (room) with bathroom per registration. If you offer more than one vacation apartment or room, you can create additional entries. Simply request new login data for this purpose. Three additional entries (per hotel) are free of charge for commission customers. Discounts are available for fixed cost model customers.',

         'faq.item.01.headline': 'A vacation rental with multiple rooms?',
         'faq.item.01.content': 'Specify the total number of rooms available to wheelchair users or people with walking disabilities in the vacation apartment or vacation home. Rooms that can only be used by pedestrians, divide as "walking impaired" rooms. When describing distances in the room and bathroom, you must then choose a sample room. In the comment field, you can go into more detail and indicate the total number of people that can be accommodated.',

         'faq.item.02.headline': 'What is meant by minimum door width',
         'faq.item.02.content': 'Here you must specify the door with the smallest width in the accommodation that makes an area relevant for wheelchair users accessible. Example hotels: All doors on the way to the room as well as to the dining room and lobby. Example vacation home: rooms, hallways, living and dining rooms, house entrance. Accessibility, for example, to spa areas, terraces, fitness rooms, or other spaces is desirable, but not mandatory for a successful stay.',

         'faq.item.03.headline': 'Measure door width?',
         'faq.item.03.content': 'Here you measure the passage width in the inner frame. That is, the maximum width that items can have to fit through the door.',

         'faq.item.04.headline': 'Clearance next to the bed?',
         'faq.item.04.content': 'How much space does the wheelchair user have to drive up against the bed on one side? Measure the distance from the edge of the bed to a wall or obstacle that prevents the wheelchair from approaching.',

         'faq.item.05.headline': 'Bed edge height?',
         'faq.item.05.content': 'Measured from the floor to the top edge of the mattress.',

         'faq.item.06.headline': 'What is meant by range of motion?',
         'faq.item.06.content': 'The range of motion indicates how much space the wheelchair user has in a room to turn and walk. The largest possible free area (length times width) without obstacles is measured.',

         'faq.item.07.headline': 'Measure WC right or left side?',
         'faq.item.07.content': 'The correct perspective is with the back to the wall, i.e. sitting on the WC. Wheelchair users usually approach the WC backwards and then move. If the WC can be approached from the left, the wheelchair user transfers from the left to the toilet on the right.',

         'faq.item.08.headline': 'Ground level: meaning?',
         'faq.item.08.content': 'Areas that can be reached on one level without steps, pedestals or edges. Level accessibility is also given when differences in height can be overcome, for example, via ramps, slight slopes or elevators.',

         'faq.item.09.headline': 'Movement range showers with curtains?',
         'faq.item.09.content': 'For showers with curtains or even no splash guard at all (open in the room), curtain or floor markings are not considered an obstacle for measuring the range of motion.',

         'faq.item.10.headline': 'Do I need to specify distances?',
         'faq.item.10.content': 'Yes. For many guests with disabilities, this is a crucial factor. Accommodations that provide information about door widths, room to move, etc. are more likely to be booked.',

         'faq.item.11.headline': 'Information on activities?',
         'faq.item.11.content': 'If you don,t know of any accessible recreational activities in your area, it may be worthwhile to track them down. Calling the museum or nearby restaurant, for example, can often quickly clarify whether or not it is accessible. Local tourism centers can also help with this question.',

         'faq.item.12.headline': 'Login: request password?',
         'faq.item.12.content': 'To get an account with password to create a profile, go to www.rolli-hotels.de/adver- tise. Alternatively, click the ,Become a Landlord´ tab at the top of the homepage bar. Click "Register Here Now" at the bottom right of the screen or scroll down to get to the data fields. Select a pricing model. For the commission model, a framework contract must be signed beforehand. Enter your data (everything must be filled in) and click on "Register accommodation". Immediately after that you will receive an e-mail with your password for login.',

         'faq.item.13.headline': 'Login: Did not receive password?',
         'faq.item.13.content': 'If you did not receive a password after submitting your data (see Login: Request password), please check your spam folder first and update your email folder. Fill in the data fields again, making sure that your email address is spelled correctly. If the problem persists, please contact us by phone at (040) 261 00 360.',

         'faq.item.14.headline': 'Login: create profile?',
         'faq.item.14.content': 'You can enter your password at www.rolli-hotels.de/login or under the "Rent" tab at the top right of the homepage bar. Pay attention to upper and lower case. It is best to copy your password (Ctrl + c) and then paste it (Ctrl + v). Confirm the input on "Login" and create your accommodation profile.',

        /*******************************************************
         * Blog
         ********************************************************/
        'blog.title': 'Welcome to our barrier-free travel magazine',
        'blog.description.1': 'We have been working in the accessible travel industry for over 40 years. Benefit from our expertise. In our travel blog we introduce you to the pearls and treasures that you can also reach and enjoy barrier-free.',
        'blog.description.2': 'Check back regularly and get to know the most beautiful corners of this world anew. And now we wish you a lot of fun while browsing and discovering.',
        'blog.discover.category': 'Discover category',
        'blog.overview.most.seen.title': 'Our most popular posts',
        'blog.overview.most.seen.description': 'We have been working in the accessible travel industry for over 40 years. Benefit from our expertise. In our travel blog we present you the pearls and treasures.',
        'blog.overview.latest.title': 'Our latest posts',
        'blog.overview.latest.description': 'We have been working in the accessible travel industry for over 40 years. Benefit from our expertise. In our travel blog we present you the pearls and treasures.',
        'blog.discover.now': 'Discover now',
        'blog.category.posts': 'Posts in this category',
        'blog.booking': 'Book now'
    }
};

export default dictionary;
