// @flow
import React from 'react';
import {useLocalize} from 'react-localize';
import logo from '../../../images/src/logo/logo.svg';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {useLocation} from 'react-router';
import {Button, makeStyles} from '@material-ui/core';
import {createAction} from 'redux-actions';
import {ApplicationActions} from '../../../reducer/application';
import {Language} from '../../../types/FlowTypes';
import german from '../../../images/icons/german.svg';
import english from '../../../images/icons/english.svg';
import {useDispatch, useSelector} from 'react-redux';
import type {AppState} from '../../../types/FlowTypes';


const useStyles = makeStyles((theme) => ({
    root: {
        background: '#F9FBFD',
        paddingTop: 100,
    },
    content: {
        display: 'flex',
        maxWidth: 1240,
        width: '95%',
        margin: '0 auto',
        justifyContent: 'space-between',
    },
    logo: {
        width: 175,
        marginBottom: theme.spacing(5.5),
        display: 'block'
    },
    contactColumn: {
        width: 360,
        paddingLeft: 30
    },
    contactSurround: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%'
    },
    link: {
        ...theme.link,
        color: theme.palette.primary.light,
        fontSize: 14
    },
    contactDivider: {
        color: theme.palette.primary.light
    },
    headline: {
        color: '#102434',
        fontSize: 22,
        lineHeight: '28px',
        fontWeight: 600,
        marginBottom: 19
    },
    escalesSurround: {
        width: 180
    },
    handicappedSurround: {
        width: 180
    },
    legalSurround: {
        width: 180
    },
    footerLink: {
        marginBottom: 16
    },
    footerLinkFirst: {
        marginTop: 47
    },
    divider: {
        maxWidth: 1412,
        width: '95%',
        margin: '72px auto 28px',
        height: 1,
        background: '#E4E4E4'
    },
    copyright: {
        textAlign: 'center',
        paddingBottom: 31,
        fontSize: 14,
        color: theme.palette.primary.light
    },
    switch: {
        background: 'transparent',
        border: '1px solid #868D99',
        boxShadow: 'none',
        textTransform: 'none',
        color: '#193150',
        fontSize: 15,
        fontWeight: 600,
        marginBottom: theme.spacing(4),
        '&:hover': {
            boxShadow: 'none'
        }
    },
    languageIcon: {
        width: 24,
        height: 24,
        marginRight: theme.spacing(1),
        borderRadius: 4
    }
}));

const Footer = () => {
    const classes = useStyles();
    const {localize} = useLocalize();
    const {pathname} = useLocation();
    const dispatch = useDispatch();

    const {language} = useSelector((state: AppState) => ({
        language: state.application.language.selected
    }))

    return (
        <div className={classes.root} style={{
            paddingTop: pathname.includes('hotel/') ? 200 : undefined
        }}>
            <div className={classes.content}>
                <div className={classes.contactColumn}>
                    <img src={logo} alt={'Rolli-Hotels.de'} className={classes.logo}/>
                    <Button variant={'contained'} className={classes.switch} onClick={() => {
                        dispatch(createAction(ApplicationActions.SET_LANGUAGE)(language === Language.ENGLISH ? Language.GERMAN : Language.ENGLISH));
                    }}>
                        <img src={language === Language.ENGLISH ? german : english} alt={''} title={''}
                             className={classes.languageIcon}/>
                        {language === Language.ENGLISH ? localize('language.german') : localize('language.english')}
                    </Button>
                    <div className={classes.contactSurround}>
                        <a href={`tel:${localize('contact.phone')}`} className={classes.link}>
                            {localize('contact.phone')}
                        </a>
                        <span className={classes.contactDivider}>
                                |
                            </span>
                        <a href={`mailto:${localize('contact.mail')}`}
                           className={classes.link}>
                            {localize('contact.mail')}
                        </a>
                    </div>
                </div>
                <div className={classes.escalesSurround}>
                    <Typography variant={'h2'} className={classes.headline}>
                        {localize('footer.escales.headline')}
                    </Typography>
                    <Typography variant={'body1'}
                                className={classNames(classes.footerLink, classes.footerLinkFirst)}>
                        <a href={'http://www.escales-verlag.de'} className={classes.link} target={'_blank'}
                           rel={'noopener noreferrer'}>
                            {localize('footer.escales.headline')}
                        </a>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <a href={'http://www.irma-messe.de'} className={classes.link} target={'_blank'}
                           rel={'noopener noreferrer'}>
                            {localize('link.irma')}
                        </a>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <a href={'http://www.rollstuhl-kurier.de'} className={classes.link} target={'_blank'}
                           rel={'noopener noreferrer'}>
                            {localize('link.rollstuhl.kurier')}
                        </a>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <a href={'http://www.handicapped-reisen.de'} className={classes.link}
                           target={'_blank'} rel={'noopener noreferrer'}>
                            {localize('link.handicapped.reisen')}
                        </a>
                    </Typography>
                </div>
                <div className={classes.handicappedSurround}>
                    <Typography variant={'h2'} className={classes.headline}>
                        {localize('footer.handicapped.headline')}
                    </Typography>
                    <Typography variant={'body1'}
                                className={classNames(classes.footerLink, classes.footerLinkFirst)}>
                        <Link to={'/'} className={classes.link}>
                            {localize('menu.home')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/hotels'} className={classes.link}>
                            {localize('menu.destinations')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/aboutUs'} className={classes.link}>
                            {localize('menu.about.us')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/contact'} className={classes.link}>
                            {localize('menu.contact')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/faq'} className={classes.link}>
                            {localize('menu.faq')}
                        </Link>
                    </Typography>
                </div>
                <div className={classes.legalSurround}>
                    <Typography variant={'h2'} className={classes.headline}>
                        {localize('footer.legal.headline')}
                    </Typography>
                    <Typography variant={'body1'}
                                className={classNames(classes.footerLink, classes.footerLinkFirst)}>
                        <Link to={'/legal/legalnotice'} className={classes.link}>
                            {localize('menu.legal.notice')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/legal/privacy'} className={classes.link}>
                            {localize('menu.data.privacy')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/legal/disclaimer'} className={classes.link}>
                            {localize('menu.disclaimer')}
                        </Link>
                    </Typography>
                    <Typography variant={'body1'} className={classes.footerLink}>
                        <Link to={'/legal/termsOfUse'} className={classes.link}>
                            {localize('menu.terms.of.use')}
                        </Link>
                    </Typography>
                </div>
            </div>
            <div className={classes.divider}/>
            <Typography variant={'body1'} className={classes.copyright}>
                &copy; {new Date().getFullYear()} - Rolli-Hotels.de | All Rights reserved
            </Typography>
        </div>
    );
}

export default Footer;
