// @flow
import React from 'react';

const ArrowDown = (props: any) => {
    return (
        <svg viewBox={'0 0 14 9'} xmlns={'http://www.w3.org/2000/svg'} xmlnsXlink={'http://www.w3.org/1999/xlink'} {...props}>
            <g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
                <g transform={'translate(-995, -398)'} fill={'currentColor'} fillRule={'nonzero'}>
                    <g transform={'translate(388, 128)'}>
                        <g transform={'translate(32, 264)'}>
                            <g transform={'translate(582, 11) translate(-582, -11) translate(575, 4)'}>
                                <polygon points={'1.68662353 3.27298685 7.311 8.584 12.2718 3.3146353 13.7282 4.6853647 7.39458969 11.4148256 0.313376467 4.72701315'} />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default ArrowDown;