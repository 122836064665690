// @flow
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import * as classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import CookieInformation from './CookieInformation';
import {Link} from 'react-router-dom';
import CookieSettings from './CookieSettings';
import {useLocalize} from 'react-localize';
import type {AppState} from '../../../types/FlowTypes';
import {createAction} from 'redux-actions';
import {ApplicationActions} from '../../../reducer/application';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3, 4),
        maxWidth: 664,
        boxSizing: 'border-box',
        borderRadius: 8,
        boxShadow: '0 0 1px 0 rgba(0,0,0,0.2), 0 4px 15px 0 rgba(0,0,0,0.08), 0 16px 32px 0 rgba(0,0,0,0.24)',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            maxWidth: '100%',
            padding: theme.spacing(3, 3, 23),
            margin: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxHeight: 'calc(100% - 90px)'
        }
    },
    settingsRoot: {
        maxWidth: 720
    },
    dialog: {
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-end'
        }
    },
    titleSurround: {
        padding: 0,
        marginBottom: theme.spacing(2.5)
    },
    title: {
        color: theme.palette.primary.dark,
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '22px',
        letterSpacing: 0
    },
    body: {
        padding: 0,
        overflow: 'visible',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '0 !important'
        }
    },
    actions: {
        marginTop: theme.spacing(3),
        padding: 0,
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            position: 'fixed',
            bottom: 0,
            left: theme.spacing(3),
            right: theme.spacing(3),
            background: 'white',
            paddingBottom: theme.spacing(6),
            zIndex: 2
        }
    },
    actionButton: {
        width: `calc(50% - ${theme.spacing(1)}px)`,
        fontSize: 16,
        boxShadow: 'none',
        height: 50,
        backgroundColor: 'white',
        color: theme.palette.primary.dark,
        border: `1px solid ${theme.palette.primary.dark}`,
        fontWeight: 600,
        letterSpacing: -0.25,
        margin: '0 !important',
        textTransform: 'none',
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: `${theme.spacing(1.5)}px !important`
        }
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderColor: theme.palette.primary.main,
        textTransform: 'none',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0 !important'
        }
    },
    rightSurround: {
        marginTop: theme.spacing(2)
    },
    description: {
        color: '#767E8C',
        fontSize: 14,
        lineHeight: '20px'
    },
    link: {
        ...theme.link,
        textDecoration: 'underline'
    },
    linkSurround: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#767E8C',
        fontSize: 14,
        lineHeight: '18px'
    },
    settingsButton: {
        [theme.breakpoints.down('xs')]: {
            border: 'none',
            textDecoration: 'underline',
            textTransform: 'none',
            fontWeight: 400,
            color: '#767E8C',
            marginBottom: `${theme.spacing(0.5)}px !important`
        }
    }
}));

const CookieNotification= () => {
    const styles = useStyles();
    const {localize} = useLocalize();
    const isWidthDownXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const dispatch = useDispatch();
    const [trackingEnabled, setTrackingEnabled] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const {open} = useSelector((state: AppState) => ({
        open: state.application.cookies.showNotification
    }));

    return (
        <Dialog open={open} classes={{
            paper: classnames(styles.root, {
                [styles.settingsRoot]: showSettings
            }),
            scrollPaper: styles.dialog
        }}>
            {(!isWidthDownXS || showSettings) &&
            <DialogTitle className={styles.titleSurround}>
                <Typography variant={'h2'} className={styles.title}>
                    {localize('cookies.title')}
                </Typography>
            </DialogTitle>}
            <DialogContent className={styles.body}>
                {!showSettings ? <CookieInformation/> :
                    <CookieSettings setTrackingEnabled={setTrackingEnabled}
                                    trackingEnabled={trackingEnabled}/>}
            </DialogContent>
            <DialogActions className={styles.actions}>
                <Button variant={'contained'} className={classnames(styles.actionButton, {
                    [styles.settingsButton]: isWidthDownXS && !showSettings
                })}
                        onClick={() => {
                            setShowSettings(true);
                            if (showSettings) {
                                dispatch(createAction(ApplicationActions.ACCEPT_COOKIES)({
                                    trackingEnabled
                                }));
                            }
                        }}>
                    {!showSettings ? (isWidthDownXS ? localize('cookies.actions.settingsMobile') : localize('cookies.actions.settings')) : localize('cookies.actions.declineAsSelected')}
                </Button>
                <Button variant={'contained'} onClick={() => {
                    // User has accepted all cookies, so enable the tracking
                    dispatch(createAction(ApplicationActions.ACCEPT_COOKIES)({trackingEnabled: true}));
                }} className={classnames(styles.actionButton, styles.acceptButton)}>
                    {localize('cookies.actions.acceptAll')}
                </Button>
            </DialogActions>
            {!showSettings && !isWidthDownXS &&
            <div className={styles.rightSurround}>
                <Typography variant={'body1'} className={styles.description}>
                    {localize('cookies.explanation.rights')}
                </Typography>
                <Typography component={'div'} className={styles.linkSurround}>
                    <Link to={'/legal/privacy'} className={styles.link}>
                        {localize('cookies.privacy')}
                    </Link>
                    &nbsp;|&nbsp;
                    <Link to={'/legal/legalNotice'} className={styles.link}>
                        {localize('cookies.legalNotice')}
                    </Link>
                </Typography>
            </div>}
        </Dialog>
    );
}

export default CookieNotification;