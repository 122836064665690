// @flow

export type Map<K, V> = {
    [K]: V
}

export type DefaultComponentProps = {
    localize: Function,
    classes: Map<string, string>
}

export type ApplicationState = {
    language: {
        selected: string,
        available: Array<string>
    },
    showMobileMenu: boolean,
    contactFetchStatus: number,
    requestFetchStatus: number,
    loginFetchStatus: number,
    createFetchStatus: number,
    uploadImageFetchStatus: number,
    saveHotelFetchStatus: number,
    createHotelWithFrameContractFetchStatus: number;
    highlights: {
        availableCategories: number[],
        selectedCategory: number
    },
    cookies: {
        trackingEnabled: boolean,
        showNotification: boolean
    }
};

export type SearchState = {
    search: string,
    results: Map<number, Object>,
    counties: Map<number, Object>,
    countries: Map<number, Object>,
    countyFetchStatus: number
};

export type SearchCategory = {};

export type Hotel = {
    lastCompletedStep: number
};

export type HotelState = {
    search: {
        value: string,
        tags: Array<number>,
        results: Array<number>,
        counties: Array<number>,
        fetchStatus: number,
        showCard: boolean,
        page: number,
        pageSize: number,
        totalNumberOfResults: number,
        pageFetchStatus: number;
        dirty: boolean;
    },
    searchCategories: {
        categories: Map<number, SearchCategory>,
        fetchStatus: number
    },
    elements: Map<number, Hotel>,
    fetchStatus: number,
    highlights: {
        fetchStatus: number,
        elements: Array<number>,
        tags: Array<number>
    },
    logWebsiteFetchStatus: number
};

export type UserState = {
    jwt: string | null;
    data: {
        id: number;
        email: string;
        firstName: string;
        lastName: string;
        gender: number;
        phone: string;
    } | null,
    hotel: Hotel | null,
    activeStep: number,
    selectedRequirements: Map<number, boolean>,
    imageUploadErrors: string[]
}

export type LanguageType = 'de_DE' | 'en_GB'

export const Language = {
    GERMAN: 'de_DE',
    ENGLISH: 'en_GB'
}

export type LocalizationKeyType =
    'blogCategoryName'
    | 'blogCategoryDescription'
    | 'blogPostName'
    | 'blogPostDescription'
    | 'blogPostContent';

export const LocalizationKey = {
    BlogCategoryName: 'blogCategoryName',
    BlogCategoryDescription: 'blogCategoryDescription',
    BlogPostName: 'blogPostName',
    BlogPostDescription: 'blogPostDescription',
    BlogPostContent: 'blogPostContent'
}

export type Localization = {
    id: number;
    key: string;
    lang: LanguageType;
    value: string;
}

export type BlogImage = {
    id: number;
    name: string;
    path: string;
    order: number;
    type: string;
}

export type BlogCategory = {
    id: number;
    views: number;
    createdAt?: string;
    updatedAt?: string;
    creator?: Admin;
    responsibleAdmin?: Admin;
    Localizations: Localization[];
    BlogImages: BlogImage[];
    imageUploadProgress?: number;
    imageUploadFetchStatus?: FetchStatus;
    saveFetchStatus?: FetchStatus;
    deleteFetchStatus?: FetchStatus;
    loadPostsFetchStatus?: FetchStatus;
    page?: number;
    pageSize?: number;
    numberOfPosts?: number;
    postFetchStatus: FetchStatus;
}

export type BlogTag = {
    id: number;
    name: string;
}

export const BlogPostState = {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE'
}

export type BlogPostStateType = {
    INACTIVE: 'INACTIVE';
    ACTIVE: 'ACTIVE';
}

export type FetchStatusType = number;

export type BlogPost = {
    id: number;
    views: number;
    Localizations: Localization[];
    BlogImages: BlogImage[];
    BlogTags: BlogTag[];
    state: BlogPostStateType;
    blogCategoryID: number;
    highlighted: boolean;
    imageUploadFetchStatus?: FetchStatusType;
    imageUploadProgress?: number;
    saveFetchStatus?: FetchStatusType;
    deleteFetchStatus?: FetchStatusType;
    highlightFetchStatus?: FetchStatusType;
    activateFetchStatus?: FetchStatusType;
}

export type BlogState = {
    posts: Map<number, BlogPost>;
    categories: Map<number, BlogCategory>;
    latest: number[];
    mostViewed: number[];
    highlights: number[];
    fetchStatus: FetchStatusType;
    postFetchStatus: FetchStatusType;
    postsByCategory: Map<number, number[]>
}

export type AppState = {
    application: ApplicationState;
    search: SearchState;
    hotels: HotelState;
    user: UserState;
    blog: BlogState;
};
