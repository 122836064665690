// @flow
import {spawn} from 'redux-saga/effects';
import hotelSaga from './HotelSaga';
import LoadSaga from './LoadSaga';
import ContactSaga from './ContactSaga';
import UserSaga from './UserSaga';
import BlogSaga from './BlogSaga';


function* appSaga() {
    yield spawn(hotelSaga);
    yield spawn(LoadSaga);
    yield spawn(ContactSaga);
    yield spawn(UserSaga);
    yield spawn(BlogSaga);
}

export default appSaga;